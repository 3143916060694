let timerId;
import {UPDATE_CART,UPDATE_CART_ITEMS,UPDATE_LIST_ITEMS,UPDATE_BILLING} from "../constants";
import API from "../api";
const api = new API();

export const addCart = (data) => {
    return (dispatch) => {
        return dispatch({
            type: UPDATE_CART,
            data:data
        });
    }
};

export const updateBilling = (data) => {
    return (dispatch) => {
        return dispatch({
            type: UPDATE_BILLING,
            data:data
        });
    }
};

export const updateCart = (data) => {
    return (dispatch) => {
        const time = 300;
        if ((data.data.cart_id !== null && data.data.cart_id !== "blank" && data.data.cart_id !== "default") || data.data.token !== null) {
            clearTimeout(timerId);
            timerId = setTimeout(
                function () {
                    api.getCartItems(data.data.cart_id, data.data.token).then(res => {
                        return dispatch({
                            type: UPDATE_CART_ITEMS,
                            data: res.data.response
                        });
                    }).catch(error => {
                        return dispatch({
                            type: UPDATE_CART_ITEMS,
                            data: {}
                        });
                    });
                }.bind(this), time);
        }
    };
};

export const updateList = (data) => {
    return (dispatch) => {
        if (data.data.list_unique_id !== null && data.data.list_unique_id !== "blank" && data.data.list_unique_id !== "default") {
            api.getUserList(data.data.list_unique_id, data.data.token).then(res => {
                return dispatch({
                    type: UPDATE_LIST_ITEMS,
                    data: res.data.response
                });
            }).catch(error => {
                return dispatch({
                    type: UPDATE_LIST_ITEMS,
                    data: {}
                });
            });
        }
    }
};
