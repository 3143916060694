import {combineReducers} from 'redux'
import ProductReducer from "./productReducer";
import UserReducer from "./userReducer";
import CartReducer from "./cartReducer";
import StoreReducer from "./storeReducer";
import ListReducer from "./listReducer";
import BillingReducer from "./billingReducer";
import TimingReducer from "./timingReducer";

const rootReducer = combineReducers({
     ProductReducer : ProductReducer,
     UserReducer : UserReducer,
     CartReducer : CartReducer,
     TimingReducer : TimingReducer,
     StoreReducer : StoreReducer,
     ListReducer : ListReducer,
     BillingReducer:BillingReducer
});

export default rootReducer;
