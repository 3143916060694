import React from 'react';
import {formatDistance, sortArray} from "../../../helper";
import 'react-tabs/style/react-tabs.css';
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import PlacesAutocomplete, {geocodeByAddress, geocodeByPlaceId, getLatLng,} from 'react-places-autocomplete';
import {connect} from "react-redux";
import API from "../../../api";
import {withSnackbar} from "notistack";
import {updateCart, updateList} from "../../../actions/cart";
import ShopHoursClass from "../../Common/Timing";
import {houstonZipcodes} from "../../../constant";
const api = new API();


class Modals extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    static async getInitialProps({req, res, match, history, location, ...ctx}) {
        const restaurants = await api.getAllRestaurants();
        return {
            restaurants: restaurants.data.response,
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            restaurant_available_for_delivery : null,
            active_tab : this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1,
            confirm_reset_cart:false,
            address: '',
            delivery:
                {
                    address: '',
                    apartment : this.props.cookies.get('selected_preference') && this.props.cookies.get('selected_preference') === "2" ? this.props.cookies.get('delivery_address')?.['apartment']   : null,
                    zip_code: ''
                },
            loading: false,
            show: 0,
            coordinates:
                {
                    lat: 0,
                    lng: 0
                },
            distance1: 0,
            distance: 0,
            available_destinations: [],
            del_active: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') === "2" ?  "nav-link active" : "nav-link" : "nav-link",
            pic_active: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') === "1" ?  "nav-link active" : "nav-link" : "nav-link active",
            selected: false,
            delivery_panel: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') === "2" ?  "d-block" : "d-none" : "d-none",
            pickup_panel: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') === "1" ?  "d-block" : "d-none" : "d-block",
            del_tab: " nav-item ",
            pic_tab: " nav-item ",
            restaurants: [],
            delivery_error : "mt-2 info-box align-item-start d-none",
            delivery_success : "mt-2 info-box align-item-start d-none",
            delivery_input : this.props.cookies.get('selected_preference') && this.props.cookies.get('selected_preference') === "2" ? this.props.cookies.get('delivery_address')?.['address']   : null,
            pickup_input : null,
            showDropdown: false,
            preference_button : "cart-button",
            preference_active_button : "cart-button active",
            default_store : this.props.cookies.get('selected_store') ? this.props.cookies.get('selected_store') : 2,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            request_store : null,
            available_item_count : 0,
            currentLatLong :null,
            confirm_button : true,
            delivery_available : "text-dark font-weight-bold mt-2 d-none",
            delivery_cookies_object : this.props.cookies.get('selected_preference') && this.props.cookies.get('selected_preference') === "2" ? this.props.cookies.get('delivery_address')  : null,
            delivery_offline : this.props.deliveryOffline,
            change_preference: "",
            delivery_zip_code: ""
        };
        this.box = React.createRef();
    }

    componentDidMount() {
        if (this.state.restaurants.length < 1) {
            api.getAllRestaurants().then(success => {
                this.setState({restaurants: success.data.response},()=>{
                    if (this.props.cookies.get('delivery_address') && this.props.cookies.get('delivery_address') !== null && this.props.cookies.get('selected_preference') && this.props.cookies.get('selected_preference') === 2){
                        this.handleSelectedPickupAddress(this.props.cookies.get('delivery_address')['late'],this.props.cookies.get('delivery_address')['long']);
                    }else{
                        this.handleSelectedPickupAddress(this.props.currentLatLong?.lat,this.props.currentLatLong?.lng);
                    }
                })
            })
        }
        if (this.props.cartAddedItems.cart_items != null) {
            this.setState({available_item_count :  this.props.cartAddedItems.cart_items.length })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        if (this.props.cookies.get('change_preference') !== undefined && this.props.cookies.get('change_preference') !== this.state.change_preference) {
            this.setState({
                change_preference : this.props.cookies.get('change_preference'),
                active_tab : this.props.cookies.get('change_preference'),
                del_active: this.props.cookies.get('change_preference') ? this.props.cookies.get('change_preference') === "2" ?  "nav-link active" : "nav-link" : "nav-link",
                pic_active: this.props.cookies.get('change_preference') ? this.props.cookies.get('change_preference') === "1" ?  "nav-link active" : "nav-link" : "nav-link active",
                delivery_panel: this.props.cookies.get('change_preference') ? this.props.cookies.get('change_preference') === "2" ?  "d-block" : "d-none" : "d-none",
                pickup_panel: this.props.cookies.get('change_preference') ? this.props.cookies.get('change_preference') === "1" ?  "d-block" : "d-none" : "d-block",
            });
        }
    }

    handlePickupChange = address => {
        this.setState({delivery: {...this.state.delivery, address: address}, address: address ,pickup_input : address });
        this.setState({show: 0});
    };


    handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        const { long_name : zip_code = '' } = results[0].address_components.find(c => c.types.includes('postal_code')) || {};

        this.setState({
            address: value, selected: true,
            delivery_input : value ,
            delivery: {...this.state.delivery, address: value.replace(', USA',''), zip_code : zip_code},
            coordinates: {lat: latLng.lat, lng: latLng.lng},
            delivery_available : "text-dark font-weight-bold mt-2 d-block",
            delivery_zip_code : zip_code,
        },()=> {
            this.handleSearch();
        });
    };

    handlePickupAddressSelect = async (value) => {

        document.getElementById('exampleInputEmail1').blur();
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        this.setState({
            address: value, selected: true,
            pickup_input : value ,
            delivery: {...this.state.delivery, address: value},
            coordinates: {lat: latLng.lat, lng: latLng.lng}
        },()=>{
            this.handleSearch();
        });
    };


    handleRestaurant(distance,value){

        if(this.state.available_item_count > 0){
            if (this.state.default_store !== value.id){
                api.getRestaurantCartUnavailableItems({
                    cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                    restaurant_id : value.id,
                    preference : 1
                }).then(success => {

                    const unavailableItems = success.data.response?.cart_items?.length > 0 ? success.data.response?.cart_items.filter((item)=>{
                                return item.restaurant_item_available === 0 || item.restaurant_variable_available === 0 ;
                    }) : [] ;

                    if (unavailableItems.length === 0){

                        api.assignCartItemToRestaurant({
                            cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                            restaurant_id : value.id,
                            preference : 1
                        });

                        this.props.cookies.set('store_modal', false);
                        this.props.cookies.set('change_store', false);
                        this.props.cookies.set('selected_store', value.id);
                        this.props.cookies.set('selected_preference', 1);
                        this.setState({default_store : value.id});

                        if(this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null) {
                            api.changeCartPreference({
                                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                                preference: 1 //pickup
                            }).then(success => {
                                this.props.updateCartItems({
                                    cart_id: this.props.cookies.get("cart_id")
                                        ? this.props.cookies.get("cart_id")
                                        : "blank",
                                    token: this.props.cookies.get("token")
                                        ? this.props.cookies.get("token")
                                        : null,
                                });
                            });
                        }
                    }else{
                        this.props.changeStore({
                            default_store: this.state.default_store,
                            request_store: value.id,
                            unavailable_items : unavailableItems
                        });
                    }
                })
            }
        }else{

            this.props.cookies.set('store_modal', false);
            this.props.cookies.set('change_store', false);
            this.props.cookies.set('selected_store', value.id);
            this.props.cookies.set('selected_preference', 1);
            this.setState({default_store : value.id},()=>{
                if(this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null) {
                    api.changeCartPreference({
                        cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                        preference: 1 //pickup
                    });
                }
            });
        }

        this.props.cookies.set("date", "");
        this.props.cookies.set("time", "");
    }

    handlePreferenceChange(delivery_address,restaurant){

        api.getRestaurantCartUnavailableItems({
            cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
            restaurant_id : restaurant,
            preference : 2 //delivery
        }).then(success => {

            const unavailableItems = success.data.response?.cart_items?.length > 0 ? success.data.response?.cart_items.filter((item)=>{
                return item.restaurant_item_available === 0 || item.restaurant_variable_available === 0 ;
            }) : [] ;

            if (unavailableItems.length === 0){

                api.assignCartItemToRestaurant({
                    cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                    restaurant_id : restaurant,
                    preference : 2 //delivery
                });

                this.props.cookies.set('store_modal' ,false);
                this.props.cookies.set('selected_preference',2); //2 Delivery
                this.props.cookies.set('selected_store', restaurant);
                this.props.cookies.set('delivery_address', delivery_address);
                this.props.cookies.set("date", "");
                this.props.cookies.set("time", "");

                if(this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null) {
                    api.changeCartPreference({
                        cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                        preference: 2 //delivery
                    });
                }

            }else{
                this.props.changePreference({
                    preference : 2,
                    request_store: restaurant,
                    delivery_address : delivery_address,
                    unavailable_items : unavailableItems
                });
            }
        })
    }

    handleSearch = () => {

        if (this.state.coordinates.lat !== 0) {

            if (!this.state.delivery_zip_code || !houstonZipcodes.includes(this.state.delivery_zip_code)) {
                this.setState({delivery_available : "info-box mt-2 d-none",delivery_error: "info-box mt-2 mb-3 d-flex align-item-start",confirm_button : true});
                return false;
            }

            this.setState({show: 1});

            const origin = new google.maps.LatLng(
                this.state.coordinates.lat,
                this.state.coordinates.lng
            );

            const destination_array = [];

            this.state.restaurants.length > 0 ? this.state.restaurants.map((val, index) => {
                const destination = new google.maps.LatLng(
                    val.latitude, val.longitude
                );
                destination_array.push({destination: destination, restaurant: val})
            }) : "";

            const distance_array = [];
            destination_array.length > 0 ? destination_array.map((val, index) => {
                const distance = google.maps.geometry.spherical.computeDistanceBetween(
                    origin,
                    val.destination
                );

                // Convert to miles
                const distanceInMiles = distance / 1609.34;
                distance_array.push({distance: distanceInMiles, restaurant: val.restaurant})
            }) : "";

            var no_distance_available = false ;
            var sorting = [];
            distance_array.length > 0 ? sorting = sortArray(distance_array) : "";

            if (parseInt(this.state.active_tab) === 2) {

                //distance_array?.[0]?.restaurant?.delivery_radius
                if (distance_array.length > 0 && Math.round(distance_array?.[0]?.distance) <= 25) {
                    this.setState({restaurant_available_for_delivery : distance_array?.[0]?.restaurant?.id },()=>{

                        //Validate delivery address
                        api.validateDeliveryAddress({
                            address : this.state.delivery.address,
                            restaurant_id : distance_array?.[0]?.restaurant?.id,
                        }).then(success => {
                            this.setState({delivery: {...this.state.delivery, zip_code: this.state.delivery_zip_code}});
                            this.setState({
                                delivery_available : "info-box mt-2 d-none",
                                delivery_success : "info-box mt-2 d-flex align-item-start mb-3",
                                confirm_button: false},()=>{
                                no_distance_available = true;
                            });
                        }).catch(error=>{
                            this.setState({delivery_available : "info-box mt-2 d-none",delivery_error: "info-box mt-2 mb-3 d-flex align-item-start",confirm_button : true});
                            return false;
                        });
                    });

                }else {

                    if (no_distance_available === false) {
                        this.setState({
                            delivery_available : "info-box mt-2 d-none",
                            delivery_error: "info-box mt-2 mb-3 d-flex align-item-start",
                            confirm_button : true
                        });
                        return false;
                    }
                }
            }
            this.setState({available_destinations: sorting});
        }
    };


    saveDelivery = () => {

        if (this.state.delivery.address === '') {
            return false;
        }
        if (this.state.selected === false) {
            this.props.cookies.set('store_modal', false);
        }

        if (this.state.coordinates.lat !== 0) {

            const origin = new google.maps.LatLng(
                this.state.coordinates.lat,
                this.state.coordinates.lng
            );

            const destination_array = [];
            this.state.restaurants.length > 0 ? this.state.restaurants.map((val, index) => {
                const destination = new google.maps.LatLng(
                    val.latitude, val.longitude
                );
                destination_array.push({destination: destination, restaurant: val})
            }) : "";

            const distance_array = [];
            destination_array.length > 0 ? destination_array.map((val, index) => {
                const distance = google.maps.geometry.spherical.computeDistanceBetween(
                    origin,
                    val.destination
                );

                // Convert to miles
                const distanceInMiles = distance / 1609.34;
                distance_array.push({distance: distanceInMiles, restaurant: val.restaurant})
            }) : "";


            var sorting = [];
            var no_distance_available = false ;
            distance_array.length > 0 ? sorting = sortArray(distance_array) : "";

            //distance_array?.[0]?.restaurant?.delivery_radius
            if (distance_array.length > 0 && Math.round(distance_array?.[0]?.distance) <= 25){

                if(this.state.available_item_count > 0 && this.state.default_preference !== "2"){
                    this.handlePreferenceChange({
                        'address': this.state.delivery.address,
                        'apartment': this.state.delivery.apartment,
                        'zip_code': this.state.delivery.zip_code,
                        'late': this.state.coordinates.lat,
                        'long': this.state.coordinates.lng
                    },distance_array?.[0]?.restaurant?.id);

                    return false ;
                }

                //Change preference in cookies
                this.props.cookies.set('store_modal' ,false);
                this.props.cookies.set('selected_preference',2); //2 Delivery
                this.props.cookies.set('selected_store', distance_array?.[0]?.restaurant?.id);
                this.props.cookies.set('delivery_address', {
                    'address': this.state.delivery.address,
                    'apartment': this.state.delivery.apartment,
                    'zip_code': this.state.delivery.zip_code,
                    'late': this.state.coordinates.lat,
                    'long': this.state.coordinates.lng
                });

                const {cookies} = this.props;
                cookies.set("date","");
                cookies.set("time","");

                no_distance_available = true ;

                if(this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null) {
                    api.changeCartPreference({
                        cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : null,
                        preference : 2 //delivery
                    });
                }

               return false ;
            }

            if(no_distance_available === false ){
                this.setState({delivery_error: "text-danger font-weight-bold mt-2 d-flex align-item-start",confirm_button : true});
            }
        }
    };

    handleDeliveryChange = address => {
        this.setState({delivery: {...this.state.delivery, address: address}, address: address ,delivery_input : address});
        this.setState({delivery_error: "info-box mt-2 align-item-start d-none",delivery_success: "info-box mt-2 d-none",confirm_button : true });
    };

    handleZipCodeChange = zip_code => {
        this.setState({delivery_zip_code: zip_code,delivery_error: "info-box mt-2 align-item-start d-none",delivery_success: "info-box mt-2 d-none",confirm_button : true });
        if(zip_code.length >= 5) {
            this.setState({delivery_available : "text-dark font-weight-bold mt-2 d-block"},()=>{
                setTimeout(() => {
                    this.handleSearch();
                }, 3000);
            })
        }
    };

    handleSelectedPickupAddress(lat,long){

        if (lat !== undefined && lat !== null ) {
            this.setState({show: 1});
            const origin = new google.maps.LatLng(
                lat,
                long
            );
            const destination_array = [];
            this.state.restaurants.length > 0 ? this.state.restaurants.map((val, index) => {
                const destination = new google.maps.LatLng(
                    val.latitude, val.longitude
                );
                destination_array.push({destination: destination, restaurant: val})
            }) : "";

            const distance_array = [];
            destination_array.length > 0 ? destination_array.map((val, index) => {
                const distance = google.maps.geometry.spherical.computeDistanceBetween(
                    origin,
                    val.destination
                );
                // Convert to miles
                const distanceInMiles = distance / 1609.34;
                distance_array.push({distance: distanceInMiles, restaurant: val.restaurant})
            }) : "";
            var sorting = [];
            var no_distance_available = false ;
            distance_array.length > 0 ? sorting = sortArray(distance_array) : "";
            sorting.length > 0 ? sorting.map((val, index) => {
                if (val.distance <= val.restaurant['delivery_radios'] ){
                    no_distance_available = true ;
                    return false ;
                }
            }) : "";

            this.setState({available_destinations: sorting})

        }else{

            this.setState({show: 1});

            const destination_array = [];
            this.state.restaurants.length > 0 ? this.state.restaurants.map((val, index) => {
                destination_array.push({destination: 0, restaurant: val})
            }) : "";
            const distance_array = [];
            destination_array.length > 0 ? destination_array.map((val, index) => {
                distance_array.push({distance: 0, restaurant: val.restaurant})
            }) : "";
            this.setState({available_destinations: distance_array})
        }
    }

    handleActive = (val) => {
        if (val === 'Delivery') {
            this.setState({del_active: 'nav-link active', delivery_panel: "d-block", active_tab : 2 })
        } else {
            this.setState({del_active: "nav-link", delivery_panel: "d-none"})
        }
        if (val === 'Pickup') {
            this.setState({pic_active: 'nav-link active', pickup_panel: "d-block",active_tab : 1 })
        } else {
            this.setState({pic_active: 'nav-link', pickup_panel: "d-none"})
        }
    };

    handleDropDownClick() {
        this.setState({
            showDropdown : true
        });
        setTimeout(() => {
            this.setState({
                showDropdown : false
            });
        }, 500)
    }

    render() {

        const cityBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(29.749907, -95.358421));

        const searchOptions = {
            bounds: cityBounds,
            componentRestrictions: { country: ['us'] },
            types: ['address']
        };

        const {showDropdown} = this.state || {};

        return (
            <React.Fragment>
                <div className="drop-modal rest-modal" ref={this.box}>
                    <div className="order-prefrence">
                        <h4 className="card-heading">Order Preference
                            <span className="modal-close btn"  onClick={() => {
                                this.props.cookies.set('store_modal', false);
                                this.props.cookies.remove('change_preference');
                            }}>
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" className="css-i6dzq1">
                                    <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </span>
                        </h4>
                        {/*<p>*/}
                        {/*    Enter your address to find the nearest Farmer’s Fresh Meat.*/}
                        {/*    Verify address for delivery at Farmer’s Fresh Meat.*/}
                        {/*</p>*/}
                    </div>

                    <div className="delivery-tabs pt-1">

                        {/*<div className="form-group mb-1">*/}
                        {/*    <label>Preference</label>*/}
                        {/*</div>*/}

                        {/* <ul className="nav nav-tabs border-0 food-details" id="myTab" role="tablist"> */}
                        <div className="nav nav-tabs border-0 food-details tabs-order p-0">
                            <div
                                className={this.state.pic_tab + (this.state.delivery_offline === 0 && " w-100 ")}
                                onClick={() => this.handleActive('Pickup')}
                            >
                                <p className={this.state.pic_active} id="profile-tab">
                                    Pickup
                                </p>
                            </div>

                            <div
                                className={this.state.del_tab + (this.state.delivery_offline === 0 && " d-none ")}
                                onClick={() => this.handleActive('Delivery')}
                            >
                                <p className={this.state.del_active} id="home-tab">
                                    Delivery
                                </p>
                            </div>
                        </div>


                        <div className={this.state.pickup_panel}>
                            <div
                                className="tab-pane fade show active"
                                id="home"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                            >
                                {/**<form className="input-form"> **/}
                                <form className="input-form mt-2 pt-1 mb-0">
                                    <div className="form-group mb-0">
                                        <label htmlFor="exampleInputEmail1">
                                            Locate nearest Farmer's store
                                        </label>
                                        <PlacesAutocomplete
                                            value={ this.state.pickup_input ? this.state.pickup_input : null  }
                                            onChange={this.handlePickupChange}
                                            onSelect={this.handlePickupAddressSelect}
                                            searchOptions={searchOptions}
                                        >
                                            {({
                                                  getInputProps,
                                                  suggestions,
                                                  getSuggestionItemProps,
                                                  loading,
                                              }) => (
                                                <div>
                                                    <div
                                                        className="position-relative"
                                                        style={{display: 'flex'}}
                                                    >
                                                        <input
                                                            className="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            {...getInputProps({
                                                                placeholder:
                                                                    'Enter your address',
                                                            })}
                                                            onKeyUp={(event)=>{
                                                                if (event.keyCode === 13){
                                                                    event.target.blur();
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    {suggestions.length > 0 ?
                                                        <div className="location-suggestions">
                                                            {loading ? (
                                                                <div>...loading</div>
                                                            ) : null}

                                                            {suggestions.map(
                                                                (suggestion) => {
                                                                    const style = {
                                                                        backgroundColor:
                                                                            suggestion.active
                                                                                ? '#41b6e6'
                                                                                : '#fff',
                                                                    };

                                                                    return (
                                                                        <div
                                                                            className="suggestion-items"
                                                                            {...getSuggestionItemProps(
                                                                                suggestion,
                                                                                {
                                                                                    style,
                                                                                }
                                                                            )}
                                                                        >   <span className="ignore_hidden_text">
                                                                        {
                                                                            suggestion.description
                                                                        }
                                                                        </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            )}
                                                        </div>
                                                        : " "
                                                    }
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                </form>

                                { this.state.show === 1 ? (
                                    <div className="card-location col-12 p-0 mt-3">
                                        <div className="card-body p-0">
                                            <div className="store-select">
                                                {this.state.available_destinations && this.state.available_destinations.length > 0 ?
                                                    this.state.available_destinations.map((item) => {

                                                        var distance = item.distance ;
                                                        return (
                                                                <div className={ parseInt(this.state.default_store) === parseInt(item.restaurant.id) && parseInt(this.state.default_preference) ===  1 ? "loc-slc active" : "loc-slc" }>
                                                                    <div className="options">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                {/*<p className="card-title">*/}
                                                                                {/*    <b>{item.restaurant.name}</b>*/}
                                                                                {/*</p>*/}
                                                                                <p className="distance">
                                                                                    <span className="text-black d-block mb-1 text-title">
                                                                                        <i className="fa fa-map-pin mr-2"></i>
                                                                                        {item.restaurant.address}
                                                                                    </span>
                                                                                    <span onClick={() => {
                                                                                        this.handleDropDownClick();
                                                                                    }}  className="text-black d-flex align-items-center mb-1 font-weight-normal pointer">
                                                                                        <i className="fa fa-clock mr-2"></i>
                                                                                         <ShopHoursClass />
                                                                                    </span>
                                                                                    <span className="text-black d-block font-weight-normal">
                                                                                        <i className="fa fa-phone mr-2"></i>
                                                                                        {item.restaurant.contact_number}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-12 mt-2 d-flex justify-content-between align-items-center">
                                                                                <span className="text-secondary font-weight-normal font-13">
                                                                                    From you{' '}{formatDistance(distance)}{' '}Miles
                                                                                    </span>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        this.handleRestaurant(distance,item.restaurant);
                                                                                    }}
                                                                                    className={ parseInt(this.state.default_store) === parseInt(item.restaurant.id) && parseInt(this.state.default_preference) ===  1 ? this.state.preference_active_button : this.state.preference_button }
                                                                                >
                                                                                    Select<slc class="slc">ed</slc>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {/*****</form>****/}
                            </div>
                        </div>


                        <div className={this.state.delivery_panel}>

                            <div className="form-group mt-2 ">
                                <label htmlFor="exampleInputEmail1">
                                    Where shall we deliver?
                                </label>
                                <PlacesAutocomplete
                                    value={ this.state.delivery_input ? this.state.delivery_input : this.state.default_preference === 2 ? this.state.address : null  }
                                    onChange={this.handleDeliveryChange}
                                    onSelect={this.handleSelect}
                                    searchOptions={searchOptions}
                                >
                                    {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading,
                                      }) => (
                                        <div>
                                            <div
                                                className="position-relative"
                                                style={{display: 'flex'}}
                                            >
                                                <input
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    {...getInputProps({
                                                        placeholder:
                                                            'Enter your address',
                                                    })}
                                                />
                                            </div>
                                            {suggestions.length > 0 ?
                                                <div className="location-suggestions">
                                                    {loading ? (
                                                        <div>...loading</div>
                                                    ) : null}

                                                    {suggestions.map(
                                                        (suggestion) => {
                                                            const style = {
                                                                backgroundColor:
                                                                    suggestion.active
                                                                        ? '#41b6e6'
                                                                        : '#fff',
                                                            };

                                                            return (
                                                                <div
                                                                    className="suggestion-items"
                                                                    {...getSuggestionItemProps(
                                                                        suggestion,
                                                                        {
                                                                            style,
                                                                        }
                                                                    )}
                                                                >
                                                                    {
                                                                        suggestion.description
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>

                            {this.state.selected && !this.state.delivery.zip_code &&
                                <div className="form-group mt-0">
                                    <input name="apartment" value={this.state.delivery_zip_code} className="form-control" placeholder="Enter zip code"
                                           onChange={(e)=>{
                                               this.handleZipCodeChange(e.target.value)}
                                    } />
                                </div>
                            }

                            <div className="form-group mt-0">
                                <input name="apartment" className="form-control" value={this.state.delivery.apartment}
                                       placeholder="(Optional) Suite, apartment, or unit number" onChange={(e) => {
                                    this.setState({
                                        delivery: {...this.state.delivery, apartment: e.target.value},
                                    },()=>{
                                        if(this.props.cookies.get('selected_preference') && this.props.cookies.get('selected_preference') === "2" && this.state.delivery_cookies_object)
                                        {
                                            setTimeout(()=>{
                                                const deliveryObject = this.state.delivery_cookies_object;
                                                deliveryObject['apartment'] = this.state.delivery.apartment;
                                                this.props.cookies.set('delivery_address', deliveryObject);
                                            },3000);
                                        }
                                    });
                                }}/>
                            </div>

                            <small className={this.state.delivery_available}>
                                Checking delivery . . . . .
                            </small>

                            <small className={this.state.delivery.zip_code ? this.state.delivery_error : "d-none"}>
                                <span className="d-flex align-items-center bg-danger-25 rounded-lg w-100">
                                     <i className="fas fa-exclamation-triangle mr-2"></i>
                                    We apologies, our delivery partners are not serving this location at the moment.
                                    {/*Please change order preference to Pickup as we are not serving delivery orders at the moment.*/}
                                </span>
                            </small>

                            <small className={!this.state.delivery.zip_code ? this.state.delivery_error : "d-none"}>
                                <span className="d-flex align-items-center bg-danger-25 rounded-lg w-100">
                                     <i className="fas fa-exclamation-triangle mr-2"></i>
                                    Please Enter zip code to check delivery availability
                                </span>
                            </small>

                            <small className={this.state.delivery.zip_code ? this.state.delivery_success : "d-none"}>
                                <span
                                    className="d-flex align-items-center p-2 bg-success-25 border-success rounded-lg w-100 mt-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                        className="mr-1 text-success"
                                        fill="none"
                                    >
                                        <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm4.78 7.7-5.67 5.67a.75.75 0 0 1-1.06 0l-2.83-2.83a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06Z" fill="#28a745"></path>
                                    </svg>
                                    {/*Great! We are delivering to the address you selected.*/}
                                    Great!  We deliver to your location.
                                </span>
                            </small>
                            <div className="col-12 store-select text-right px-0">
                                <button
                                    className="mt-2 float-none cart-button w-auto px-4 py-2 h-auto"
                                    disabled={!this.state.delivery.zip_code || this.state.confirm_button}
                                    onClick={() => {
                                        this.saveDelivery()
                                    }}
                                >
                                    Confirm Address
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--------------------------/header dropdown modal-------------------------->*/}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: "UPDATE_CART_ITEMS", data: val})),
    };
};


const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

export default withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Modals)));
