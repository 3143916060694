export const MENU_TYPE = 'MENU_TYPE';
export const GET_CART = 'GET_CART';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_TIMING_INFO = 'GET_TIMING_INFO';
export const UPDATE_CART = 'UPDATE_CART';
export const USER_REWARD_POINTS = 'USER_REWARD_POINTS';
export const UI_EDIT_PROFILE_UPDATE = 'UI_EDIT_PROFILE_UPDATE';
export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const SINGLE_PRODUCT = 'SINGLE_PRODUCT';
export const BONUS_OFFERS = 'BONUS_OFFERS';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const UPDATE_CART_INC = "UPDATE_CART_INC";
export const SELECTED_STORE = "SELECTED_STORE";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const UPDATE_LIST_ITEMS = "UPDATE_LIST_ITEMS";
export const USER_LIST = "USER_LIST";
export const UPDATE_BILLING ="UPDATE_BILLING";


export const citiesHoustan = [
    { label: "Abbott city", value: "Abbott city" },
    { label: "Abernathy city", value: "Abernathy city" },
    { label: "Abilene city", value: "Abilene city" },
    { label: "Abram CDP", value: "Abram CDP" },
    { label: "Acala CDP", value: "Acala CDP" },
    { label: "Ackerly city", value: "Ackerly city" },
    { label: "Addison town", value: "Addison town" },
    { label: "Adrian city", value: "Adrian city" },
    { label: "Agua Dulce CDP", value: "Agua Dulce CDP" },
    { label: "Airport Heights CDP", value: "Airport Heights CDP" },
    { label: "Alamo city", value: "Alamo city" },
    { label: "Alamo Heights city", value: "Alamo Heights city" },
    { label: "Alba town", value: "Alba town" },
    { label: "Albany city", value: "Albany city" },
    { label: "Aldine CDP", value: "Aldine CDP" },
    { label: "Aledo city", value: "Aledo city" },
    { label: "Alfred town", value: "Alfred town" },
    { label: "Alice city", value: "Alice city" },
    { label: "Alice Acres CDP", value: "Alice Acres CDP" },
    { label: "Allen city", value: "Allen city" },
    { label: "Alma town", value: "Alma town" },
    { label: "Alpine city", value: "Alpine city" },
    { label: "Alto town", value: "Alto town" },
    { label: "Alton city", value: "Alton city" },
    { label: "Alvarado city", value: "Alvarado city" },
    { label: "Alvin city", value: "Alvin city" },
    { label: "Alvord town", value: "Alvord town" },
    { label: "Amada Acres town", value: "Amada Acres town" },
    { label: "Amargosa CDP", value: "Amargosa CDP" },
    { label: "Amaya CDP", value: "Amaya CDP" },
    { label: "Ames town", value: "Ames town" },
    { label: "Amherst town", value: "Amherst town" },
    { label: "Amistad village", value: "Amistad village" },
    { label: "Anahuac city", value: "Anahuac city" },
    { label: "Anderson city", value: "Anderson city" },
    { label: "Anderson Mill CDP", value: "Anderson Mill CDP" },
    { label: "Andrews city", value: "Andrews city" },
    { label: "Angleton city", value: "Angleton city" },
    { label: "Angus city", value: "Angus city" },
    { label: "Anna city", value: "Anna city" },
    { label: "Annetta town", value: "Annetta town" },
    { label: "Annetta North town", value: "Annetta North town" },
    { label: "Annetta South town", value: "Annetta South town" },
    { label: "Annona town", value: "Annona town" },
    { label: "Anson city", value: "Anson city" },
    { label: "Anthony town", value: "Anthony town" },
    { label: "Anton town", value: "Anton town" },
    { label: "Appleby city", value: "Appleby city" },
    { label: "Aquilla town", value: "Aquilla town" },
    { label: "Aransas Pass city", value: "Aransas Pass city" },
    { label: "Archer City city", value: "Archer City city" },
    { label: "Arcola city", value: "Arcola city" },
    { label: "Argyle town", value: "Argyle town" },
    { label: "Arlington city", value: "Arlington city" },
    { label: "Arp city", value: "Arp city" },
    { label: "Arroyo Alto CDP", value: "Arroyo Alto CDP" },
    { label: "Arroyo Colorado Estates CDP", value: "Arroyo Colorado Estates CDP" },
    { label: "Arroyo Gardens CDP", value: "Arroyo Gardens CDP" },
    { label: "Asherton city", value: "Asherton city" },
    { label: "Aspermont city", value: "Aspermont city" },
    { label: "Atascocita CDP", value: "Atascocita CDP" },
    { label: "Athens city", value: "Athens city" },
    { label: "Atlanta city", value: "Atlanta city" },
    { label: "Aubrey city", value: "Aubrey city" },
    { label: "Aurora city", value: "Aurora city" },
    { label: "Austin city", value: "Austin city" },
    { label: "Austwell city", value: "Austwell city" },
    { label: "Avery town", value: "Avery town" },
    { label: "Avinger town", value: "Avinger town" },
    { label: "Azle city", value: "Azle city" },
    { label: "Bacliff CDP", value: "Bacliff CDP" },
    { label: "Bailey town", value: "Bailey town" },
    { label: "Bailey's Prairie village", value: "Bailey's Prairie village" },
    { label: "Baird city", value: "Baird city" },
    { label: "Balch Springs city", value: "Balch Springs city" },
    { label: "Balcones Heights city", value: "Balcones Heights city" },
    { label: "Ballinger city", value: "Ballinger city" },
    { label: "Balmorhea city", value: "Balmorhea city" },
    { label: "Bandera city", value: "Bandera city" },
    { label: "Bangs city", value: "Bangs city" },
    { label: "Banquete CDP", value: "Banquete CDP" },
    { label: "Bardwell city", value: "Bardwell city" },
    { label: "Barrett town", value: "Barrett town" },
    { label: "Barry city", value: "Barry city" },
    { label: "Barstow city", value: "Barstow city" },
    { label: "Bartlett city", value: "Bartlett city" },
    { label: "Barton Creek CDP", value: "Barton Creek CDP" },
    { label: "Bartonville town", value: "Bartonville town" },
    { label: "Bastrop city", value: "Bastrop city" },
    { label: "Batesville CDP", value: "Batesville CDP" },
    { label: "Bay City city", value: "Bay City city" },
    { label: "Bayou Vista city", value: "Bayou Vista city" },
    { label: "Bayside town", value: "Bayside town" },
    { label: "Baytown city", value: "Baytown city" },
    { label: "Bayview CDP", value: "Bayview CDP" },
    { label: "Beach City city", value: "Beach City city" },
    { label: "Bear Creek CDP", value: "Bear Creek CDP" },
    { label: "Beasley city", value: "Beasley city" },
    { label: "Beaumont city", value: "Beaumont city" },
    { label: "Beckville city", value: "Beckville city" },
    { label: "Bedford city", value: "Bedford city" },
    { label: "Bedias city", value: "Bedias city" },
    { label: "Bee Cave village", value: "Bee Cave village" },
    { label: "Beeville city", value: "Beeville city" },
    { label: "Bellaire city", value: "Bellaire city" },
    { label: "Bellevue city", value: "Bellevue city" },
    { label: "Bellmead city", value: "Bellmead city" },
    { label: "Bells town", value: "Bells town" },
    { label: "Bellville city", value: "Bellville city" },
    { label: "Belton city", value: "Belton city" },
    { label: "Benavides city", value: "Benavides city" },
    { label: "Benbrook city", value: "Benbrook city" },
    { label: "Benjamin town", value: "Benjamin town" },
    { label: "Berryville CDP", value: "Berryville CDP" },
    { label: "Bertram city", value: "Bertram city" },
    { label: "Beverly Hills city", value: "Beverly Hills city" },
    { label: "Bevil Oaks city", value: "Bevil Oaks city" },
    { label: "Bigfoot CDP", value: "Bigfoot CDP" },
    { label: "Big Lake city", value: "Big Lake city" },
    { label: "Big Sandy town", value: "Big Sandy town" },
    { label: "Big Spring city", value: "Big Spring city" },
    { label: "Big Wells city", value: "Big Wells city" },
    { label: "Bishop city", value: "Bishop city" },
    { label: "Bishop Hills town", value: "Bishop Hills town" },
    { label: "Bixby CDP", value: "Bixby CDP" },
    { label: "Blackwell town", value: "Blackwell town" },
    { label: "Blanco city", value: "Blanco city" },
    { label: "Blanket town", value: "Blanket town" },
    { label: "Bledsoe town", value: "Bledsoe town" },
    { label: "Blessing town", value: "Blessing town" },
    { label: "Bloomburg town", value: "Bloomburg town" },
    { label: "Blooming Grove town", value: "Blooming Grove town" },
    { label: "Bloomington CDP", value: "Bloomington CDP" },
    { label: "Blossom city", value: "Blossom city" },
    { label: "Blue Berry Hill town", value: "Blue Berry Hill town" },
    { label: "Blue Mound city", value: "Blue Mound city" },
    { label: "Blue Ridge town", value: "Blue Ridge town" },
    { label: "Bluetown-Iglesia Antigua CDP", value: "Bluetown-Iglesia Antigua CDP" },
    { label: "Blum city", value: "Blum city" },
    { label: "Boerne city", value: "Boerne city" },
    { label: "Bogata city", value: "Bogata city" },
    { label: "Boling-Iago city", value: "Boling-Iago city" },
    { label: "Bolivar Peninsula CDP", value: "Bolivar Peninsula CDP" },
    { label: "Bonham city", value: "Bonham city" },
    { label: "Bonney village", value: "Bonney village" },
    { label: "Booker city", value: "Booker city" },
    { label: "Borger city", value: "Borger city" },
    { label: "Botines CDP", value: "Botines CDP" },
    { label: "Bovina city", value: "Bovina city" },
    { label: "Bowie city", value: "Bowie city" },
    { label: "Box Canyon CDP", value: "Box Canyon CDP" },
    { label: "Boyd town", value: "Boyd town" },
    { label: "Boys Ranch CDP", value: "Boys Ranch CDP" },
    { label: "Brackettville city", value: "Brackettville city" },
    { label: "Brady city", value: "Brady city" },
    { label: "Brazoria village", value: "Brazoria village" },
    { label: "Breckenridge city", value: "Breckenridge city" },
    { label: "Bremond city", value: "Bremond city" },
    { label: "Brenham city", value: "Brenham city" },
    { label: "Briar town", value: "Briar town" },
    { label: "Briarcliff village", value: "Briarcliff village" },
    { label: "Briaroaks city", value: "Briaroaks city" },
    { label: "Bridge City city", value: "Bridge City city" },
    { label: "Bridgeport city", value: "Bridgeport city" },
    { label: "Broaddus town", value: "Broaddus town" },
    { label: "Bronte town", value: "Bronte town" },
    { label: "Brookshire city", value: "Brookshire city" },
    { label: "Brookside Village city", value: "Brookside Village city" },
    { label: "Browndell city", value: "Browndell city" },
    { label: "Brownfield city", value: "Brownfield city" },
    { label: "Brownsboro city", value: "Brownsboro city" },
    { label: "Brownsville city", value: "Brownsville city" },
    { label: "Brownwood city", value: "Brownwood city" },
    { label: "Bruceville-Eddy city", value: "Bruceville-Eddy city" },
    { label: "Brundage CDP", value: "Brundage CDP" },
    { label: "Bruni city", value: "Bruni city" },
    { label: "Brushy Creek CDP", value: "Brushy Creek CDP" },
    { label: "Bryan city", value: "Bryan city" },
    { label: "Bryson city", value: "Bryson city" },
    { label: "Buchanan Dam CDP", value: "Buchanan Dam CDP" },
    { label: "Buckholts town", value: "Buckholts town" },
    { label: "Buckingham CDP", value: "Buckingham CDP" },
    { label: "Buda city", value: "Buda city" },
    { label: "Buenaventura Lakes CDP", value: "Buenaventura Lakes CDP" },
    { label: "Buffalo city", value: "Buffalo city" },
    { label: "Buffalo Gap town", value: "Buffalo Gap town" },
    { label: "Buffalo Springs town", value: "Buffalo Springs town" },
    { label: "Bullard city", value: "Bullard city" },
    { label: "Bulverde city", value: "Bulverde city" },
    { label: "Buna CDP", value: "Buna CDP" },
    { label: "Bunker Hill Village city", value: "Bunker Hill Village city" },
    { label: "Burkburnett city", value: "Burkburnett city" },
    { label: "Burke CDP", value: "Burke CDP" },
    { label: "Burleson city", value: "Burleson city" },
    { label: "Burnet city", value: "Burnet city" },
    { label: "Burton town", value: "Burton town" },
    { label: "Butterfield CDP", value: "Butterfield CDP" },
    { label: "Byers city", value: "Byers city" },
    { label: "Bynum town", value: "Bynum town" },
    { label: "Cactus town", value: "Cactus town" },
    { label: "Caddo Mills city", value: "Caddo Mills city" },
    { label: "Caldwell city", value: "Caldwell city" },
    { label: "Callender Lake CDP", value: "Callender Lake CDP" },
    { label: "Callisburg town", value: "Callisburg town" },
    { label: "Calvert city", value: "Calvert city" },
    { label: "Cameron city", value: "Cameron city" },
    { label: "Campbell city", value: "Campbell city" },
    { label: "Campo Verde CDP", value: "Campo Verde CDP" },
    { label: "Camp Swift CDP", value: "Camp Swift CDP" },
    { label: "Camp Wood city", value: "Camp Wood city" },
    { label: "Canadian city", value: "Canadian city" },
    { label: "Caney City town", value: "Caney City town" },
    { label: "Canton city", value: "Canton city" },
    { label: "Cantu Addition CDP", value: "Cantu Addition CDP" },
    { label: "Canutillo CDP", value: "Canutillo CDP" },
    { label: "Canyon city", value: "Canyon city" },
    { label: "Canyon Creek CDP", value: "Canyon Creek CDP" },
    { label: "Canyon Lake CDP", value: "Canyon Lake CDP" },
    { label: "Carbon town", value: "Carbon town" },
    { label: "Carlsbad city", value: "Carlsbad city" },
    { label: "Carmine city", value: "Carmine city" },
    { label: "Carrizo Hill CDP", value: "Carrizo Hill CDP" },
    { label: "Carrizo Springs city", value: "Carrizo Springs city" },
    { label: "Carrollton city", value: "Carrollton city" },
    { label: "Carthage city", value: "Carthage city" },
    { label: "Castle Hills city", value: "Castle Hills city" },
    { label: "Castroville city", value: "Castroville city" },
    { label: "Catarina city", value: "Catarina city" },
    { label: "Cedar Hill city", value: "Cedar Hill city" },
    { label: "Cedar Park city", value: "Cedar Park city" },
    { label: "Celeste city", value: "Celeste city" },
    { label: "Celina city", value: "Celina city" },
    { label: "Center city", value: "Center city" },
    { label: "Centerville city", value: "Centerville city" },
    { label: "Central Gardens CDP", value: "Central Gardens CDP" },
    { label: "Cesar Chavez CDP", value: "Cesar Chavez CDP" },
    { label: "Chandler city", value: "Chandler city" },
    { label: "Channelview CDP", value: "Channelview CDP" },
    { label: "Channing city", value: "Channing city" },
    { label: "Chaparrito CDP", value: "Chaparrito CDP" },
    { label: "Chappell Hill city", value: "Chappell Hill city" },
    { label: "Charco CDP", value: "Charco CDP" },
    { label: "Charlotte city", value: "Charlotte city" },
    { label: "Chateau Woods CDP", value: "Chateau Woods CDP" },
    { label: "Chester town", value: "Chester town" },
    { label: "Chico city", value: "Chico city" },
    { label: "Childress city", value: "Childress city" },
    { label: "Chillicothe city", value: "Chillicothe city" },
    { label: "Chilton city", value: "Chilton city" },
    { label: "China CDP", value: "China CDP" },
    { label: "China Grove city", value: "China Grove city" },
    { label: "China Spring CDP", value: "China Spring CDP" },
    { label: "Chireno town", value: "Chireno town" },
    { label: "Christine city", value: "Christine city" },
    { label: "Christoval CDP", value: "Christoval CDP" },
    { label: "Chula Vista-Orason CDP", value: "Chula Vista-Orason CDP" },
    { label: "Chula Vista-River Spur CDP", value: "Chula Vista-River Spur CDP" },
    { label: "Chula Vista-Stone City CDP", value: "Chula Vista-Stone City CDP" },
    { label: "Chula Vista-Webb County CDP", value: "Chula Vista-Webb County CDP" },
    { label: "Cibolo city", value: "Cibolo city" },
    { label: "Cienegas Terrace CDP", value: "Cienegas Terrace CDP" },
    { label: "Cinco Ranch CDP", value: "Cinco Ranch CDP" },
    { label: "Circle D-KC Estates CDP", value: "Circle D-KC Estates CDP" },
    { label: "Cisco city", value: "Cisco city" },
    { label: "Citrus City CDP", value: "Citrus City CDP" },
    { label: "Clarksville city", value: "Clarksville city" },
    { label: "Clarksville City town", value: "Clarksville City town" },
    { label: "Clay city", value: "Clay city" },
    { label: "Clayton city", value: "Clayton city" },
    { label: "Clear Lake Shores city", value: "Clear Lake Shores city" },
    { label: "Cleburne city", value: "Cleburne city" },
    { label: "Cleveland city", value: "Cleveland city" },
    { label: "Clifton city", value: "Clifton city" },
    { label: "Clint CDP", value: "Clint CDP" },
    { label: "Cloverleaf CDP", value: "Cloverleaf CDP" },
    { label: "Clute city", value: "Clute city" },
    { label: "Clyde city", value: "Clyde city" },
    { label: "Coahoma city", value: "Coahoma city" },
    { label: "Cockrell Hill city", value: "Cockrell Hill city" },
    { label: "Coffee City town", value: "Coffee City town" },
    { label: "Coldspring town", value: "Coldspring town" },
    { label: "Coleman city", value: "Coleman city" },
    { label: "College Station city", value: "College Station city" },
    { label: "Colleyville city", value: "Colleyville city" },
    { label: "Collinsville town", value: "Collinsville town" },
    { label: "Colmesneil city", value: "Colmesneil city" },
    { label: "Colorado Acres CDP", value: "Colorado Acres CDP" },
    { label: "Colorado City city", value: "Colorado City city" },
    { label: "Columbus city", value: "Columbus city" },
    { label: "Comanche city", value: "Comanche city" },
    { label: "Combes city", value: "Combes city" },
    { label: "Combine city", value: "Combine city" },
    { label: "Comfort city", value: "Comfort city" },
    { label: "Commerce city", value: "Commerce city" },
    { label: "Como town", value: "Como town" },
    { label: "Concepcion city", value: "Concepcion city" },
    { label: "Conroe city", value: "Conroe city" },
    { label: "Converse city", value: "Converse city" },
    { label: "Cool city", value: "Cool city" },
    { label: "Cooper city", value: "Cooper city" },
    { label: "Coppell city", value: "Coppell city" },
    { label: "Copper Canyon town", value: "Copper Canyon town" },
    { label: "Copperas Cove city", value: "Copperas Cove city" },
    { label: "Copperhill CDP", value: "Copperhill CDP" },
    { label: "Copper's Cove city", value: "Copper's Cove city" },
    { label: "Corinth city", value: "Corinth city" },
    { label: "Corpus Christi city", value: "Corpus Christi city" },
    { label: "Corral City town", value: "Corral City town" },
    { label: "Corrigan city", value: "Corrigan city" },
    { label: "Corsicana city", value: "Corsicana city" },
    { label: "Cottonwood CDP", value: "Cottonwood CDP" },
    { label: "Cottonwood Shores city", value: "Cottonwood Shores city" },
    { label: "Cotulla city", value: "Cotulla city" },
    { label: "Cove city", value: "Cove city" },
    { label: "Covington city", value: "Covington city" },
    { label: "Coyanosa CDP", value: "Coyanosa CDP" },
    { label: "Coyote Acres city", value: "Coyote Acres city" },
    { label: "Coyote Flats town", value: "Coyote Flats town" },
    { label: "Crandall city", value: "Crandall city" },
    { label: "Crane city", value: "Crane city" },
    { label: "Cranfills Gap city", value: "Cranfills Gap city" },
    { label: "Crawford city", value: "Crawford city" },
    { label: "Creedmoor city", value: "Creedmoor city" },
    { label: "Cresson city", value: "Cresson city" },
    { label: "Crockett city", value: "Crockett city" },
    { label: "Crosby city", value: "Crosby city" },
    { label: "Crosbyton city", value: "Crosbyton city" },
    { label: "Cross Mountain CDP", value: "Cross Mountain CDP" },
    { label: "Cross Plains city", value: "Cross Plains city" },
    { label: "Cross Roads town", value: "Cross Roads town" },
    { label: "Cross Timber town", value: "Cross Timber town" },
    { label: "Crowell city", value: "Crowell city" },
    { label: "Crowley city", value: "Crowley city" },
    { label: "Crystal City city", value: "Crystal City city" },
    { label: "Cuero city", value: "Cuero city" },
    { label: "Cuevitas CDP", value: "Cuevitas CDP" },
    { label: "Cumby city", value: "Cumby city" },
    { label: "Cumings CDP", value: "Cumings CDP" },
    { label: "Cuney town", value: "Cuney town" },
    { label: "Cushing city", value: "Cushing city" },
    { label: "Cut and Shoot city", value: "Cut and Shoot city" },
    { label: "Daingerfield city", value: "Daingerfield city" },
    { label: "Daisetta city", value: "Daisetta city" },
    { label: "Dalhart city", value: "Dalhart city" },
    { label: "Dallas city", value: "Dallas city" },
    { label: "Dalworthington Gardens city", value: "Dalworthington Gardens city" },
    { label: "Damon city", value: "Damon city" },
    { label: "Danbury city", value: "Danbury city" },
    { label: "Darrouzett city", value: "Darrouzett city" },
    { label: "Dawson city", value: "Dawson city" },
    { label: "Dayton city", value: "Dayton city" },
    { label: "Dayton Lakes city", value: "Dayton Lakes city" },
    { label: "De Leon city", value: "De Leon city" },
    { label: "Dean city", value: "Dean city" },
    { label: "Decatur city", value: "Decatur city" },
    { label: "DeCordova city", value: "DeCordova city" },
    { label: "Deer Park city", value: "Deer Park city" },
    { label: "Delaware Water Gap town", value: "Delaware Water Gap town" },
    { label: "Del Mar Heights CDP", value: "Del Mar Heights CDP" },
    { label: "Del Rio city", value: "Del Rio city" },
    { label: "Del Sol-Loma Linda CDP", value: "Del Sol-Loma Linda CDP" },
    { label: "Denison city", value: "Denison city" },
    { label: "Denton city", value: "Denton city" },
    { label: "Denver City city", value: "Denver City city" },
    { label: "Desoto city", value: "Desoto city" },
    { label: "Detroit city", value: "Detroit city" },
    { label: "Devers town", value: "Devers town" },
    { label: "Devine city", value: "Devine city" },
    { label: "Deweyville CDP", value: "Deweyville CDP" },
    { label: "Diboll city", value: "Diboll city" },
    { label: "Dickens town", value: "Dickens town" },
    { label: "Dickinson city", value: "Dickinson city" },
    { label: "Dilley city", value: "Dilley city" },
    { label: "Dimmitt city", value: "Dimmitt city" },
    { label: "Discovery Bay CDP", value: "Discovery Bay CDP" },
    { label: "DISH town", value: "DISH town" },
    { label: "Dodd City town", value: "Dodd City town" },
    { label: "Doffing CDP", value: "Doffing CDP" },
    { label: "Donna city", value: "Donna city" },
    { label: "Doolittle CDP", value: "Doolittle CDP" },
    { label: "Dorchester city", value: "Dorchester city" },
    { label: "Double Oak town", value: "Double Oak town" },
    { label: "Douglass town", value: "Douglass town" },
    { label: "Douglassville CDP", value: "Douglassville CDP" },
    { label: "Drain CDP", value: "Drain CDP" },
    { label: "Driftwood city", value: "Driftwood city" },
    { label: "Dripping Springs city", value: "Dripping Springs city" },
    { label: "Dublin city", value: "Dublin city" },
    { label: "Dumas city", value: "Dumas city" },
    { label: "Duncanville city", value: "Duncanville city" },
    { label: "Eagle Lake city", value: "Eagle Lake city" },
    { label: "Eagle Mountain CDP", value: "Eagle Mountain CDP" },
    { label: "Eagle Pass city", value: "Eagle Pass city" },
    { label: "Early city", value: "Early city" },
    { label: "Earth city", value: "Earth city" },
    { label: "East Bernard city", value: "East Bernard city" },
    { label: "Eastland city", value: "Eastland city" },
    { label: "East Mountain city", value: "East Mountain city" },
    { label: "Easton town", value: "Easton town" },
    { label: "East Tawakoni city", value: "East Tawakoni city" },
    { label: "East Texas Center CDP", value: "East Texas Center CDP" },
    { label: "Eastvale city", value: "Eastvale city" },
    { label: "East Williamson CDP", value: "East Williamson CDP" },
    { label: "Eastwood Estates CDP", value: "Eastwood Estates CDP" },
    { label: "Ector city", value: "Ector city" },
    { label: "Edcouch city", value: "Edcouch city" },
    { label: "Eden city", value: "Eden city" },
    { label: "Edgecliff Village town", value: "Edgecliff Village town" },
    { label: "Edgewater-Paisano CDP", value: "Edgewater-Paisano CDP" },
    { label: "Edgewood city", value: "Edgewood city" },
    { label: "Edinburg city", value: "Edinburg city" },
    { label: "Edmonson city", value: "Edmonson city" },
    { label: "Edna city", value: "Edna city" },
    { label: "Edom town", value: "Edom town" },
    { label: "Edroy CDP", value: "Edroy CDP" },
    { label: "Egypt city", value: "Egypt city" },
    { label: "Elbert town", value: "Elbert town" },
    { label: "El Camino Angosto CDP", value: "El Camino Angosto CDP" },
    { label: "El Campo city", value: "El Campo city" },
    { label: "El Castillo CDP", value: "El Castillo CDP" },
    { label: "El Cenizo city", value: "El Cenizo city" },
    { label: "Eldorado city", value: "Eldorado city" },
    { label: "Electra city", value: "Electra city" },
    { label: "Elgin city", value: "Elgin city" },
    { label: "El Indio CDP", value: "El Indio CDP" },
    { label: "Elkhart city", value: "Elkhart city" },
    { label: "El Lago city", value: "El Lago city" },
    { label: "Elm Creek city", value: "Elm Creek city" },
    { label: "Elmo town", value: "Elmo town" },
    { label: "El Paso city", value: "El Paso city" },
    { label: "El Quiote CDP", value: "El Quiote CDP" },
    { label: "Elsa city", value: "Elsa city" },
    { label: "Emhouse city", value: "Emhouse city" },
    { label: "Emory city", value: "Emory city" },
    { label: "Encantada-Ranchito El Calaboz CDP", value: "Encantada-Ranchito El Calaboz CDP" },
    { label: "Encinal city", value: "Encinal city" },
    { label: "Encino CDP", value: "Encino CDP" },
    { label: "Ennis city", value: "Ennis city" },
    { label: "Escobares CDP", value: "Escobares CDP" },
    { label: "Estelline city", value: "Estelline city" },
    { label: "Euless city", value: "Euless city" },
    { label: "Eureka city", value: "Eureka city" },
    { label: "Eustace city", value: "Eustace city" },
    { label: "Evadale CDP", value: "Evadale CDP" },
    { label: "Evant town", value: "Evant town" },
    { label: "Evergreen CDP", value: "Evergreen CDP" },
    { label: "Everman city", value: "Everman city" },
    { label: "Fabens CDP", value: "Fabens CDP" },
    { label: "Fairchilds village", value: "Fairchilds village" },
    { label: "Fairfield city", value: "Fairfield city" },
    { label: "Fair Oaks Ranch city", value: "Fair Oaks Ranch city" },
    { label: "Fairview city", value: "Fairview city" },
    { label: "Falcon Heights town", value: "Falcon Heights town" },
    { label: "Falcon Lake Estates CDP", value: "Falcon Lake Estates CDP" },
    { label: "Falcon Mesa CDP", value: "Falcon Mesa CDP" },
    { label: "Falcon Village CDP", value: "Falcon Village CDP" },
    { label: "Falfurrias city", value: "Falfurrias city" },
    { label: "Falls City city", value: "Falls City city" },
    { label: "Falman-County Acres CDP", value: "Falman-County Acres CDP" },
    { label: "Farmers Branch city", value: "Farmers Branch city" },
    { label: "Farmersville city", value: "Farmersville city" },
    { label: "Farwell city", value: "Farwell city" },
    { label: "Fate city", value: "Fate city" },
    { label: "Fayetteville town", value: "Fayetteville town" },
    { label: "Faysville CDP", value: "Faysville CDP" },
    { label: "Ferris city", value: "Ferris city" },
    { label: "Fifth Street CDP", value: "Fifth Street CDP" },
    { label: "Flatonia town", value: "Flatonia town" },
    { label: "Florence city", value: "Florence city" },
    { label: "Floresville city", value: "Floresville city" },
    { label: "Flowella CDP", value: "Flowella CDP" },
    { label: "Flower Mound town", value: "Flower Mound town" },
    { label: "Floydada city", value: "Floydada city" },
    { label: "Follett city", value: "Follett city" },
    { label: "Forest Hill city", value: "Forest Hill city" },
    { label: "Forney city", value: "Forney city" },
    { label: "Forsan town", value: "Forsan town" },
    { label: "Fort Bliss CDP", value: "Fort Bliss CDP" },
    { label: "Fort Davis town", value: "Fort Davis town" },
    { label: "Fort Hancock town", value: "Fort Hancock town" },
    { label: "Fort Hood CDP", value: "Fort Hood CDP" },
    { label: "Fort Stockton city", value: "Fort Stockton city" },
    { label: "Fort Worth city", value: "Fort Worth city" },
    { label: "Four Corners CDP", value: "Four Corners CDP" },
    { label: "Fowlerton town", value: "Fowlerton town" },
    { label: "Franklin city", value: "Franklin city" },
    { label: "Frankston city", value: "Frankston city" },
    { label: "Fredericksburg city", value: "Fredericksburg city" },
    { label: "Freeport city", value: "Freeport city" },
    { label: "Freer city", value: "Freer city" },
    { label: "Fresno CDP", value: "Fresno CDP" },
    { label: "Friendswood city", value: "Friendswood city" },
    { label: "Friona city", value: "Friona city" },
    { label: "Frisco city", value: "Frisco city" },
    { label: "Fritch city", value: "Fritch city" },
    { label: "Fronton town", value: "Fronton town" },
    { label: "Frost city", value: "Frost city" },
    { label: "Fruitvale town", value: "Fruitvale town" },
    { label: "Fulshear city", value: "Fulshear city" },
    { label: "Fulton town", value: "Fulton town" },
    { label: "Gail town", value: "Gail town" },
    { label: "Gainesville city", value: "Gainesville city" },
    { label: "Galena Park city", value: "Galena Park city" },
    { label: "Gallatin CDP", value: "Gallatin CDP" },
    { label: "Galveston city", value: "Galveston city" },
    { label: "Ganado city", value: "Ganado city" },
    { label: "Garceno CDP", value: "Garceno CDP" },
    { label: "Gardendale CDP", value: "Gardendale CDP" },
    { label: "Garden Ridge city", value: "Garden Ridge city" },
    { label: "Garfield town", value: "Garfield town" },
    { label: "Garland city", value: "Garland city" },
    { label: "Garrett town", value: "Garrett town" },
    { label: "Garrison city", value: "Garrison city" },
    { label: "Gary City town", value: "Gary City town" },
    { label: "Gatesville city", value: "Gatesville city" },
    { label: "George West city", value: "George West city" },
    { label: "Georgetown city", value: "Georgetown city" },
    { label: "Geronimo CDP", value: "Geronimo CDP" },
    { label: "Gholson town", value: "Gholson town" },
    { label: "Giddings city", value: "Giddings city" },
    { label: "Gilmer city", value: "Gilmer city" },
    { label: "Girard town", value: "Girard town" },
    { label: "Gladewater city", value: "Gladewater city" },
    { label: "Glenn Heights city", value: "Glenn Heights city" },
    { label: "Glidden city", value: "Glidden city" },
    { label: "Godley city", value: "Godley city" },
    { label: "Goldsmith city", value: "Goldsmith city" },
    { label: "Goldthwaite city", value: "Goldthwaite city" },
    { label: "Goliad city", value: "Goliad city" },
    { label: "Golinda city", value: "Golinda city" },
    { label: "Gonzales city", value: "Gonzales city" },
    { label: "Goodlow town", value: "Goodlow town" },
    { label: "Goodrich city", value: "Goodrich city" },
    { label: "Gordon city", value: "Gordon city" },
    { label: "Goree city", value: "Goree city" },
    { label: "Gorman city", value: "Gorman city" },
    { label: "Graford city", value: "Graford city" },
    { label: "Graham city", value: "Graham city" },
    { label: "Granbury city", value: "Granbury city" },
    { label: "Grand Acres CDP", value: "Grand Acres CDP" },
    { label: "Grandfalls city", value: "Grandfalls city" },
    { label: "Grand Prairie city", value: "Grand Prairie city" },
    { label: "Grand Saline city", value: "Grand Saline city" },
    { label: "Grandview city", value: "Grandview city" },
    { label: "Granger city", value: "Granger city" },
    { label: "Granite Shoals city", value: "Granite Shoals city" },
    { label: "Granjeno city", value: "Granjeno city" },
    { label: "Granjeno CDP", value: "Granjeno CDP" },
    { label: "Grape Creek CDP", value: "Grape Creek CDP" },
    { label: "Grapeland city", value: "Grapeland city" },
    { label: "Grapevine city", value: "Grapevine city" },
    { label: "Grays Prairie village", value: "Grays Prairie village" },
    { label: "Greatwood CDP", value: "Greatwood CDP" },
    { label: "Green Valley Farms CDP", value: "Green Valley Farms CDP" },
    { label: "Greenville city", value: "Greenville city" },
    { label: "Gregory city", value: "Gregory city" },
    { label: "Grey Forest city", value: "Grey Forest city" },
    { label: "Groesbeck city", value: "Groesbeck city" },
    { label: "Groom city", value: "Groom city" },
    { label: "Groves city", value: "Groves city" },
    { label: "Groveton city", value: "Groveton city" },
    { label: "Gruver city", value: "Gruver city" },
    { label: "Guadalupe-Guerra CDP", value: "Guadalupe-Guerra CDP" },
    { label: "Guadalupe Heights CDP", value: "Guadalupe Heights CDP" },
    { label: "Gulfport town", value: "Gulfport town" },
    { label: "Gun Barrel City city", value: "Gun Barrel City city" },
    { label: "Gunter city", value: "Gunter city" },
    { label: "Gustine town", value: "Gustine town" },
    { label: "Guthrie town", value: "Guthrie town" },
    { label: "Gutierrez CDP", value: "Gutierrez CDP" },
    { label: "Guy town", value: "Guy town" },
    { label: "Hale Center city", value: "Hale Center city" },
    { label: "Hallettsville city", value: "Hallettsville city" },
    { label: "Hallsburg town", value: "Hallsburg town" },
    { label: "Hallsville city", value: "Hallsville city" },
    { label: "Haltom City city", value: "Haltom City city" },
    { label: "Hamilton city", value: "Hamilton city" },
    { label: "Hamlin city", value: "Hamlin city" },
    { label: "Happy city", value: "Happy city" },
    { label: "Hardin city", value: "Hardin city" },
    { label: "Harker Heights city", value: "Harker Heights city" },
    { label: "Harlingen city", value: "Harlingen city" },
    { label: "Harper town", value: "Harper town" },
    { label: "Hart city", value: "Hart city" },
    { label: "Hartley town", value: "Hartley town" },
    { label: "Haskell city", value: "Haskell city" },
    { label: "Haslet city", value: "Haslet city" },
    { label: "Havana CDP", value: "Havana CDP" },
    { label: "Hawk Cove city", value: "Hawk Cove city" },
    { label: "Hawkins city", value: "Hawkins city" },
    { label: "Hawley city", value: "Hawley city" },
    { label: "Hays CDP", value: "Hays CDP" },
    { label: "Hearne city", value: "Hearne city" },
    { label: "Heath city", value: "Heath city" },
    { label: "Hebbronville city", value: "Hebbronville city" },
    { label: "Hebron city", value: "Hebron city" },
    { label: "Hedley town", value: "Hedley town" },
    { label: "Hedwig Village city", value: "Hedwig Village city" },
    { label: "Heidelberg CDP", value: "Heidelberg CDP" },
    { label: "Helotes city", value: "Helotes city" },
    { label: "Hemphill city", value: "Hemphill city" },
    { label: "Hempstead city", value: "Hempstead city" },
    { label: "Henderson city", value: "Henderson city" },
    { label: "Henrietta city", value: "Henrietta city" },
    { label: "Hereford city", value: "Hereford city" },
    { label: "Hermleigh town", value: "Hermleigh town" },
    { label: "Hewitt city", value: "Hewitt city" },
    { label: "Hickory Creek city", value: "Hickory Creek city" },
    { label: "Hico city", value: "Hico city" },
    { label: "Hidalgo city", value: "Hidalgo city" },
    { label: "Hideaway city", value: "Hideaway city" },
    { label: "Higgins city", value: "Higgins city" },
    { label: "Highland Haven city", value: "Highland Haven city" },
    { label: "Highland Park town", value: "Highland Park town" },
    { label: "Highland Village city", value: "Highland Village city" },
    { label: "Highlands CDP", value: "Highlands CDP" },
    { label: "Hill Country Village city", value: "Hill Country Village city" },
    { label: "Hillcrest village", value: "Hillcrest village" },
    { label: "Hillsboro city", value: "Hillsboro city" },
    { label: "Hilltop CDP", value: "Hilltop CDP" },
    { label: "Hilshire Village city", value: "Hilshire Village city" },
    { label: "Hitchcock city", value: "Hitchcock city" },
    { label: "Holiday Beach CDP", value: "Holiday Beach CDP" },
    { label: "Holiday Lakes city", value: "Holiday Lakes city" },
    { label: "Holland town", value: "Holland town" },
    { label: "Holliday city", value: "Holliday city" },
    { label: "Hollywood Park town", value: "Hollywood Park town" },
    { label: "Homestead Meadows South CDP", value: "Homestead Meadows South CDP" },
    { label: "Homestead Meadows North CDP", value: "Homestead Meadows North CDP" },
    { label: "Hondo city", value: "Hondo city" },
    { label: "Honey Grove city", value: "Honey Grove city" },
    { label: "Hooks city", value: "Hooks city" },
    { label: "Horizon City city", value: "Horizon City city" },
    { label: "Hornsby Bend CDP", value: "Hornsby Bend CDP" },
    { label: "Horseshoe Bay city", value: "Horseshoe Bay city" },
    { label: "Houston city", value: "Houston city" },
    { label: "Howardwick city", value: "Howardwick city" },
    { label: "Howe town", value: "Howe town" },
    { label: "Hubbard city", value: "Hubbard city" },
    { label: "Hudson city", value: "Hudson city" },
    { label: "Hudson Bend CDP", value: "Hudson Bend CDP" },
    { label: "Hudson Oaks city", value: "Hudson Oaks city" },
    { label: "Hughes Springs city", value: "Hughes Springs city" },
    { label: "Humble city", value: "Humble city" },
    { label: "Hungerford CDP", value: "Hungerford CDP" },
    { label: "Hunters Creek Village city", value: "Hunters Creek Village city" },
    { label: "Huntington city", value: "Huntington city" },
    { label: "Huntsville city", value: "Huntsville city" },
    { label: "Hurst city", value: "Hurst city" },
    { label: "Hutchins city", value: "Hutchins city" },
    { label: "Hutto city", value: "Hutto city" },
    { label: "Huxley city", value: "Huxley city" },
    { label: "Idalou city", value: "Idalou city" },
    { label: "Impact CDP", value: "Impact CDP" },
    { label: "Imperial CDP", value: "Imperial CDP" },
    { label: "Indian Hills CDP", value: "Indian Hills CDP" },
    { label: "Indian Lake town", value: "Indian Lake town" },
    { label: "Indian Springs town", value: "Indian Springs town" },
    { label: "Industry city", value: "Industry city" },
    { label: "Inez CDP", value: "Inez CDP" },
    { label: "Ingleside city", value: "Ingleside city" },
    { label: "Ingleside on the Bay city", value: "Ingleside on the Bay city" },
    { label: "Ingram city", value: "Ingram city" },
    { label: "Iola town", value: "Iola town" },
    { label: "Iowa Colony city", value: "Iowa Colony city" },
    { label: "Iowa Park city", value: "Iowa Park city" },
    { label: "Iraan city", value: "Iraan city" },
    { label: "Iredell town", value: "Iredell town" },
    { label: "Irving city", value: "Irving city" },
    { label: "Italy town", value: "Italy town" },
    { label: "Itasca city", value: "Itasca city" },
    { label: "Jacinto City city", value: "Jacinto City city" },
    { label: "Jacksboro city", value: "Jacksboro city" },
    { label: "Jacksonville city", value: "Jacksonville city" },
    { label: "Jamaica Beach city", value: "Jamaica Beach city" },
    { label: "Jasper city", value: "Jasper city" },
    { label: "Jayton city", value: "Jayton city" },
    { label: "Jefferson city", value: "Jefferson city" },
    { label: "Jersey Village city", value: "Jersey Village city" },
    { label: "Jewett city", value: "Jewett city" },
    { label: "Joaquin city", value: "Joaquin city" },
    { label: "Johnson City city", value: "Johnson City city" },
    { label: "Jollyville CDP", value: "Jollyville CDP" },
    { label: "Jones Creek village", value: "Jones Creek village" },
    { label: "Jonestown city", value: "Jonestown city" },
    { label: "Josephine city", value: "Josephine city" },
    { label: "Joshua city", value: "Joshua city" },
    { label: "Jourdanton city", value: "Jourdanton city" },
    { label: "Junction city", value: "Junction city" },
    { label: "Justin city", value: "Justin city" },
    { label: "Karnes City city", value: "Karnes City city" },
    { label: "Katy city", value: "Katy city" },
    { label: "Kaufman city", value: "Kaufman city" },
    { label: "K-Bar Ranch CDP", value: "K-Bar Ranch CDP" },
    { label: "Keene city", value: "Keene city" },
    { label: "Keller city", value: "Keller city" },
    { label: "Kemah city", value: "Kemah city" },
    { label: "Kemp city", value: "Kemp city" },
    { label: "Kempner city", value: "Kempner city" },
    { label: "Kendleton city", value: "Kendleton city" },
    { label: "Kenedy city", value: "Kenedy city" },
    { label: "Kenefick town", value: "Kenefick town" },
    { label: "Kennard town", value: "Kennard town" },
    { label: "Kennedale city", value: "Kennedale city" },
    { label: "Kerens city", value: "Kerens city" },
    { label: "Kermit city", value: "Kermit city" },
    { label: "Kerrville city", value: "Kerrville city" },
    { label: "Kilgore city", value: "Kilgore city" },
    { label: "Killeen city", value: "Killeen city" },
    { label: "Kingsbury city", value: "Kingsbury city" },
    { label: "Kingsland city", value: "Kingsland city" },
    { label: "Kingsville city", value: "Kingsville city" },
    { label: "Kirby city", value: "Kirby city" },
    { label: "Kirbyville city", value: "Kirbyville city" },
    { label: "Kirvin city", value: "Kirvin city" },
    { label: "Knippa CDP", value: "Knippa CDP" },
    { label: "Knollwood town", value: "Knollwood town" },
    { label: "Knox City city", value: "Knox City city" },
    { label: "Kosse town", value: "Kosse town" },
    { label: "Kountze city", value: "Kountze city" },
    { label: "Kress town", value: "Kress town" },
    { label: "Krugerville town", value: "Krugerville town" },
    { label: "Krum city", value: "Krum city" },
    { label: "Kyle city", value: "Kyle city" },
    { label: "La Blanca CDP", value: "La Blanca CDP" },
    { label: "La Casita CDP", value: "La Casita CDP" },
    { label: "Lackland AFB CDP", value: "Lackland AFB CDP" },
    { label: "LaCoste city", value: "LaCoste city" },
    { label: "Lacy-Lakeview city", value: "Lacy-Lakeview city" },
    { label: "Ladonia town", value: "Ladonia town" },
    { label: "La Feria city", value: "La Feria city" },
    { label: "Lago city", value: "Lago city" },
    { label: "Lago Vista city", value: "Lago Vista city" },
    { label: "La Grange city", value: "La Grange city" },
    { label: "La Grulla city", value: "La Grulla city" },
    { label: "Laguna Heights CDP", value: "Laguna Heights CDP" },
    { label: "Laguna Seca CDP", value: "Laguna Seca CDP" },
    { label: "Laguna Vista town", value: "Laguna Vista town" },
    { label: "La Homa CDP", value: "La Homa CDP" },
    { label: "La Joya city", value: "La Joya city" },
    { label: "Lake Bridgeport city", value: "Lake Bridgeport city" },
    { label: "Lake Brownwood city", value: "Lake Brownwood city" },
    { label: "Lake City city", value: "Lake City city" },
    { label: "Lake Dallas city", value: "Lake Dallas city" },
    { label: "Lake Dunlap CDP", value: "Lake Dunlap CDP" },
    { label: "Lakehills CDP", value: "Lakehills CDP" },
    { label: "Lake Jackson city", value: "Lake Jackson city" },
    { label: "Lake Kiowa CDP", value: "Lake Kiowa CDP" },
    { label: "Lake Meredith Estates CDP", value: "Lake Meredith Estates CDP" },
    { label: "Lake Tanglewood village", value: "Lake Tanglewood village" },
    { label: "Lakeview city", value: "Lakeview city" },
    { label: "Lake Worth city", value: "Lake Worth city" },
    { label: "La Marque city", value: "La Marque city" },
    { label: "Lamesa city", value: "Lamesa city" },
    { label: "Lampasas city", value: "Lampasas city" },
    { label: "Lancaster city", value: "Lancaster city" },
    { label: "La Paloma-Lost Creek CDP", value: "La Paloma-Lost Creek CDP" },
    { label: "La Paloma Addition CDP", value: "La Paloma Addition CDP" },
    { label: "La Porte city", value: "La Porte city" },
    { label: "Laredo city", value: "Laredo city" },
    { label: "Laredo Ranchettes CDP", value: "Laredo Ranchettes CDP" },
    { label: "Larga Vista CDP", value: "Larga Vista CDP" },
    { label: "La Rosita CDP", value: "La Rosita CDP" },
    { label: "Lasana CDP", value: "Lasana CDP" },
    { label: "Lasara CDP", value: "Lasara CDP" },
    { label: "La Serna CDP", value: "La Serna CDP" },
    { label: "Las Lomas CDP", value: "Las Lomas CDP" },
    { label: "Las Palmas CDP", value: "Las Palmas CDP" },
    { label: "Las Palmas II CDP", value: "Las Palmas II CDP" },
    { label: "Las Pilas CDP", value: "Las Pilas CDP" },
    { label: "Las Quintas Fronterizas CDP", value: "Las Quintas Fronterizas CDP" },
    { label: "Latexo town", value: "Latexo town" },
    { label: "Laughlin AFB CDP", value: "Laughlin AFB CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Laureles CDP", value: "Laureles CDP" },
    { label: "Lavernia city", value: "Lavernia city" },
    { label: "La Victoria CDP", value: "La Victoria CDP" },
    { label: "La Villa city", value: "La Villa city" },
    { label: "La Ward city", value: "La Ward city" },
    { label: "Lawn town", value: "Lawn town" },
    { label: "League City city", value: "League City city" },
    { label: "Leakey city", value: "Leakey city" },
    { label: "Leander city", value: "Leander city" },
    { label: "Leary town", value: "Leary town" },
    { label: "Lefors city", value: "Lefors city" },
    { label: "Leona town", value: "Leona town" },
    { label: "Leonard town", value: "Leonard town" },
    { label: "Leroy town", value: "Leroy town" },
    { label: "Levelland city", value: "Levelland city" },
    { label: "Lewisville city", value: "Lewisville city" },
    { label: "Lexington city", value: "Lexington city" },
    { label: "Liberty city", value: "Liberty city" },
    { label: "Liberty city", value: "Liberty city" },
    { label: "Liberty Hill city", value: "Liberty Hill city" },
    { label: "Lincoln Park town", value: "Lincoln Park town" },
    { label: "Lindale city", value: "Lindale city" },
    { label: "Linden city", value: "Linden city" },
    { label: "Lindsay city", value: "Lindsay city" },
    { label: "Lindsay CDP", value: "Lindsay CDP" },
    { label: "Linn town", value: "Linn town" },
    { label: "Lipan city", value: "Lipan city" },
    { label: "Lipscomb city", value: "Lipscomb city" },
    { label: "Little Elm city", value: "Little Elm city" },
    { label: "Little River-Academy city", value: "Little River-Academy city" },
    { label: "Littlefield city", value: "Littlefield city" },
    { label: "Little River city", value: "Little River city" },
    { label: "Live Oak city", value: "Live Oak city" },
    { label: "Liverpool city", value: "Liverpool city" },
    { label: "Livingston city", value: "Livingston city" },
    { label: "Llano city", value: "Llano city" },
    { label: "Llano Grande CDP", value: "Llano Grande CDP" },
    { label: "Lockhart city", value: "Lockhart city" },
    { label: "Lockney city", value: "Lockney city" },
    { label: "Log Cabin city", value: "Log Cabin city" },
    { label: "Lolita CDP", value: "Lolita CDP" },
    { label: "Loma Linda East CDP", value: "Loma Linda East CDP" },
    { label: "Lometa city", value: "Lometa city" },
    { label: "Lone Oak city", value: "Lone Oak city" },
    { label: "Lone Star city", value: "Lone Star city" },
    { label: "Longoria CDP", value: "Longoria CDP" },
    { label: "Longview city", value: "Longview city" },
    { label: "Lopeno CDP", value: "Lopeno CDP" },
    { label: "Lopezville CDP", value: "Lopezville CDP" },
    { label: "Loraine town", value: "Loraine town" },
    { label: "Lorena city", value: "Lorena city" },
    { label: "Lorenzo city", value: "Lorenzo city" },
    { label: "Los Alvarez CDP", value: "Los Alvarez CDP" },
    { label: "Los Angeles CDP", value: "Los Angeles CDP" },
    { label: "Los Arcos CDP", value: "Los Arcos CDP" },
    { label: "Los Arcos CDP", value: "Los Arcos CDP" },
    { label: "Los Arrieros CDP", value: "Los Arrieros CDP" },
    { label: "Los Barreras CDP", value: "Los Barreras CDP" },
    { label: "Los Centenarios CDP", value: "Los Centenarios CDP" },
    { label: "Los Corralitos CDP", value: "Los Corralitos CDP" },
    { label: "Los Corralitos CDP", value: "Los Corralitos CDP" },
    { label: "Los Ebanos CDP", value: "Los Ebanos CDP" },
    { label: "Los Ebanos CDP", value: "Los Ebanos CDP" },
    { label: "Los Ebanos CDP", value: "Los Ebanos CDP" },
    { label: "Los Ebanos CDP", value: "Los Ebanos CDP" },
    { label: "Los Fresnos city", value: "Los Fresnos city" },
    { label: "Los Fresnos CDP", value: "Los Fresnos CDP" },
    { label: "Los Fresnos CDP", value: "Los Fresnos CDP" },
    { label: "Los Fresnos CDP", value: "Los Fresnos CDP" },
    { label: "Los Indios town", value: "Los Indios town" },
    { label: "Los Indios CDP", value: "Los Indios CDP" },
    { label: "Los Leales CDP", value: "Los Leales CDP" },
    { label: "Los Minerales CDP", value: "Los Minerales CDP" },
    { label: "Los Nopalitos CDP", value: "Los Nopalitos CDP" },
    { label: "Los Veteranos II CDP", value: "Los Veteranos II CDP" },
    { label: "Lost Creek CDP", value: "Lost Creek CDP" },
    { label: "Los Villareales CDP", value: "Los Villareales CDP" },
    { label: "Los Villareales CDP", value: "Los Villareales CDP" },
    { label: "Los Ybanez city", value: "Los Ybanez city" },
    { label: "Los Ybanez CDP", value: "Los Ybanez CDP" },
    { label: "Los Ybanez CDP", value: "Los Ybanez CDP" },
    { label: "Los Ybanez CDP", value: "Los Ybanez CDP" },
    { label: "Los Ybanez CDP", value: "Los Ybanez CDP" },
    { label: "Lott city", value: "Lott city" },
    { label: "Louise city", value: "Louise city" },
    { label: "Lovelady city", value: "Lovelady city" },
    { label: "Lowry Crossing city", value: "Lowry Crossing city" },
    { label: "Lozano CDP", value: "Lozano CDP" },
    { label: "Lubbock city", value: "Lubbock city" },
    { label: "Lucas city", value: "Lucas city" },
    { label: "Lueders city", value: "Lueders city" },
    { label: "Lufkin city", value: "Lufkin city" },
    { label: "Luling city", value: "Luling city" },
    { label: "Lumberton city", value: "Lumberton city" },
    { label: "Lyford city", value: "Lyford city" },
    { label: "Lytle city", value: "Lytle city" },
    { label: "Mabank town", value: "Mabank town" },
    { label: "McAllen city", value: "McAllen city" },
    { label: "McCamey city", value: "McCamey city" },
    { label: "McDade city", value: "McDade city" },
    { label: "McGregor city", value: "McGregor city" },
    { label: "McKinney city", value: "McKinney city" },
    { label: "McLean city", value: "McLean city" },
    { label: "McLendon-Chisholm city", value: "McLendon-Chisholm city" },
    { label: "McQueeney CDP", value: "McQueeney CDP" },
    { label: "Madisonville city", value: "Madisonville city" },
    { label: "Magnolia city", value: "Magnolia city" },
    { label: "Malakoff city", value: "Malakoff city" },
    { label: "Malone town", value: "Malone town" },
    { label: "Manchaca CDP", value: "Manchaca CDP" },
    { label: "Manor city", value: "Manor city" },
    { label: "Mansfield city", value: "Mansfield city" },
    { label: "Manvel city", value: "Manvel city" },
    { label: "Marathon city", value: "Marathon city" },
    { label: "Marble Falls city", value: "Marble Falls city" },
    { label: "Marfa city", value: "Marfa city" },
    { label: "Marietta CDP", value: "Marietta CDP" },
    { label: "Marion city", value: "Marion city" },
    { label: "Markham CDP", value: "Markham CDP" },
    { label: "Marlin city", value: "Marlin city" },
    { label: "Marquez city", value: "Marquez city" },
    { label: "Marshall city", value: "Marshall city" },
    { label: "Mart city", value: "Mart city" },
    { label: "Martindale city", value: "Martindale city" },
    { label: "Martinez CDP", value: "Martinez CDP" },
    { label: "Mason city", value: "Mason city" },
    { label: "Matador town", value: "Matador town" },
    { label: "Mathis city", value: "Mathis city" },
    { label: "Maud city", value: "Maud city" },
    { label: "Mauriceville CDP", value: "Mauriceville CDP" },
    { label: "Maypearl city", value: "Maypearl city" },
    { label: "Meadow town", value: "Meadow town" },
    { label: "Meadowlakes city", value: "Meadowlakes city" },
    { label: "Meadows Place city", value: "Meadows Place city" },
    { label: "Medina city", value: "Medina city" },
    { label: "Megargel town", value: "Megargel town" },
    { label: "Melissa city", value: "Melissa city" },
    { label: "Melvin town", value: "Melvin town" },
    { label: "Memphis city", value: "Memphis city" },
    { label: "Menard city", value: "Menard city" },
    { label: "Mercedes city", value: "Mercedes city" },
    { label: "Meridian city", value: "Meridian city" },
    { label: "Merkel city", value: "Merkel city" },
    { label: "Mertens town", value: "Mertens town" },
    { label: "Mertzon city", value: "Mertzon city" },
    { label: "Mesquite city", value: "Mesquite city" },
    { label: "Mexia city", value: "Mexia city" },
    { label: "Miami city", value: "Miami city" },
    { label: "Midland city", value: "Midland city" },
    { label: "Midlothian city", value: "Midlothian city" },
    { label: "Midway CDP", value: "Midway CDP" },
    { label: "Midway city", value: "Midway city" },
    { label: "Midway North CDP", value: "Midway North CDP" },
    { label: "Midway South CDP", value: "Midway South CDP" },
    { label: "Miguel Barrera CDP", value: "Miguel Barrera CDP" },
    { label: "Milam town", value: "Milam town" },
    { label: "Milano town", value: "Milano town" },
    { label: "Mildred town", value: "Mildred town" },
    { label: "Miles city", value: "Miles city" },
    { label: "Milford town", value: "Milford town" },
    { label: "Miller's Cove CDP", value: "Miller's Cove CDP" },
    { label: "Millersview CDP", value: "Millersview CDP" },
    { label: "Millican town", value: "Millican town" },
    { label: "Millsap town", value: "Millsap town" },
    { label: "Mineola city", value: "Mineola city" },
    { label: "Mineral Wells city", value: "Mineral Wells city" },
    { label: "Mingus CDP", value: "Mingus CDP" },
    { label: "Mirando City CDP", value: "Mirando City CDP" },
    { label: "Mission city", value: "Mission city" },
    { label: "Mission Bend CDP", value: "Mission Bend CDP" },
    { label: "Missouri City city", value: "Missouri City city" },
    { label: "Mobeetie city", value: "Mobeetie city" },
    { label: "Mobile City town", value: "Mobile City town" },
    { label: "Monahans city", value: "Monahans city" },
    { label: "Mont Belvieu city", value: "Mont Belvieu city" },
    { label: "Monte Alto city", value: "Monte Alto city" },
    { label: "Monte Grande CDP", value: "Monte Grande CDP" },
    { label: "Montgomery city", value: "Montgomery city" },
    { label: "Moody city", value: "Moody city" },
    { label: "Moore town", value: "Moore town" },
    { label: "Moore Station CDP", value: "Moore Station CDP" },
    { label: "Moraida CDP", value: "Moraida CDP" },
    { label: "Morales-Sanchez CDP", value: "Morales-Sanchez CDP" },
    { label: "Moran city", value: "Moran city" },
    { label: "Morgan city", value: "Morgan city" },
    { label: "Morgan Farm Area CDP", value: "Morgan Farm Area CDP" },
    { label: "Morgan's Point Resort city", value: "Morgan's Point Resort city" },
    { label: "Morning Glory CDP", value: "Morning Glory CDP" },
    { label: "Morse city", value: "Morse city" },
    { label: "Morton city", value: "Morton city" },
    { label: "Moulton city", value: "Moulton city" },
    { label: "Mountain City city", value: "Mountain City city" },
    { label: "Mount Calm city", value: "Mount Calm city" },
    { label: "Mount Enterprise town", value: "Mount Enterprise town" },
    { label: "Mount Pleasant city", value: "Mount Pleasant city" },
    { label: "Mount Vernon city", value: "Mount Vernon city" },
    { label: "Muenster city", value: "Muenster city" },
    { label: "Muleshoe city", value: "Muleshoe city" },
    { label: "Mullin town", value: "Mullin town" },
    { label: "Munday city", value: "Munday city" },
    { label: "Muniz CDP", value: "Muniz CDP" },
    { label: "Murchison town", value: "Murchison town" },
    { label: "Murphy city", value: "Murphy city" },
    { label: "Mustang city", value: "Mustang city" },
    { label: "Mustang Ridge city", value: "Mustang Ridge city" },
    { label: "Nacogdoches city", value: "Nacogdoches city" },
    { label: "Nadar CDP", value: "Nadar CDP" },
    { label: "Naples city", value: "Naples city" },
    { label: "Narciso Pena CDP", value: "Narciso Pena CDP" },
    { label: "Nash town", value: "Nash town" },
    { label: "Nassau Bay city", value: "Nassau Bay city" },
    { label: "Natalia city", value: "Natalia city" },
    { label: "Navarro CDP", value: "Navarro CDP" },
    { label: "Navasota city", value: "Navasota city" },
    { label: "Nazareth city", value: "Nazareth city" },
    { label: "Nederland city", value: "Nederland city" },
    { label: "Needville city", value: "Needville city" },
    { label: "Nesbitt CDP", value: "Nesbitt CDP" },
    { label: "Nevada city", value: "Nevada city" },
    { label: "Newark city", value: "Newark city" },
    { label: "New Berlin city", value: "New Berlin city" },
    { label: "New Boston city", value: "New Boston city" },
    { label: "New Braunfels city", value: "New Braunfels city" },
    { label: "Newcastle city", value: "Newcastle city" },
    { label: "New Chapel Hill city", value: "New Chapel Hill city" },
    { label: "New Deal city", value: "New Deal city" },
    { label: "New Fairview town", value: "New Fairview town" },
    { label: "New Falcon CDP", value: "New Falcon CDP" },
    { label: "New Home town", value: "New Home town" },
    { label: "New Hope city", value: "New Hope city" },
    { label: "New London city", value: "New London city" },
    { label: "New Summerfield city", value: "New Summerfield city" },
    { label: "New Territory CDP", value: "New Territory CDP" },
    { label: "Newton city", value: "Newton city" },
    { label: "New Waverly city", value: "New Waverly city" },
    { label: "Neylandville city", value: "Neylandville city" },
    { label: "Niederwald city", value: "Niederwald city" },
    { label: "Nixon city", value: "Nixon city" },
    { label: "Nocona city", value: "Nocona city" },
    { label: "Nocona Hills town", value: "Nocona Hills town" },
    { label: "Nolan city", value: "Nolan city" },
    { label: "Nolanville city", value: "Nolanville city" },
    { label: "Nome city", value: "Nome city" },
    { label: "Noonday CDP", value: "Noonday CDP" },
    { label: "Nordheim city", value: "Nordheim city" },
    { label: "Normangee city", value: "Normangee city" },
    { label: "Normanna CDP", value: "Normanna CDP" },
    { label: "North Alamo town", value: "North Alamo town" },
    { label: "North Cleveland CDP", value: "North Cleveland CDP" },
    { label: "Northcliff CDP", value: "Northcliff CDP" },
    { label: "North Escobares CDP", value: "North Escobares CDP" },
    { label: "Northlake town", value: "Northlake town" },
    { label: "North Pearsall CDP", value: "North Pearsall CDP" },
    { label: "North Richland Hills city", value: "North Richland Hills city" },
    { label: "North San Pedro CDP", value: "North San Pedro CDP" },
    { label: "Novice town", value: "Novice town" },
    { label: "Nurillo CDP", value: "Nurillo CDP" },
    { label: "Oak Cliff Place CDP", value: "Oak Cliff Place CDP" },
    { label: "Oakhurst city", value: "Oakhurst city" },
    { label: "Oak Leaf city", value: "Oak Leaf city" },
    { label: "Oak Point city", value: "Oak Point city" },
    { label: "Oak Ridge town", value: "Oak Ridge town" },
    { label: "Oak Ridge North city", value: "Oak Ridge North city" },
    { label: "Oak Trail Shores CDP", value: "Oak Trail Shores CDP" },
    { label: "Oak Valley CDP", value: "Oak Valley CDP" },
    { label: "Oakville town", value: "Oakville town" },
    { label: "Oakwood town", value: "Oakwood town" },
    { label: "Obrien town", value: "Obrien town" },
    { label: "Odem city", value: "Odem city" },
    { label: "Odessa city", value: "Odessa city" },
    { label: "O'Donnell city", value: "O'Donnell city" },
    { label: "Oglesby city", value: "Oglesby city" },
    { label: "Oilton city", value: "Oilton city" },
    { label: "Old River-Winfree city", value: "Old River-Winfree city" },
    { label: "Olivarez CDP", value: "Olivarez CDP" },
    { label: "Olmito CDP", value: "Olmito CDP" },
    { label: "Olmito CDP", value: "Olmito CDP" },
    { label: "Olmos Park city", value: "Olmos Park city" },
    { label: "Olney city", value: "Olney city" },
    { label: "Olton city", value: "Olton city" },
    { label: "Omaha city", value: "Omaha city" },
    { label: "Onalaska city", value: "Onalaska city" },
    { label: "Opdyke West town", value: "Opdyke West town" },
    { label: "Orange city", value: "Orange city" },
    { label: "Orange Grove city", value: "Orange Grove city" },
    { label: "Orason CDP", value: "Orason CDP" },
    { label: "Orchard city", value: "Orchard city" },
    { label: "Ore City city", value: "Ore City city" },
    { label: "Overton city", value: "Overton city" },
    { label: "Ovilla city", value: "Ovilla city" },
    { label: "Oyster Creek city", value: "Oyster Creek city" },
    { label: "Ozona city", value: "Ozona city" },
    { label: "Paducah town", value: "Paducah town" },
    { label: "Paint Rock town", value: "Paint Rock town" },
    { label: "Palacios city", value: "Palacios city" },
    { label: "Palestine city", value: "Palestine city" },
    { label: "Palisades village", value: "Palisades village" },
    { label: "Palito Blanco CDP", value: "Palito Blanco CDP" },
    { label: "Palmhurst city", value: "Palmhurst city" },
    { label: "Palm Valley city", value: "Palm Valley city" },
    { label: "Palmview South CDP", value: "Palmview South CDP" },
    { label: "Palmview city", value: "Palmview city" },
    { label: "Palo Blanco CDP", value: "Palo Blanco CDP" },
    { label: "Palo Pinto city", value: "Palo Pinto city" },
    { label: "Paloma Creek CDP", value: "Paloma Creek CDP" },
    { label: "Paloma Creek South CDP", value: "Paloma Creek South CDP" },
    { label: "Palito Blanco CDP", value: "Palito Blanco CDP" },
    { label: "Pamela Heights CDP", value: "Pamela Heights CDP" },
    { label: "Pampa city", value: "Pampa city" },
    { label: "Panhandle city", value: "Panhandle city" },
    { label: "Panorama Village city", value: "Panorama Village city" },
    { label: "Pantego town", value: "Pantego town" },
    { label: "Paradise city", value: "Paradise city" },
    { label: "Paris city", value: "Paris city" },
    { label: "Parker city", value: "Parker city" },
    { label: "Pasadena city", value: "Pasadena city" },
    { label: "Pattison city", value: "Pattison city" },
    { label: "Patton Village city", value: "Patton Village city" },
    { label: "Pawnee city", value: "Pawnee city" },
    { label: "Payne Springs city", value: "Payne Springs city" },
    { label: "Pearland city", value: "Pearland city" },
    { label: "Pearsall city", value: "Pearsall city" },
    { label: "Pecan Acres CDP", value: "Pecan Acres CDP" },
    { label: "Pecan Gap city", value: "Pecan Gap city" },
    { label: "Pecan Grove CDP", value: "Pecan Grove CDP" },
    { label: "Pecan Hill city", value: "Pecan Hill city" },
    { label: "Pecan Plantation CDP", value: "Pecan Plantation CDP" },
    { label: "Pecan Valley CDP", value: "Pecan Valley CDP" },
    { label: "Pecos city", value: "Pecos city" },
    { label: "Pelican Bay city", value: "Pelican Bay city" },
    { label: "Penelope town", value: "Penelope town" },
    { label: "Penitas city", value: "Penitas city" },
    { label: "Pernitas Point village", value: "Pernitas Point village" },
    { label: "Perryton city", value: "Perryton city" },
    { label: "Petersburg town", value: "Petersburg town" },
    { label: "Petrolia town", value: "Petrolia town" },
    { label: "Petronila CDP", value: "Petronila CDP" },
    { label: "Pettus city", value: "Pettus city" },
    { label: "Pflugerville city", value: "Pflugerville city" },
    { label: "Pharr city", value: "Pharr city" },
    { label: "Pilot Point city", value: "Pilot Point city" },
    { label: "Pine Forest city", value: "Pine Forest city" },
    { label: "Pinehurst city", value: "Pinehurst city" },
    { label: "Pineland city", value: "Pineland city" },
    { label: "Pinewood Estates CDP", value: "Pinewood Estates CDP" },
    { label: "Piney Point Village city", value: "Piney Point Village city" },
    { label: "Pittsburg city", value: "Pittsburg city" },
    { label: "Plains city", value: "Plains city" },
    { label: "Plainview city", value: "Plainview city" },
    { label: "Plano city", value: "Plano city" },
    { label: "Pleak village", value: "Pleak village" },
    { label: "Pleasanton city", value: "Pleasanton city" },
    { label: "Pleasant Valley city", value: "Pleasant Valley city" },
    { label: "Plum Grove city", value: "Plum Grove city" },
    { label: "Point city", value: "Point city" },
    { label: "Point Blank CDP", value: "Point Blank CDP" },
    { label: "Point Comfort city", value: "Point Comfort city" },
    { label: "Ponder town", value: "Ponder town" },
    { label: "Port Aransas city", value: "Port Aransas city" },
    { label: "Port Arthur city", value: "Port Arthur city" },
    { label: "Porter Heights CDP", value: "Porter Heights CDP" },
    { label: "Port Isabel city", value: "Port Isabel city" },
    { label: "Portland city", value: "Portland city" },
    { label: "Port Lavaca city", value: "Port Lavaca city" },
    { label: "Port Mansfield CDP", value: "Port Mansfield CDP" },
    { label: "Port Neches city", value: "Port Neches city" },
    { label: "Post city", value: "Post city" },
    { label: "Poteet city", value: "Poteet city" },
    { label: "Poth city", value: "Poth city" },
    { label: "Potosi CDP", value: "Potosi CDP" },
    { label: "Pottsboro city", value: "Pottsboro city" },
    { label: "Powell town", value: "Powell town" },
    { label: "Poynor town", value: "Poynor town" },
    { label: "Prado Verde CDP", value: "Prado Verde CDP" },
    { label: "Prairie View city", value: "Prairie View city" },
    { label: "Premont city", value: "Premont city" },
    { label: "Presidio city", value: "Presidio city" },
    { label: "Presidio CDP", value: "Presidio CDP" },
    { label: "Primera town", value: "Primera town" },
    { label: "Princeton city", value: "Princeton city" },
    { label: "Progreso city", value: "Progreso city" },
    { label: "Progreso Lakes city", value: "Progreso Lakes city" },
    { label: "Prosper town", value: "Prosper town" },
    { label: "Putnam city", value: "Putnam city" },
    { label: "Pyote town", value: "Pyote town" },
    { label: "Quail town", value: "Quail town" },
    { label: "Quanah city", value: "Quanah city" },
    { label: "Queen City city", value: "Queen City city" },
    { label: "Quemado CDP", value: "Quemado CDP" },
    { label: "Quintana town", value: "Quintana town" },
    { label: "Quintana CDP", value: "Quintana CDP" },
    { label: "Quitaque city", value: "Quitaque city" },
    { label: "Quitman city", value: "Quitman city" },
    { label: "Radar Base CDP", value: "Radar Base CDP" },
    { label: "Rafael Pena CDP", value: "Rafael Pena CDP" },
    { label: "Ralls city", value: "Ralls city" },
    { label: "Ranchette Estates CDP", value: "Ranchette Estates CDP" },
    { label: "Rancho Alegre CDP", value: "Rancho Alegre CDP" },
    { label: "Rancho Banquete CDP", value: "Rancho Banquete CDP" },
    { label: "Rancho Chico CDP", value: "Rancho Chico CDP" },
    { label: "Rancho Viejo town", value: "Rancho Viejo town" },
    { label: "Ranchos Penitas West CDP", value: "Ranchos Penitas West CDP" },
    { label: "Ranger city", value: "Ranger city" },
    { label: "Rangerville village", value: "Rangerville village" },
    { label: "Rankin city", value: "Rankin city" },
    { label: "Ransom Canyon town", value: "Ransom Canyon town" },
    { label: "Ratamosa CDP", value: "Ratamosa CDP" },
    { label: "Ravenna city", value: "Ravenna city" },
    { label: "Raymondville city", value: "Raymondville city" },
    { label: "Realitos CDP", value: "Realitos CDP" },
    { label: "Redbird CDP", value: "Redbird CDP" },
    { label: "Redford CDP", value: "Redford CDP" },
    { label: "Red Lick town", value: "Red Lick town" },
    { label: "Red Oak city", value: "Red Oak city" },
    { label: "Redwater city", value: "Redwater city" },
    { label: "Redwood CDP", value: "Redwood CDP" },
    { label: "Reese Center CDP", value: "Reese Center CDP" },
    { label: "Refugio city", value: "Refugio city" },
    { label: "Reid Hope King CDP", value: "Reid Hope King CDP" },
    { label: "Reklaw city", value: "Reklaw city" },
    { label: "Relampago CDP", value: "Relampago CDP" },
    { label: "Rendon CDP", value: "Rendon CDP" },
    { label: "Reno city", value: "Reno city" },
    { label: "Retreat town", value: "Retreat town" },
    { label: "Rhome city", value: "Rhome city" },
    { label: "Ricardo CDP", value: "Ricardo CDP" },
    { label: "Richardson city", value: "Richardson city" },
    { label: "Richland city", value: "Richland city" },
    { label: "Richland Hills city", value: "Richland Hills city" },
    { label: "Richmond city", value: "Richmond city" },
    { label: "Richwood city", value: "Richwood city" },
    { label: "Riesel city", value: "Riesel city" },
    { label: "Rio Bravo city", value: "Rio Bravo city" },
    { label: "Rio Grande City city", value: "Rio Grande City city" },
    { label: "Rio Hondo city", value: "Rio Hondo city" },
    { label: "Rio Vista city", value: "Rio Vista city" },
    { label: "Rising Star city", value: "Rising Star city" },
    { label: "River Oaks city", value: "River Oaks city" },
    { label: "Roanoke city", value: "Roanoke city" },
    { label: "Roaring Springs town", value: "Roaring Springs town" },
    { label: "Robert Lee city", value: "Robert Lee city" },
    { label: "Robinson city", value: "Robinson city" },
    { label: "Robstown city", value: "Robstown city" },
    { label: "Roby city", value: "Roby city" },
    { label: "Rochester city", value: "Rochester city" },
    { label: "Rockdale city", value: "Rockdale city" },
    { label: "Rockport city", value: "Rockport city" },
    { label: "Rocksprings city", value: "Rocksprings city" },
    { label: "Rockwall city", value: "Rockwall city" },
    { label: "Rocky Mound town", value: "Rocky Mound town" },
    { label: "Rogers city", value: "Rogers city" },
    { label: "Roma city", value: "Roma city" },
    { label: "Roma Creek CDP", value: "Roma Creek CDP" },
    { label: "Roman Forest city", value: "Roman Forest city" },
    { label: "Ropesville city", value: "Ropesville city" },
    { label: "Roscoe city", value: "Roscoe city" },
    { label: "Rosebud city", value: "Rosebud city" },
    { label: "Rose City town", value: "Rose City town" },
    { label: "Rose Hill Acres city", value: "Rose Hill Acres city" },
    { label: "Rosenberg city", value: "Rosenberg city" },
    { label: "Rosita North CDP", value: "Rosita North CDP" },
    { label: "Rosita South CDP", value: "Rosita South CDP" },
    { label: "Ross town", value: "Ross town" },
    { label: "Rosser village", value: "Rosser village" },
    { label: "Rotan city", value: "Rotan city" },
    { label: "Round Mountain town", value: "Round Mountain town" },
    { label: "Round Rock city", value: "Round Rock city" },
    { label: "Round Top town", value: "Round Top town" },
    { label: "Rowlett city", value: "Rowlett city" },
    { label: "Roxton city", value: "Roxton city" },
    { label: "Royse City city", value: "Royse City city" },
    { label: "Rule city", value: "Rule city" },
    { label: "Runaway Bay city", value: "Runaway Bay city" },
    { label: "Runge city", value: "Runge city" },
    { label: "Rusk city", value: "Rusk city" },
    { label: "Rutersville town", value: "Rutersville town" },
    { label: "Sabinal city", value: "Sabinal city" },
    { label: "Sachse city", value: "Sachse city" },
    { label: "Sadler town", value: "Sadler town" },
    { label: "Saginaw city", value: "Saginaw city" },
    { label: "St. Hedwig town", value: "St. Hedwig town" },
    { label: "St. Jo town", value: "St. Jo town" },
    { label: "St. Paul city", value: "St. Paul city" },
    { label: "St. Paul CDP", value: "St. Paul CDP" },
    { label: "St. Paul CDP", value: "St. Paul CDP" },
    { label: "St. Paul city", value: "St. Paul city" },
    { label: "Salado village", value: "Salado village" },
    { label: "Salineno CDP", value: "Salineno CDP" },
    { label: "Samnorwood town", value: "Samnorwood town" },
    { label: "San Angelo city", value: "San Angelo city" },
    { label: "San Antonio city", value: "San Antonio city" },
    { label: "San Augustine city", value: "San Augustine city" },
    { label: "San Benito city", value: "San Benito city" },
    { label: "San Carlos CDP", value: "San Carlos CDP" },
    { label: "San Carlos CDP", value: "San Carlos CDP" },
    { label: "San Carlos II CDP", value: "San Carlos II CDP" },
    { label: "San Carlos II CDP", value: "San Carlos II CDP" },
    { label: "San Diego city", value: "San Diego city" },
    { label: "San Elizario city", value: "San Elizario city" },
    { label: "San Felipe town", value: "San Felipe town" },
    { label: "Sanford town", value: "Sanford town" },
    { label: "San Ignacio CDP", value: "San Ignacio CDP" },
    { label: "San Isidro city", value: "San Isidro city" },
    { label: "San Juan city", value: "San Juan city" },
    { label: "San Juan CDP", value: "San Juan CDP" },
    { label: "San Leanna village", value: "San Leanna village" },
    { label: "San Leon CDP", value: "San Leon CDP" },
    { label: "San Manuel-Linn CDP", value: "San Manuel-Linn CDP" },
    { label: "San Marcos city", value: "San Marcos city" },
    { label: "San Patricio city", value: "San Patricio city" },
    { label: "San Perlita city", value: "San Perlita city" },
    { label: "San Pedro CDP", value: "San Pedro CDP" },
    { label: "San Pedro CDP", value: "San Pedro CDP" },
    { label: "San Pedro CDP", value: "San Pedro CDP" },
    { label: "San Pedro CDP", value: "San Pedro CDP" },
    { label: "San Perlita city", value: "San Perlita city" },
    { label: "San Saba city", value: "San Saba city" },
    { label: "Sansom Park city", value: "Sansom Park city" },
    { label: "Santa Anna town", value: "Santa Anna town" },
    { label: "Santa Clara CDP", value: "Santa Clara CDP" },
    { label: "Santa Cruz CDP", value: "Santa Cruz CDP" },
    { label: "Santa Cruz CDP", value: "Santa Cruz CDP" },
    { label: "Santa Fe city", value: "Santa Fe city" },
    { label: "Santa Maria city", value: "Santa Maria city" },
    { label: "Santa Monica CDP", value: "Santa Monica CDP" },
    { label: "Santa Monica CDP", value: "Santa Monica CDP" },
    { label: "Santa Rosa city", value: "Santa Rosa city" },
    { label: "Santa Rosa CDP", value: "Santa Rosa CDP" },
    { label: "Savoy town", value: "Savoy town" },
    { label: "Scenic Oaks CDP", value: "Scenic Oaks CDP" },
    { label: "Schertz city", value: "Schertz city" },
    { label: "Schulenburg city", value: "Schulenburg city" },
    { label: "Scissors CDP", value: "Scissors CDP" },
    { label: "Scotland city", value: "Scotland city" },
    { label: "Scottsville city", value: "Scottsville city" },
    { label: "Seabrook city", value: "Seabrook city" },
    { label: "Seadrift city", value: "Seadrift city" },
    { label: "Seagoville city", value: "Seagoville city" },
    { label: "Seagraves city", value: "Seagraves city" },
    { label: "Sebastian city", value: "Sebastian city" },
    { label: "Seguin city", value: "Seguin city" },
    { label: "Selma city", value: "Selma city" },
    { label: "Seminole city", value: "Seminole city" },
    { label: "Serenada CDP", value: "Serenada CDP" },
    { label: "Seth Ward CDP", value: "Seth Ward CDP" },
    { label: "Seven Oaks CDP", value: "Seven Oaks CDP" },
    { label: "Seven Points city", value: "Seven Points city" },
    { label: "Seymour city", value: "Seymour city" },
    { label: "Shady Hollow CDP", value: "Shady Hollow CDP" },
    { label: "Shady Shores town", value: "Shady Shores town" },
    { label: "Shallowater city", value: "Shallowater city" },
    { label: "Shamrock city", value: "Shamrock city" },
    { label: "Shavano Park city", value: "Shavano Park city" },
    { label: "Sheldon CDP", value: "Sheldon CDP" },
    { label: "Shenandoah city", value: "Shenandoah city" },
    { label: "Shepherd city", value: "Shepherd city" },
    { label: "Sherman city", value: "Sherman city" },
    { label: "Shiner city", value: "Shiner city" },
    { label: "Sienna Plantation CDP", value: "Sienna Plantation CDP" },
    { label: "Sierra Blanca town", value: "Sierra Blanca town" },
    { label: "Siesta Acres CDP", value: "Siesta Acres CDP" },
    { label: "Sierra Blanca CDP", value: "Sierra Blanca CDP" },
    { label: "Siesta Shores CDP", value: "Siesta Shores CDP" },
    { label: "Silsbee city", value: "Silsbee city" },
    { label: "Silverton city", value: "Silverton city" },
    { label: "Simonton city", value: "Simonton city" },
    { label: "Sinton city", value: "Sinton city" },
    { label: "Skellytown town", value: "Skellytown town" },
    { label: "Skidmore city", value: "Skidmore city" },
    { label: "Slaton city", value: "Slaton city" },
    { label: "Smiley town", value: "Smiley town" },
    { label: "Smithville city", value: "Smithville city" },
    { label: "Smyer town", value: "Smyer town" },
    { label: "Snook city", value: "Snook city" },
    { label: "Socorro city", value: "Socorro city" },
    { label: "Socorro city", value: "Socorro city" },
    { label: "Solis CDP", value: "Solis CDP" },
    { label: "Somerset city", value: "Somerset city" },
    { label: "Somerville city", value: "Somerville city" },
    { label: "Sonora city", value: "Sonora city" },
    { label: "Sour Lake city", value: "Sour Lake city" },
    { label: "South Alamo CDP", value: "South Alamo CDP" },
    { label: "South Fork Estates CDP", value: "South Fork Estates CDP" },
    { label: "South Houston city", value: "South Houston city" },
    { label: "Southlake city", value: "Southlake city" },
    { label: "Southmayd city", value: "Southmayd city" },
    { label: "South Mountain CDP", value: "South Mountain CDP" },
    { label: "South Padre Island town", value: "South Padre Island town" },
    { label: "South Point town", value: "South Point town" },
    { label: "Southside Place city", value: "Southside Place city" },
    { label: "Spade town", value: "Spade town" },
    { label: "Sparks town", value: "Sparks town" },
    { label: "Spearman city", value: "Spearman city" },
    { label: "Splendora city", value: "Splendora city" },
    { label: "Spofford CDP", value: "Spofford CDP" },
    { label: "Spring city", value: "Spring city" },
    { label: "Spring Gardens CDP", value: "Spring Gardens CDP" },
    { label: "Springlake town", value: "Springlake town" },
    { label: "Springtown town", value: "Springtown town" },
    { label: "Spur city", value: "Spur city" },
    { label: "Stamford city", value: "Stamford city" },
    { label: "Stanton city", value: "Stanton city" },
    { label: "Staples city", value: "Staples city" },
    { label: "Star Harbor city", value: "Star Harbor city" },
    { label: "Stephenville city", value: "Stephenville city" },
    { label: "Sterling City city", value: "Sterling City city" },
    { label: "Stinnett city", value: "Stinnett city" },
    { label: "Stockdale city", value: "Stockdale city" },
    { label: "Stonewall town", value: "Stonewall town" },
    { label: "Stonewall CDP", value: "Stonewall CDP" },
    { label: "Stowell CDP", value: "Stowell CDP" },
    { label: "Stratford city", value: "Stratford city" },
    { label: "Strawn city", value: "Strawn city" },
    { label: "Streetman town", value: "Streetman town" },
    { label: "Study Butte CDP", value: "Study Butte CDP" },
    { label: "Sudan city", value: "Sudan city" },
    { label: "Sugar Land city", value: "Sugar Land city" },
    { label: "Sullivan City city", value: "Sullivan City city" },
    { label: "Sulphur Springs city", value: "Sulphur Springs city" },
    { label: "Sundown city", value: "Sundown city" },
    { label: "Sunnyvale town", value: "Sunnyvale town" },
    { label: "Sunray city", value: "Sunray city" },
    { label: "Sunrise Beach Village city", value: "Sunrise Beach Village city" },
    { label: "Sunset city", value: "Sunset city" },
    { label: "Sunset Valley city", value: "Sunset Valley city" },
    { label: "Sun Valley city", value: "Sun Valley city" },
    { label: "Surfside Beach city", value: "Surfside Beach city" },
    { label: "Sweeny city", value: "Sweeny city" },
    { label: "Sweetwater city", value: "Sweetwater city" },
    { label: "Taft city", value: "Taft city" },
    { label: "Taft Southwest CDP", value: "Taft Southwest CDP" },
    { label: "Tahoka city", value: "Tahoka city" },
    { label: "Talco city", value: "Talco city" },
    { label: "Talty city", value: "Talty city" },
    { label: "Tatum city", value: "Tatum city" },
    { label: "Taylor city", value: "Taylor city" },
    { label: "Taylor Lake Village city", value: "Taylor Lake Village city" },
    { label: "Taylor Landing CDP", value: "Taylor Landing CDP" },
    { label: "Teague city", value: "Teague city" },
    { label: "Tehuacana town", value: "Tehuacana town" },
    { label: "Temple city", value: "Temple city" },
    { label: "Tenaha town", value: "Tenaha town" },
    { label: "Terrell city", value: "Terrell city" },
    { label: "Terrell Hills city", value: "Terrell Hills city" },
    { label: "Texarkana city", value: "Texarkana city" },
    { label: "Texas City city", value: "Texas City city" },
    { label: "Texhoma city", value: "Texhoma city" },
    { label: "Texline town", value: "Texline town" },
    { label: "The Colony city", value: "The Colony city" },
    { label: "The Hills village", value: "The Hills village" },
    { label: "The Woodlands CDP", value: "The Woodlands CDP" },
    { label: "Thompsons town", value: "Thompsons town" },
    { label: "Thorndale city", value: "Thorndale city" },
    { label: "Thornton city", value: "Thornton city" },
    { label: "Thorntonville CDP", value: "Thorntonville CDP" },
    { label: "Thrall city", value: "Thrall city" },
    { label: "Three Rivers city", value: "Three Rivers city" },
    { label: "Throckmorton town", value: "Throckmorton town" },
    { label: "Tierra Bonita CDP", value: "Tierra Bonita CDP" },
    { label: "Tierra Grande CDP", value: "Tierra Grande CDP" },
    { label: "Tiki Island village", value: "Tiki Island village" },
    { label: "Timbercreek Canyon village", value: "Timbercreek Canyon village" },
    { label: "Timberwood Park CDP", value: "Timberwood Park CDP" },
    { label: "Timpson city", value: "Timpson city" },
    { label: "Tioga town", value: "Tioga town" },
    { label: "Tira town", value: "Tira town" },
    { label: "Toco town", value: "Toco town" },
    { label: "Todd Mission city", value: "Todd Mission city" },
    { label: "Tolar city", value: "Tolar city" },
    { label: "Tom Bean city", value: "Tom Bean city" },
    { label: "Tomball city", value: "Tomball city" },
    { label: "Tool city", value: "Tool city" },
    { label: "Tornillo CDP", value: "Tornillo CDP" },
    { label: "Toyah city", value: "Toyah city" },
    { label: "Toyahvale CDP", value: "Toyahvale CDP" },
    { label: "Tradewinds CDP", value: "Tradewinds CDP" },
    { label: "Trent town", value: "Trent town" },
    { label: "Trenton city", value: "Trenton city" },
    { label: "Trinidad city", value: "Trinidad city" },
    { label: "Trinity city", value: "Trinity city" },
    { label: "Trophy Club town", value: "Trophy Club town" },
    { label: "Troup city", value: "Troup city" },
    { label: "Troy city", value: "Troy city" },
    { label: "Tuleta town", value: "Tuleta town" },
    { label: "Tulia city", value: "Tulia city" },
    { label: "Tulsita CDP", value: "Tulsita CDP" },
    { label: "Turkey city", value: "Turkey city" },
    { label: "Tuscola town", value: "Tuscola town" },
    { label: "Tye city", value: "Tye city" },
    { label: "Tyler city", value: "Tyler city" },
    { label: "Tynan CDP", value: "Tynan CDP" },
    { label: "Uhland city", value: "Uhland city" },
    { label: "Uncertain city", value: "Uncertain city" },
    { label: "Union Grove city", value: "Union Grove city" },
    { label: "Union Valley city", value: "Union Valley city" },
    { label: "Universal City city", value: "Universal City city" },
    { label: "University Park city", value: "University Park city" },
    { label: "Utopia city", value: "Utopia city" },
    { label: "Uvalde city", value: "Uvalde city" },
    { label: "Valentine town", value: "Valentine town" },
    { label: "Valley Mills city", value: "Valley Mills city" },
    { label: "Valley View town", value: "Valley View town" },
    { label: "Val Verde Park CDP", value: "Val Verde Park CDP" },
    { label: "Van city", value: "Van city" },
    { label: "Van Alstyne city", value: "Van Alstyne city" },
    { label: "Vanderbilt city", value: "Vanderbilt city" },
    { label: "Van Horn town", value: "Van Horn town" },
    { label: "Van Vleck village", value: "Van Vleck village" },
    { label: "Vega city", value: "Vega city" },
    { label: "Venus town", value: "Venus town" },
    { label: "Vernon city", value: "Vernon city" },
    { label: "Victoria city", value: "Victoria city" },
    { label: "Vidor city", value: "Vidor city" },
    { label: "Villa del Sol CDP", value: "Villa del Sol CDP" },
    { label: "Villa Pancho CDP", value: "Villa Pancho CDP" },
    { label: "Villa Verde CDP", value: "Villa Verde CDP" },
    { label: "Vinton city", value: "Vinton city" },
    { label: "Waco city", value: "Waco city" },
    { label: "Waelder city", value: "Waelder city" },
    { label: "Wake Village city", value: "Wake Village city" },
    { label: "Waller city", value: "Waller city" },
    { label: "Wallis city", value: "Wallis city" },
    { label: "Walnut Springs city", value: "Walnut Springs city" },
    { label: "Warren city", value: "Warren city" },
    { label: "Warren City CDP", value: "Warren City CDP" },
    { label: "Waskom city", value: "Waskom city" },
    { label: "Watauga city", value: "Watauga city" },
    { label: "Waxahachie city", value: "Waxahachie city" },
    { label: "Weatherford city", value: "Weatherford city" },
    { label: "Webb city", value: "Webb city" },
    { label: "Webster city", value: "Webster city" },
    { label: "Webster CDP", value: "Webster CDP" },
    { label: "Weimar city", value: "Weimar city" },
    { label: "Weinert city", value: "Weinert city" },
    { label: "Weir city", value: "Weir city" },
    { label: "Welch town", value: "Welch town" },
    { label: "Wellington city", value: "Wellington city" },
    { label: "Wellman city", value: "Wellman city" },
    { label: "Wells town", value: "Wells town" },
    { label: "Wells Branch CDP", value: "Wells Branch CDP" },
    { label: "Weslaco city", value: "Weslaco city" },
    { label: "West city", value: "West city" },
    { label: "Westbrook city", value: "Westbrook city" },
    { label: "West Columbia city", value: "West Columbia city" },
    { label: "Westdale CDP", value: "Westdale CDP" },
    { label: "Westlake city", value: "Westlake city" },
    { label: "West Lake Hills city", value: "West Lake Hills city" },
    { label: "West Livingston CDP", value: "West Livingston CDP" },
    { label: "Westminster city", value: "Westminster city" },
    { label: "West Odessa CDP", value: "West Odessa CDP" },
    { label: "Weston city", value: "Weston city" },
    { label: "West Orange city", value: "West Orange city" },
    { label: "Westover Hills town", value: "Westover Hills town" },
    { label: "Weston Lakes city", value: "Weston Lakes city" },
    { label: "West Pearsall CDP", value: "West Pearsall CDP" },
    { label: "West Sharyland CDP", value: "West Sharyland CDP" },
    { label: "West Tawakoni city", value: "West Tawakoni city" },
    { label: "West University Place city", value: "West University Place city" },
    { label: "Westway CDP", value: "Westway CDP" },
    { label: "Westworth Village city", value: "Westworth Village city" },
    { label: "Wharton city", value: "Wharton city" },
    { label: "Wheeler city", value: "Wheeler city" },
    { label: "White Deer city", value: "White Deer city" },
    { label: "Whiteface town", value: "Whiteface town" },
    { label: "Whitehouse city", value: "Whitehouse city" },
    { label: "White Oak city", value: "White Oak city" },
    { label: "Whitesboro city", value: "Whitesboro city" },
    { label: "White Settlement city", value: "White Settlement city" },
    { label: "Whitewright city", value: "Whitewright city" },
    { label: "Whitney city", value: "Whitney city" },
    { label: "Wichita Falls city", value: "Wichita Falls city" },
    { label: "Wickett CDP", value: "Wickett CDP" },
    { label: "Wild Peach Village CDP", value: "Wild Peach Village CDP" },
    { label: "Willamar CDP", value: "Willamar CDP" },
    { label: "Willis city", value: "Willis city" },
    { label: "Willow Park city", value: "Willow Park city" },
    { label: "Wills Point city", value: "Wills Point city" },
    { label: "Wilmer city", value: "Wilmer city" },
    { label: "Wilson city", value: "Wilson city" },
    { label: "Wimberley city", value: "Wimberley city" },
    { label: "Windcrest city", value: "Windcrest city" },
    { label: "Windemere town", value: "Windemere town" },
    { label: "Windom town", value: "Windom town" },
    { label: "Windthorst town", value: "Windthorst town" },
    { label: "Winfield town", value: "Winfield town" },
    { label: "Wink city", value: "Wink city" },
    { label: "Winnie CDP", value: "Winnie CDP" },
    { label: "Winnsboro city", value: "Winnsboro city" },
    { label: "Winona city", value: "Winona city" },
    { label: "Winters city", value: "Winters city" },
    { label: "Wixon Valley city", value: "Wixon Valley city" },
    { label: "Wolfe City city", value: "Wolfe City city" },
    { label: "Wolfforth city", value: "Wolfforth city" },
    { label: "Woodbranch city", value: "Woodbranch city" },
    { label: "Woodcreek city", value: "Woodcreek city" },
    { label: "Woodlake CDP", value: "Woodlake CDP" },
    { label: "Woodloch city", value: "Woodloch city" },
    { label: "Woodsboro city", value: "Woodsboro city" },
    { label: "Woodson town", value: "Woodson town" },
    { label: "Woodville city", value: "Woodville city" },
    { label: "Woodway city", value: "Woodway city" },
    { label: "Wortham city", value: "Wortham city" },
    { label: "Wyldwood CDP", value: "Wyldwood CDP" },
    { label: "Wylie city", value: "Wylie city" },
    { label: "Yantis town", value: "Yantis town" },
    { label: "Yoakum city", value: "Yoakum city" },
    { label: "Yorktown city", value: "Yorktown city" },
    { label: "Yznaga CDP", value: "Yznaga CDP" },
    { label: "Zapata city", value: "Zapata city" },
    { label: "Zapata Ranch CDP", value: "Zapata Ranch CDP" },
    { label: "Zarate CDP", value: "Zarate CDP" },
    { label: "Zavalla city", value: "Zavalla city" },
    { label: "Zuehl CDP", value: "Zuehl CDP" }
];
