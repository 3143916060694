
import {SELECTED_STORE} from "../constants";

export const selectStore = (data) => {
    return (dispatch) => {
        return dispatch({
            type: SELECTED_STORE,
            data:data
        });
    }
};