import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { capitalizeWords } from "../../helper";
import { withRouter } from "react-router-dom";
import { Cookies, withCookies } from "react-cookie";
import { instanceOf } from "prop-types";
import * as moment from "moment";

class ShopHoursClass extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isPopoverOpen: false,
            anchorEl: null,
            isShopOpen: false,
            formattedClosingTime: '',
            formattedOpeningTime: '',
            nextDayFullName: '',
            nextDayShortName : '',
            timings: [
                { day: 'monday', from: '07:00:00', to: '19:00:00' },
                { day: 'tuesday', from: '07:00:00', to: '19:00:00' },
                { day: 'wednesday', from: '07:00:00', to: '19:00:00' },
                { day: 'thursday', from: '07:00:00', to: '19:00:00' },
                { day: 'friday', from: '07:00:00', to: '19:00:00' },
                { day: 'saturday', from: '07:00:00', to: '19:00:00' },
                { day: 'sunday', from: '08:00:00', to: '16:00:00' },
            ],
            restaurant_id: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.updateShopStatus();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        const { shopCloseId = '', shopOpenId = '' } = this.props;
        if (prevProps.showDropdown !== this.props.showDropdown && this.props.showDropdown) {
            const isShopOpen = document.getElementById(shopOpenId);
            if (isShopOpen) {
                isShopOpen.click();
            }
            const isShopClosed = document.getElementById(shopCloseId);
            if (isShopClosed) {
                isShopClosed.click();
            }
        }

        if (prevProps.timings !== this.props.timings || prevState.timings !== this.state.timings) {
            this.updateShopStatus();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.showDropdown !== this.props.showDropdown ||
            nextProps.timings !== this.props.timings ||
            nextState.timings !== this.state.timings ||
            nextState.isShopOpen !== this.state.isShopOpen ||
            nextState.formattedClosingTime !== this.state.formattedClosingTime ||
            nextState.formattedOpeningTime !== this.state.formattedOpeningTime ||
            nextState.nextDayFullName !== this.state.nextDayFullName ||
            nextState.nextDayShortName !== this.state.nextDayShortName ||
            nextState.anchorEl !== this.state.anchorEl) {
            return true;
        }
        return false;
    }

    handleScroll = () => {
        if (this.state.anchorEl) {
            this.setState({ anchorEl: null });
        }
    };

    parseTime = (date, time) => {
        return moment(date).set({
            hour: parseInt(time.split(':')[0], 10),
            minute: parseInt(time.split(':')[1], 10),
            second: parseInt(time.split(':')[2], 10)
        }).toDate();
    };

    updateShopStatus = () => {

        const { timings } = this.state;
        const currentDay = moment().format('dddd').toLowerCase();
        const currentTime = moment().format('HH:mm:ss');

        const currentDayData = timings.find(entry => entry.day.toLowerCase() === currentDay);
        if (!currentDayData) {
            this.setState({
                isShopOpen: false,
                formattedClosingTime: '',
                formattedOpeningTime: '',
                nextDayFullName: '',
                nextDayShortName : ''
            });
            return;
        }

        const nextDay = moment().add(currentDayData.day === currentDay && currentTime <= currentDayData.from ? 0 : 1, 'days').toDate();
        const nextDayFullName = moment(nextDay).format('dddd').toLowerCase();
        const nextDayData = timings.find(entry => entry.day.toLowerCase() === nextDayFullName);
        const nextDayShortName = moment(nextDay).format('ddd');

        if (!nextDayData) {
            this.setState({
                isShopOpen: false,
                formattedClosingTime: '',
                formattedOpeningTime: '',
                nextDayFullName: '',
                nextDayShortName : ''
            });
            return;
        }

        const closingTime = this.parseTime(new Date(), currentDayData.to);
        const openingTime = this.parseTime(new Date(), nextDayData.from);

        if (!closingTime || !openingTime) {
            this.setState({
                isShopOpen: false,
                formattedClosingTime: '',
                formattedOpeningTime: '',
                nextDayFullName: '',
                nextDayShortName : ''
            });
            return;
        }

        const formattedClosingTime = moment(closingTime).format('h:mm A');
        const formattedOpeningTime = moment(openingTime).format('h:mm A');

        const isShopOpen = moment(currentTime, 'HH:mm:ss').isSameOrAfter(moment(currentDayData.from, 'HH:mm:ss')) &&
            moment(currentTime, 'HH:mm:ss').isBefore(moment(currentDayData.to, 'HH:mm:ss'));

        this.setState({
            isShopOpen,
            formattedClosingTime,
            formattedOpeningTime,
            nextDayFullName,
            nextDayShortName
        });
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { isShopOpen, formattedClosingTime, formattedOpeningTime, nextDayShortName, timings } = this.state;

        if (!(timings && timings.length)) {
            return (
                <section>
                    <div>
                        <span className="font-10">Loading...</span>
                    </div>
                </section>
            );
        }

        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;
        const { isFooter = false, shopCloseId = '', shopOpenId = '' } = this.props;

        return (
            <>
                <div>
                    {isShopOpen ? (
                        <p id={shopOpenId} className="font-10 d-flex w-max align-items-center cursor"
                           onClick={this.handleClick} aria-describedby={id}>
                            <span className="font-weight-medium text-success">Open now</span>
                            <span className="mx-1 px-1 font-weight-medium">|</span>
                            <span className={`text-timing ${isFooter ? 'footer-bg' : ''}`}>Closes {formattedClosingTime.toLowerCase()}</span>
                            {this.state.anchorEl ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#5ab7d4" strokeLinecap="round" strokeLinejoin="round"
                                          strokeMiterlimit="10" strokeWidth="1.5"
                                          d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 11.79c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3-3-3 3c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l3.53-3.53c.29-.29.77-.29 1.06 0l3.53 3.53c.29.3.29.77 0 1.06z"></path>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#5ab7d4" strokeLinecap="round" strokeLinejoin="round"
                                          strokeMiterlimit="10" strokeWidth="1.5"
                                          d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 9.27l-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3.53-3.53a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3 3-3c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06z"></path>
                                </svg>
                            }
                        </p>
                    ) : (
                        <p id={shopCloseId} className="font-10 d-flex w-max align-items-center cursor"
                           onClick={this.handleClick} aria-describedby={id}>
                            <span className="font-weight-medium text-danger">Closed</span>
                            <span className="mx-1 px-1 font-weight-medium">|</span>
                            <span className={`text-timing ${isFooter ? 'footer-bg' : ''}`}>Open {formattedOpeningTime.toLowerCase()} {nextDayShortName}</span>
                            {this.state.anchorEl ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#5ab7d4" strokeLinecap="round" strokeLinejoin="round"
                                          strokeMiterlimit="10" strokeWidth="1.5"
                                          d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 11.79c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3-3-3 3c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l3.53-3.53c.29-.29.77-.29 1.06 0l3.53 3.53c.29.3.29.77 0 1.06z"></path>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#5ab7d4" strokeLinecap="round" strokeLinejoin="round"
                                          strokeMiterlimit="10" strokeWidth="1.5"
                                          d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm4.06 9.27l-3.53 3.53c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-3.53-3.53a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3 3-3c.29-.29.77-.29 1.06 0 .29.29.29.76 0 1.06z"></path>
                                </svg>
                            }
                        </p>
                    )}
                </div>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    className="mt-4"
                >
                    <ul className="text-right w-100 timing-main">
                        {timings.length > 0 && timings.map((value, index) => {
                            const formattedStartTime = this.parseTime(new Date(), value.from).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });

                            const formattedEndTime = this.parseTime(new Date(), value.to).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });

                            const isToday = (moment().format('dddd')).toLowerCase() === value.day;
                            return (
                                <li key={index} className="m-0 text-left date-item d-flex align-items-center justify-content-between">
                                    <span className={`font-weight-medium ${isToday ? 'text-blue' : ''}`}>
                                        {capitalizeWords(value.day)}
                                    </span>
                                    <span className={`${isToday ? 'font-weight-medium' : ''}`}>{formattedStartTime} - {formattedEndTime}</span>
                                </li>
                            );
                        })}
                    </ul>
                </Popover>
            </>
        );
    }
}

export default withRouter(withCookies(ShopHoursClass));
