import {SELECTED_STORE} from "/src/constants";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

let newState = {
    selectedStore : null
};

export default function StoreReducer(state,action)
{
    switch (action.type){

        case SELECTED_STORE:
            if (action.data !== null) {
                newState = Object.assign({}, newState, {
                    selectedStore: action.data.data
                });
            }

            return newState;

            default:
            return state || newState;
    }
}

