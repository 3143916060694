import moment from "moment";
import {format, compareAsc, addMinutes} from 'date-fns'

export function strReplace(str) {
    return str ? str.toString().replace(/-/g, ' ') : "";
}

export function validatePassword(password) {
    let letter = /[a-zA-Z]/;
    let number = /[0-9]/;
    let valid = number.test(password) && letter.test(password); //match a letter _and_ a number
    return (valid && password.length >= 8) ? null : 'Password must be alphanumeric and must be at least 8 characters';
}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)
};

export const formatDistance = (number) => {
    return new Intl.NumberFormat('en-US').format(number)
};


export const CapitalizeFirstLetter = (str) => {
    return str?.length ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str
};

export const capitalizeWords = (str) => {
    if (!str) return str; // Handle empty string or null input
    const words = str.split(" ");
    const capitalizedWords = words.map((word) => {
        const lowercaseWord = word.toLowerCase();
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
    });
    return capitalizedWords.join(" ");
};


export const tConv24 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

export const convertTime12to24 = (date,amPmString) => {
    const format = (time12h) => moment(time12h, 'hh:mm A').format('HH:mm');
    return Math.floor(new Date(date +" "+ format(amPmString)).valueOf()/1000) ;
};

export const formatCardNumber = value => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, '')

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter(group => !!group).join(' ')
    )
};

export const formatDate = value => {
    return moment(value).format('M-D-YYYY');
};

export const formatUsDate = string => {
    return moment(string).format('dddd, LL');
};

export const formatCouponDate = string => {
    return moment(string).format('LL');
};

export const convertToSlug = s =>  {
    return s.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
};

export const inverse_slugify = s => {
    const last = s.charAt(s.length - 1);
    if (last === "-"){
       s = s.slice(0, -1)
    }
    return s.toLowerCase()
        .split('-')
        .map(i => i[0] + i.substr(1))
        .join(' ');
};

export const currencyFormat = string => {
    return '$' + string.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};

export const datepickerDate = value => {
    return moment(value).format('MM/DD/YYYY');
};

export const handleNumberType = (event) => {
    if(event.key === "Enter") {
        return true;
    } else {
        if (!/[0-9.]/g.test(event.key) && event.key !== "Backspace") {
            event.preventDefault();
        }
    }
};

export const focusElementById = (id) => {
    const element = document.getElementById(id);
    if(!!element) {
        element.focus();
    }
};

export const capitalizeWordsFirstLetter = (words, originalString) => {
    let modifiedString = originalString;
    let jsxElements = [];
    let lastIndex = 0;

    words.forEach(word => {
        let index = modifiedString.toLowerCase().indexOf(word.toLowerCase());
        while (index !== -1) {
            jsxElements.push(modifiedString.substring(lastIndex, index));

            jsxElements.push(
                <span className="capitalize-word">
                    {modifiedString.substring(index, index + word.length)}
                </span>
            );

            lastIndex = index + word.length;
            index = modifiedString.toLowerCase().indexOf(word.toLowerCase(), lastIndex);
        }
    });

    jsxElements.push(modifiedString.substring(lastIndex));

    return jsxElements;
};


export const sortArray = array => {
    return array.sort(function(a, b){ return a.distance - b.distance });
};

export const formatUpdateTime = string => {
    return moment(string).format("DD-MM-YYYY hh:mm a").toUpperCase();
};

export const convertTimStampToDateTime = string =>{
   return  moment.unix(string).format("MM-DD-YYYY hh:mm a").toUpperCase()
};

export const formatOrderTime = string => {
    return moment(string).format("h:mm a").toUpperCase();
};

export const formatSimpleDate = value => {
    return moment(value).format('dddd, MMMM Do YYYY');
};

export const getDate = value => {
    if (value !== null && value !== undefined) {
        var date = new Date(value);
        date.setDate(date.getDate() + 1);
        return date.toISOString().substring(0, 10)
    }
};


export const EventEmitter = {
    _events: {},
    dispatch: function (event, data) {
        if (!this._events[event]) return; // no one is listening to this event
        for (var i = 0; i < this._events[event].length; i++)
            this._events[event][i](data);
    },
    subscribe: function (event, callback) {
        if (!this._events[event]) this._events[event] = []; // new event
        this._events[event].push(callback);
    }
    // note that we do not handle unsubscribe here
};

export const breakStringAfterLastComma = inputString => {
    const lastCommaIndex = inputString.indexOf(',');
    if (lastCommaIndex !== -1) {
        const beforeLastComma = inputString.substring(0, lastCommaIndex);
        const afterLastComma = inputString.substring(lastCommaIndex + 1).trim();
        const result = beforeLastComma + '<br>' + afterLastComma;
        return result;
    }
    return inputString;
};

export const slots = (value,scheduleDate) => {
    let CurrentDate = moment().format("YYYY-MM-DD");
    let CurrentTime = moment().format("HH:mm");

    //Format the time
    let startTime = moment(value.start_time, "HH:mm").add(30,"minute");

    //Format the end time and the next day to it
    let endTime = moment(value.end_time, "HH:mm");
    let finalEndTime = moment(value.end_time, ["HH mm"]).format("HH:mm");

    //Times
    let allTimes = [];

    //Loop over the times - only pushes time with 120 minutes interval

    while (startTime < endTime) {

        var startSlotTime = moment(startTime, ["HH mm"]).format("h:mm a");
        var startCompareTime = moment(startTime).format("HH:mm");
        var endCompareSlotTime = moment(startTime.add(90, 'minutes'), ["HH mm"]).format("HH:mm");
        var endSlotTime = moment(endCompareSlotTime, ["HH mm"]).format("h:mm a");

        if (endCompareSlotTime <= finalEndTime) {

            if(CurrentDate === scheduleDate && startCompareTime < CurrentTime ){
                //Push times
                var newArray = null;
            }else{
                //Push times
                var newArray = {
                    "start_time": startSlotTime,
                    "end_time": endSlotTime
                }
            }
        }else{
            var newArray = null;
        }
        allTimes.push(newArray);
        //Add interval of 120 minutes;

    }
    return allTimes.length > 0 ? allTimes.filter(function (el) {
        return el != null;
    }) : [];
};

export const redirectionMiddleware=(req,res,next)=>{
    var toRedirect =['/product-category','/product-tag','/tag','May','/feed','/view','/wp-content','/deals-news','/category/press','/watch','/search','/shop-online','/2015','/2016','/2014','/products','/farmers-fresh-meat']
    if (toRedirect.some(path => req.path.startsWith(path))) {
        return res.redirect(301, '/');
    }
    if (req.path == '/career') {
        return res.redirect(301, `/about/employment`);
    }
    next();
}

// export const slots = (value,scheduleDate) => {
//    console.log(value,scheduleDate)
//     let CurrentDate = format(new Date(), 'yyyy-MM-dd')
//     let ScheduleDate = format(new Date(scheduleDate), 'yyyy-MM-dd');
//     let CurrentTime = format(new Date(), 'hh:mm a');
//
//
//     //Format the time
//     let startTime =  value.start_time ? format(new Date(CurrentDate +" "+ value.start_time),'HH:mm') : "";
//     let endTime = value.end_time ?  format(new Date(CurrentDate +" "+ value.end_time),'HH:mm') : "";
//     let finalEndTime = value.end_time ? format(new Date(CurrentDate +" "+ value.end_time),'HH:mm') : "";
//
//
//
// //Times
//     let allTimes = [];
//
//
//
// //Loop over the times - only pushes time with 120 minutes interval
//
//     while (startTime < endTime) {
//
//         var startSlotTime = startTime ? format(new Date(CurrentDate +" "+ startTime),'hh:mm a') : "";
//         var newTime = startSlotTime ? addMinutes(new Date(CurrentDate +" " +startTime), 90) : "";
//         var endSlotTime = newTime ? format(new Date(newTime),'hh:mm a') : "";
//         startTime = newTime ? format(new Date(newTime),'HH:mm') : "";
//
//
//         if (startTime <= finalEndTime) {
//
//             if(CurrentDate === ScheduleDate && startSlotTime < CurrentTime ){
//                 //Push times
//                 var newArray = null;
//             }else{
//
//                 //Push times
//                 var newArray = {
//                     "start_time": startSlotTime,
//                     "end_time": endSlotTime
//                 }
//             }
//         }else{
//             var newArray = null;
//         }
//         allTimes.push(newArray);
//         //Add interval of 120 minutes;
//
//     }
//
//     return allTimes.length > 0 ? allTimes.filter(function (el) {
//         return el != null;
//     }) : [];
// }


export function BASE_URL() {
    return "https://farmersfreshmeat.com";
}
