import {GET_CART, GET_RESTAURANTS, MENU_TYPE, UPDATE_CART ,SINGLE_PRODUCT} from "/src/constants";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

let newState = {singleProduct: {},menuTypes: [], cart: [], cart_details: {}, restaurants: []};

export default function ProductReducer(state,action)
{
    switch (action.type){
        case SINGLE_PRODUCT:
            newState = cloneObject(state);
            if (action.data !== null) {
                newState = Object.assign({}, newState, {
                    singleProduct: action.data
                });
            }
            return newState;

        default:
            return state || newState
    }
}

