import React, {Component} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import {updateCart} from "../../actions/cart";
import {withCookies} from "react-cookie";
import API from "../../api";
import {EventEmitter} from "../../helper";

const api = new API();
let timerId;


class CartDropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedVariable: {},
            localSelectedVariable: {},
            selectedCustomise: '',
            localSelectedCustomise: '',
            showDropDown: false,
            cartData: null,
            cartItems: [],
            quantity_product: null,
            quantity_variable: null,
            quantity_customization : null,
            quantity_amount: null,
            quantity: 1,
            counter: 0,
            default_store : this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            updating: false,
            remove_slide: "",
            remove_confirm: "slide-items",
        };
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.getButtonWidth = this.getButtonWidth.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.debounce = this.debounce.bind(this);
    }

    componentDidMount() {
        const {
            cartValue = null,
            variables = [],
            preferences = [],
            wrapperClass = '',
            isMyListPage = false,
            itemId = '',
            isCartPage = false,
            productName = ''
        } = this.props;
        const {localSelectedVariable = {}, showDropDown = false} = this.state;
        const isCartItemsPage = !!wrapperClass.includes('cart-item');
        const currentCartItems = this.props.cartAddedItems?.cart_items?.filter(item => item.item_id === this.props.item);

        this.setState({ cartItems: currentCartItems ? currentCartItems : [] });
        this.setState({ counter: currentCartItems ? currentCartItems.length : 0 });

        const isMobileView = typeof window !== "undefined" ? window?.innerWidth <= 768 : false;
        const ele = document.getElementById('dropdown-menu'+itemId);
        if(!!ele && isMobileView) {
            ele.classList.remove('close');
            ele.classList.add('animate');
        }

        window.addEventListener('scroll', (e) => {
            if (!!this.componentRef) {
                const {top = 0, bottom = 0} = this.componentRef?.getBoundingClientRect();
                if (top) {
                    const isVisible = top < window?.innerHeight && bottom >= 0;
                    if (!isVisible) {
                        this.setState({showDropDown: false});
                    }
                }
            }
        });

        EventEmitter.subscribe('closeDropDown', () => {
            this.setState({showDropDown: false});
        });

        if (!!cartValue && (isCartItemsPage || isCartPage)) {
            if (cartValue?.variable_id) {
                this.setState({selectedVariable: cartValue.variable, localSelectedVariable: cartValue.variable});
            }
            this.setState({
                selectedCustomise: cartValue?.customization || 'No preference',
                localSelectedCustomise: cartValue?.customization || 'No preference'
            });
        }

        if (!!variables.length && !!preferences.length && !cartValue) {
            this.setState({
                selectedVariable: variables[0],
                localSelectedVariable: variables[0]
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cartValue = null, itemId = '', wrapperClass = '', productName = ''} = this.props;
        const {localSelectedVariable = {}, showDropDown= false } = this.state;

        if(this.props.cartAddedItems?.cart_items != null){
            if(prevProps.cartAddedItems?.cart_items !== this.props.cartAddedItems?.cart_items)
            {
                this.setState({quantity : null, quantity_amount:null ,quantity_product : null, quantity_variable : null , quantity_customization:null});
                this.setState({ cartItems: this.props.cartAddedItems?.cart_items || [] });
                this.setState({counter: this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items?.length : 0});
                this.setState({ price: this.props.cartAddedItems?.order_total ? this.props.cartAddedItems?.order_total?.toFixed(2) : 0 });
                this.setState({ tax : this.props.cartAddedItems?.total_tax ? this.props.cartAddedItems?.total_tax?.toFixed(2) : 0 });
                const counter =  this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items?.length : 0 ;
                const { cookies } = this.props;
                if (parseInt(counter) > 0) {
                    cookies.set('toggle','toggled');
                } else {
                    cookies.set('toggle','');
                    this.setState({toggle: '',container_toggle: "container1"});
                }
            }

            if(prevProps.cartAddedItems.preference !== this.props.cartAddedItems.preference){

                if(this.props.cartAddedItems.preference.restaurant_id === 1 ){
                    this.props.defStore({
                        store : this.props.cartAddedItems.preference.restaurant_id,
                        address :"Farmer’s Fresh Meat at Cullen",
                        preference : this.props.cartAddedItems.preference.preference_id,
                        delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                    });
                }

                if(this.props.cartAddedItems.preference.restaurant_id === 2 )
                {
                    this.props.defStore({
                        store : this.props.cartAddedItems.preference.restaurant_id,
                        address :"Farmer’s Fresh Meat at Mesa",
                        preference : this.props.cartAddedItems.preference.preference_id,
                        delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                    });
                }
            }
        }
        const isMobileView = typeof window !== "undefined" ? window?.innerWidth <= 768 : false;
        if(prevState.showDropDown !== showDropDown) {
            if(showDropDown) {
                if(isMobileView) {
                    document.body.classList.add('no-scroll');
                } else {
                    document.body.classList.remove('no-scroll');
                }
            } else {
                document.body.classList.remove('no-scroll');
            }
        }
    }

    debounce(func, delay) {
        let timerId;
        return function (...args) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    handleSelect(item, isCustomise = false ) {
        const {selectedVariable = {}, selectedCustomise = ''} = this.state;
        const {variables = [], preferences = [], isMyListPage = false, cartValue = null, wrapperClass = ''} = this.props;
        const isCartItemsPage = !!wrapperClass.includes('cart-item');
        const allCartItems = this.props.cartAddedItems?.cart_items || [];
        const getAllAddedItems = !!allCartItems?.length ? allCartItems.filter(val => Number(val.item_id) === Number(item.item_id)) : [];
        const cartItemId = cartValue?.cart_item_id || '';
        const {handleVariables} = this.props;
        clearTimeout(timerId);
        if (isCustomise) {
            this.setState({selectedCustomise: item});
            if(isMyListPage) {
                this.setState({selectedCustomise: item});
            }
            if ((selectedVariable?.id || variables?.length === 1)) {
                const variable = selectedVariable?.id ? selectedVariable : variables[0];
                const customisation = item || '';
                timerId = setTimeout(() => {
                    handleVariables({
                        target: {
                            value: JSON.stringify({
                                ...variable,
                                item_count: isMyListPage ? (cartValue?.item_count || 1) : 1
                            })
                        }
                    }, customisation, cartItemId);
                    setTimeout(() => {
                        if (!isMyListPage && !isCartItemsPage) {
                            this.setState({selectedVariable: {}, selectedCustomise: ''});
                        }
                        if (isCartItemsPage) {
                            this.setState({showDropDown: false})
                        }
                        if (!getAllAddedItems.length) {
                            document.body.classList.remove('no-scroll');
                        }
                    }, 2000);
                },1000);
            }
        } else {
            this.setState({selectedVariable: item});
            if(isMyListPage) {
                this.setState({localSelectedVariable: item});
            }
            if ((selectedCustomise || !preferences.length)) {
                const customization = selectedCustomise || '';
                timerId = setTimeout(() => {
                    handleVariables({
                        target: {
                            value: JSON.stringify({
                                ...item,
                                item_count: isMyListPage ? (cartValue?.item_count || 1) : 1
                            })
                        }
                    }, customization, cartItemId);
                    setTimeout(() => {
                        if (!isMyListPage && !isCartItemsPage) {
                            this.setState({selectedVariable: {}, selectedCustomise: ''});
                        }
                        if (isCartItemsPage) {
                            this.setState({showDropDown: false})
                        }
                        if (!getAllAddedItems.length) {
                            document.body.classList.remove('no-scroll');
                        }
                    }, 2000);
                },1000);
            }
        }
    }

    handleSubmit() {
        const {selectedVariable = {}, selectedCustomise = '', variables = []} = this.state;
        const {handleVariables} = this.props;
        handleVariables({target: {value: JSON.stringify(!!selectedVariable ? selectedVariable : variables[0])}}, selectedCustomise,this.props?.cartValue?.cart_item_id || '');
        this.setState({localSelectedCustomise: selectedCustomise, localSelectedVariable: selectedVariable});
    }

    toggleDropdown() {
        const {showDropDown, localSelectedCustomise, localSelectedVariable} = this.state;
        const isMobileView = typeof window !== "undefined" ? window?.innerWidth <= 768 : false;
        const {itemId=''} = this.props;
        if(!showDropDown) {
            EventEmitter.dispatch('closeDropDown');
                this.setState({showDropDown: true},  () => {
                    const ele = document.getElementById('dropdown-menu'+itemId);
                    if(!!ele && isMobileView) {
                         ele.classList.remove('close');
                         ele.classList.add('animate');
                    }
                });
        } else {
            const beforeElement = document.getElementById('dropdown-menu'+itemId);
            if(!!beforeElement && isMobileView) {
                beforeElement.classList.add('close');
                beforeElement.classList.remove('animate');
            }
            if(isMobileView) {
                setTimeout(() => {
                    this.setState({showDropDown: false});
                },300);
            } else {
                    this.setState({showDropDown: false});
            }

        }


    }

    getButtonWidth() {
        if (!!this.componentRef) {
            const {width = 0} = this.componentRef?.getBoundingClientRect();
            if(width) {
                return width
            }
        }
    };

    addToCart(val) {

        var time = 0 ;
        if(val.quantity === 0){
            this.setState({remove_slide : val.item_id,remove_confirm : "slide-items confirm"});
            return false ;
        }else{
            //cancel multiple request
            clearTimeout(timerId);
            time = 400 ;
        }

        //state update for multiple quantity request
        this.setState({quantity : val.quantity , quantity_amount:val.amount * val.quantity ,quantity_product : val.id, quantity_customization:val.customization, quantity_variable: val.variable_id},()=>{

            //api calling with 500ms delay
            timerId = setTimeout(
                function() {
                    const product = {
                        'menu_id':3,
                        'restaurant':this.state.default_store,
                        "item_id":val.id,
                        "variable_id":val.variable_id,
                        "quantity":this.state.quantity ? this.state.quantity : 1,
                        "customization": val.customization === 'No preference' ? '' : (val.customization || '')
                        // "removed":0,
                        // "change":0
                    };

                    this.setState({updating : true});
                    api.addToCart(
                        product,this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                        this.props.cookies.get('token') ? this.props.cookies.get('token') : null,
                        this.state.default_preference
                    ).then(success =>{

                        if (success.data.status == "400") {
                            this.props.enqueueSnackbar(success.data.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                variant: 'error',
                            });
                            this.setState({updating : false});
                        } else if (success.data.status == "405") {
                            this.setState({showDeliveryOfflineConflictModal : true, updating : false})
                        } else {
                            this.props.updateCartItems( { cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                                token : this.props.cookies.get('token') ? this.props.cookies.get('token') : null  });
                            setTimeout(() => {
                                this.setState({updating : false});
                            },1500);
                        }

                    }).catch(error => {
                        if (error?.response?.data?.response?.is_grocery_online === 0){
                            this.setState({offline : true});
                        }else {
                            this.props.enqueueSnackbar(error?.response?.data?.message, {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "error",
                            });
                        }
                        this.props.updateCartItems( { cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                            token : this.props.cookies.get('token') ? this.props.cookies.get('token') : null  });
                    });
                }
                    .bind(this),
                time
            );
        });
    }

    handleRemove = (item_id) => {
        api.removeFromCart(item_id).then(success => {
            this.setState({quantity : null, quantity_amount:null ,quantity_product : null, quantity_customization:null, quantity_variable: null});
            this.props.updateCartItems({
                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null
            });
            setTimeout(() => {
                document.body.classList.remove('no-scroll');
            },2000);
        });
    };


    render() {
        const {
            variables = [],
            preferences = [],
            btnLabel = 'Add to cart',
            cartValue = null,
            isCatering = false,
            isAddedToCart = false,
            wrapperClass = 'cart-dropdown',
            isDisabled = false,
            productName = '',
            positionToCenter=false,
            positionToLeft = false,
            positionToRight = false,
            itemId = '',
            isCartPage = false
        } = this.props;

        const {
            showDropDown,
            selectedVariable = {},
            selectedCustomise = '',
            updating = false,
            remove_slide = '',
            remove_confirm = '',
        } = this.state;


        const buttonWidth = this.getButtonWidth();
        const isCartItemsPage = !!wrapperClass.includes('cart-item');
        const isMobileView = typeof window !== "undefined" ? window?.innerWidth <= 768 : false;
        let openedAtBottom = true;
        let isLastItemInRow = false;
        if (!!this.componentRef) {
            const {top = 0, bottom = 0, left = 0, right = 0} = this.componentRef?.getBoundingClientRect();
                const windowHeight = typeof window !== 'undefined' && window?.innerHeight;
            const windowWidth = typeof window !== 'undefined' && window?.innerWidth;
                openedAtBottom = top - 100 < windowHeight / 2;
            if (right > windowWidth - 200) {
                isLastItemInRow = true;
            }
        }
        return (
            <Dropdown show={showDropDown}
                      alignRight={positionToRight}
                      ref={ref => (this.componentRef = ref)}
                      className={`${wrapperClass} ${isMobileView ? ' dropdown-slide ' : ''}`}
            >
                <Dropdown.Toggle onClick={this.toggleDropdown}
                                 id={'dropdownButton'+itemId}
                                 style={isCartPage ? {fontSize: '13px'} : {}}
                                 className={`btn btn-m text-wrap cart-button ml-0 pl-0 ${isDisabled ? 'pointer-event-none' : ''}`}>
                    {btnLabel}
                    {!isDisabled &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         fill="#ffffff" viewBox="0 0 256 256" strokeWidth='10'>
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>}
                </Dropdown.Toggle>
                <div className={`backdrop-dropdown ${isMobileView ? ' isMobile ' : '' } ` + (showDropDown ? "show" : "")}
                     id={'dropdownBackdrop'+itemId}
                     onClick={() => {
                         if (!isAddedToCart) {
                             this.toggleDropdown();
                         }
                     }}/>
                <Dropdown.Menu
                    id={'dropdown-menu'+itemId}
                    popperConfig={{strategy: 'fixed'}}
                    className={`
                    ${(!!preferences?.length && variables.length > 1 && positionToLeft && isLastItemInRow && false) ? ` drop-down-center ${openedAtBottom ? 'bottom' : 'top'} ` : ''} 
                    ${!!preferences?.length ? ' overflow-visible cart-drop-main ' : ' cart-drop-main '} 
                    ${isMobileView ? ' mob-drop-down-bottom ' : ''} 
                    ${(!!preferences?.length && variables.length > 1 && (positionToCenter && !isMobileView) && false) ? ` mob-drop-down-center ${openedAtBottom ? 'bottom' : 'top'} ` : ''}
                    `}>
                    <div className="col-12 px-0 border-bottom">
                        <h6 className="m-0 title fs-14 fw-600 bg-transparent">{productName}</h6>
                    </div>
                    <div className="col-12 list-max-drop px-0 add-to-cart-dropdown-body">
                        <div className="col-xl-12 px-1">
                            {this.state.counter? this.state.cartItems
                                    .filter((val)=> val.item_id==this.props.item)
                                    .map((val) =>{

                                        return (
                                            <div
                                                className={remove_slide === val.cart_item_id ? remove_confirm : "slide-items"}>
                                                <div className="area mt-2px">
                                                    <p className='mb-0 fs-12'>Are you sure you want to remove?</p>
                                                    <button
                                                        className="btn btn-normal btn-sm btn-outline-dark fs-12 btn-transparent w-auto px-3 py-1 mr-2"
                                                        onClick={() => this.setState({remove_confirm: "slide-items"})}>Cancel
                                                    </button>
                                                    <button className="btn btn-normal btn-sm btn-danger fs-12 w-auto px-3 py-1"
                                                            onClick={() => this.handleRemove(val.cart_item_id)}>Yes
                                                    </button>
                                                </div>
                                                <div className="dropdown-added-item py-1">
                                                    <div
                                                        className="col-12 px-2 "
                                                        key={val.cart_item_id}
                                                    >
                                                        <div className="col-12 py-2 px-3">
                                                            <div className="row align-items-center">
                                                                <div className="col-auto pl-0 pr-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg" width="18"
                                                                        height="18" viewBox="0 0 24 24" fill="none"
                                                                        className='pointer'
                                                                        onClick={() => this.setState({remove_slide: val.cart_item_id}, () => {
                                                                            this.setState({remove_confirm: "slide-items confirm"})
                                                                        })}
                                                                    >
                                                                        <path
                                                                            d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17"
                                                                            stroke="#dc2a27" strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </div>

                                                                <div className="col px-0">
                                                            <span className={`text-muted d-block mb-0 w-auto fs-12 lh-1 ${!!val?.customization ? 'mt-0' : 'mt-1'}`}>
                                                                {(val.product && val.product.item_type === 2 && (val.variable?.unit?.name ?? "") === "pc") && <><span className="text-black">Pieces:</span> {parseInt(val.variable?.weight)}</>}
                                                                {(val.product && val.product.item_type === 2 && (val.variable?.unit?.name ?? "") !== "pc") &&
                                                                <>
                                                                    {/\d/.test(val.variable?.weight) ?
                                                                        <>
                                                                            <span
                                                                                className="text-black">Weight:</span> {val.variable?.weight + " " + (val.variable?.unit?.name ?? "")}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {val.variable?.weight}
                                                                        </>}
                                                                </>
                                                                }
                                                                {val.product && val.product.item_type === 1 && <><span className="text-black">Tray size:</span> {val.variable?.tray_size}</>}
                                                            </span>
                                                                    {(!!val?.customization && val?.customization !== 'No preference') &&
                                                                <span className={`d-block w-auto pt-2px fs-12 lh-1 text-muted `}>
                                                                    <span className="text-black">Preference:</span> {!!val?.customization && `${val?.customization}`}
                                                                </span>
                                                                    }
                                                                </div>
                                                                <div className="col-auto pr-0 drop-count">
                                                                    <div className="num-block ">
                                                                        <div
                                                                            className={`num-in fs-10 ${updating ? 'actions-disabled' : ''}`}>
                                                                            <span className="minus dis decrement"
                                                                                  onClick={() => {
                                                                                      if ((this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity ? this.state.quantity : val.item_count) === 1) {
                                                                                          this.handleRemove(val.cart_item_id);
                                                                                      } else {
                                                                                          this.addToCart({
                                                                                              'id': val.item_id,
                                                                                              'quantity': (this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity ? this.state.quantity : val.item_count) - 1,
                                                                                              'variable_id': val.variable_id,
                                                                                              'amount': val.amount,
                                                                                              'item_id': val.cart_item_id,
                                                                                              customization: val.customization
                                                                                          })
                                                                                      }
                                                                                  }}
                                                                            >
                                                                                {(this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity ? this.state.quantity : val.item_count) === 1 ?
                                                                                    <i className="fa fa-trash"></i> :
                                                                                    <i className="fa fa-minus"></i>}
                                                                            </span>
                                                                            <span className="count">
                                                                                {
                                                                                    // (this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity ? this.state.quantity : val.item_count) ?
                                                                                    //     <i className="fa fa-times"></i> :
                                                                                    (this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity ? this.state.quantity : val.item_count)
                                                                                }
                                                                            </span>

                                                                            <span
                                                                                className="plus increment"
                                                                                onClick={() => this.addToCart({
                                                                                    id: val.item_id,
                                                                                    variable_id: val.variable_id,
                                                                                    item_price: this.state.item_price,
                                                                                    sell_price: this.state.sell_price,
                                                                                    customization: val.customization,
                                                                                    quantity:
                                                                                        (this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity ? this.state.quantity : val.item_count) + 1
                                                                                })}
                                                                            >
                                                                                <i className="fa fa-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>
                                        );
                                    })
                                : null}
                        </div>
                        <div className="col-12 d-flex px-0">
                        {(variables.length > 1) &&
                        <div
                            style={!preferences?.length ? {minWidth: buttonWidth} : {}}
                            className={`cart-dropdown-left w-100 mt-0 pb-3 mb-0 ${!!preferences?.length ? 'border-right' : ''}`}>
                            <h6 className='pl-2 pr-3 mb-2 text-nowrap fw-500'>{isCatering ? 'Select tray size' : !!variables?.length ? variables[0]?.unit?.name === "pc" ? 'Select pieces' : 'Select weight' : 'No customisations'}</h6>
                            {variables.map((item, index) => {
                                const isSelected = selectedVariable?.id === item.id;
                                return (
                                    <Dropdown.Item onClick={() => {
                                        this.handleSelect(item);
                                    }}
                                                   className={
                                                       `add-to-cart-option-item px-2 
                                            
                                                ${variables?.length - 1 === index ? 'last-item' : ''}
                                                ${isSelected ? 'selected' : ''}
                                                `
                                                   }
                                                   key={item.id}>
                                        <div>
                                            <p className='label mb-0 p-normal'>
                                                {isCatering ?
                                                    <p className=' mb-0'>{item?.tray_size}</p>
                                                    :
                                                    <p className=' mb-0'>{item?.unit?.name === "pc" ? (parseInt(item.weight) + ' ' + item?.unit?.name) : (item?.weight + " " + item?.unit?.name)}</p>
                                                }
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                )
                            })}
                        </div>}
                        {!!preferences?.length &&
                        <div className='cart-dropdown-right mt-0 pb-3 mb-0 w-100'
                             style={!(variables.length > 1) ? {minWidth: buttonWidth} : {}}
                        >
                            <h6 className='pl-2 pr-3 mb-2 text-nowrap fw-500'>Select cut preference</h6>
                            {[{customization: 'No preference'}, ...preferences].map((item, index) => {
                                const isSelected = (selectedCustomise === item.customization);
                                return (
                                    <Dropdown.Item onClick={() => {
                                        this.handleSelect(item.customization, true);
                                    }}
                                                   className={
                                                       `add-to-cart-option-item px-2  
                                                   ${preferences?.length === index ? ' last-item ' : ''}
                                                   ${isSelected ? ' selected ' : ''} 
                                                   ${(variables.length > 1 && !selectedVariable?.id) ? ' disabled ' : ''}
                                                  `}
                                                   key={item.id}>
                                        <div>
                                            <p className='label mb-0'>
                                                <p className=' mb-0 '>{item.customization}</p>
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                )
                            })}
                        </div>
                        }
                    </div>
                    </div>
                    {isAddedToCart &&
                    <div style={!preferences?.length ? {minWidth: buttonWidth} : {}}
                         className="col-12 py-2 border-top px-3 dropdown-action pointer" onClick={() => {
                        this.toggleDropdown();
                    }}>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                                <a href="javascript:void(0)" id='closeDropDown'
                                   className=" fs-12 mb-0 text-blue">Done</a>
                            </div>
                        </div>
                    </div>}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: 'UPDATE_CART_ITEMS', data: val})),
        defStore: (val) => dispatch(selectStore({type: 'SELECTED_STORE', data: val}))
    }
};

export default withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CartDropDown)));
