import {GET_TIMING_INFO} from "/src/constants";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

let newState = {
    selectedStore : null
};

export default function TimingReducer(state,action)
{
    switch (action.type){
        case GET_TIMING_INFO:
            newState = cloneObject(state);
            if (action.data !== null) {
                newState = Object.assign({}, newState, {
                    ...state,
                    info: action.data ? action.data : [],
                });
            }
            return newState;

        default:
            return state || newState
    }
}

