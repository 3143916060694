import React from 'react';
import {withSnackbar} from "notistack";
import {images} from "/src/constant/index";
import ScrollContainer from "react-indiana-drag-scroll";
import API from "../../api";
import {NavLink} from "react-router-dom";
import * as moment from "moment";
import LazyLoad from "react-lazyload";
const api = new API();

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs : [],
            scrollX: 0,
            scrolEnd: false
        };
        this.scrl = React.createRef();
    }

    componentDidMount() {
        api.getSidebarAllBlog().then(blogs => {
            this.setState({blogs : blogs.data });
        });
    }

    //Slide click
    slide = (shift) => {
        this.scrl.current.scrollLeft += shift;
        this.setState({scrollX: scrollX + shift});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };


    scrollCheck = () => {
        this.setState({scrollX: this.scrl.current.scrollLeft});
        if (
            Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
            this.scrl.current.offsetWidth
        ) {
            this.setState({scrolEnd: true});
        } else {
            this.setState({scrolEnd: false});
        }
    };


    render() {

        const blogs = this.state.blogs ;

        return (
            <React.Fragment>
                <section className="sec-article">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mx-auto p-0">
                                <div className="title mb-4 text-start flex-column">
                                    <h2 className="mb-0 text-white">Articles and Recipes</h2>
                                    <h6 className="text-white">Check out our latest hand-crafted articles from Houston's best butcher shop, Farmer's Fresh Meat.</h6>
                                </div>
                                <div className="col-12 mb-2 px-md-3 px-0">
                                    <div className="col-12 p-0 cus-slider">

                                        <ScrollContainer
                                            className="scroll-container"
                                            hideScrollbars={false}
                                        >
                                            {this.state.scrollX !== 0 ?
                                                <button
                                                    className="nav-btn prev"
                                                    onClick={() => this.slide(-282)}
                                                >
                                                    <i className="fa fa-angle-left"></i>
                                                </button>
                                                : ""}
                                            <ul className="cus-slider " ref={this.scrl} onScroll={this.scrollCheck}>
                                                {blogs && blogs.length > 0 && blogs.map((blog,index)=>{
                                                    if (this.props?.id !== blog.id) {
                                                        const image = blog["_embedded"]["wp:featuredmedia"]?.[0]["media_details"]  ?  blog["_embedded"]?.["wp:featuredmedia"]?.[0]?.["media_details"]?.["sizes"]?.["medium"]?.["source_url"] : images.blog_place ;
                                                        const author = blog["_embedded"]["author"][0]["name"];
                                                        const sort_description = blog["acf"]["short_description"];
                                                        return (
                                                            <div key={index} className="food-column article-card">
                                                                <NavLink exact to={"/blog/" + blog.slug}>
                                                                    <a>
                                                                        <div
                                                                            className="col-12 product-details">
                                                                            <LazyLoad key={blog.id}>
                                                                                <img className="img-fluid mb-2" src={image}/>
                                                                            </LazyLoad>
                                                                            {/*<p className="d-flex align-items-center justify-content-between text-uppercase my-1 text-theme font-10 mb-0 h-auto">{author}<span className="text-capitalize text-muted">12 Sep 2022</span></p>*/}
                                                                            <p className="d-flex align-items-center justify-content-between text-uppercase my-1 text-theme font-10 mb-0 h-auto"><span className="text-capitalize text-muted">{ moment(new Date(blog.date)).format('LL')}</span></p>
                                                                            <h6 className="">{blog.title?.rendered}</h6>
                                                                            <p>{sort_description}</p>
                                                                        </div>
                                                                    </a>
                                                                </NavLink>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </ul>

                                            {!this.state.scrolEnd ?
                                                <button
                                                    className="nav-btn next"
                                                    onClick={() => this.slide(+282)}
                                                >
                                                    <i className="fa fa-angle-right"></i>
                                                </button>
                                                : ""}
                                        </ScrollContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default withSnackbar(Article)
