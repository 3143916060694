import { UPDATE_BILLING } from "/src/constants";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

let newState = { billingDetails : null };

export default function BillingReducer(state,action)
{
    switch (action.type){

        case UPDATE_BILLING :
            newState = cloneObject(state);
            if (action.data !== null) {
                newState = Object.assign({}, newState, {
                    ...action.data,
                    billingDetails: action.data,
                });
            }
            return  newState ;

            default:
            return state || newState;
    }

}

