import React from 'react';
import {hydrate} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {ensureReady, After} from '@jaredpalmer/after';
import './client.css';
import routes from './routes';
import theme from './theme';
import {ThemeProvider} from "@material-ui/styles";
import {SnackbarProvider} from "notistack";
import {CookiesProvider} from "react-cookie";
import store from "./store/index";
import { Provider } from 'react-redux';
import Layout from "./component/layout";
import {LightgalleryProvider} from "react-lightgallery";

ensureReady(routes).then(data =>
    hydrate(
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <Provider store={store}>
                <CookiesProvider>
                    <BrowserRouter>
                        <LightgalleryProvider>
                            <Layout>
                                <After data={data} routes={routes}/>
                            </Layout>
                        </LightgalleryProvider>
                    </BrowserRouter>
                </CookiesProvider>
                </Provider>
            </SnackbarProvider>
        </ThemeProvider>,
    document.getElementById('root')
)
)
;

if (module.hot) {
    module.hot.accept();
}
