import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {CapitalizeFirstLetter, currencyFormat, EventEmitter, formatNumber} from "../../../helper";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import {connect} from "react-redux";
import {addCart, updateCart} from "../../../actions/cart";
import API from "../../../api";
import {selectStore} from "../../../actions/store";
import {Badge, Modal} from "react-bootstrap";
import {images} from "../../../constant";
import {withSnackbar} from "notistack";
import ReactTooltip from "react-tooltip";
import DeliveryOfflineModal from "../../Common/DeliveryOfflineModal";
import CartDropDown from "../../Common/CartDropDown";
const api = new API();
let timerId ;

class CartSideBar extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            token: this.props.cookies.get('token'),
            quantity_product:null,
            quantity_variable:null,
            quantity_customization:null,
            quantity : 1,
            quantity_amount : null,
            checkout : true,
            counter : 0 ,
            showDeliveryOfflineConflictModal : false,
            cartItems:[],
            price:0,
            tax:0,
            remove_slide : "",
            remove_confirm : "slide-items",
            toggle: this.props.cookies.get('toggle'),
            container_toggle :"container1",
            default_store : this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            free_delivery_applicable : this.props.cookies.get('free_delivery_applicable') !== undefined ? this.props.cookies.get('free_delivery_applicable') : 0,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            offline : false,
            updating : false,
            delivery_overall_weight : 0,
            grocery_max_order_limit : 0,
            grocery_order_limit_message : null
        };
        this.selectHandler = this.selectHandler.bind(this);
        this.redirectHandler = this.redirectHandler.bind(this);
        this.redirectProductDetailHandler = this.redirectProductDetailHandler.bind(this);
        this.box = React.createRef();
    }

    handleOutsideClick = (event) => {
        if (this.box && this.box.current && !this.box.current.contains(event.target)) {
            this.setState({container_toggle:"container1"})
        }
    };

    componentDidMount() {

        document.addEventListener('click', this.handleOutsideClick);

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store){
            this.setState({default_store: this.props.cookies.get('selected_store') });
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference){
            this.setState({default_preference: this.props.cookies.get('selected_preference') });
        }

        this.setState({ cartItems: this.props.cartAddedItems.cart_items ? this.props.cartAddedItems.cart_items : [] });
        this.setState({ counter: this.props.cartAddedItems.cart_items ? this.props.cartAddedItems.cart_items.length : 0 });
        this.setState({ price: this.props.cartAddedItems?.order_total ? this.props.cartAddedItems.order_total.toFixed(2) : 0 });
        this.setState({ delivery_overall_weight: this.props.cartAddedItems?.delivery_overall_weight ? this.props.cartAddedItems.delivery_overall_weight : 0 });
        this.setState({ tax: this.props.cartAddedItems?.total_tax ? this.props.cartAddedItems.total_tax.toFixed(2) : 0 });
        this.setState({
            grocery_max_order_limit: this.props?.cartAddedItems?.grocery_max_order_limit || 0,
            grocery_order_limit_message: this.props?.cartAddedItems?.grocery_order_limit_message || null
        });

        if(this.props.cartAddedItems.preference ? this.props.cartAddedItems.preference : "" !== "" ){

            if(this.props.cartAddedItems.preference.restaurant_id ? this.props.cartAddedItems.preference.restaurant_id : 0 === 1 ){
                let data = {
                    store : this.props.cartAddedItems.preference.restaurant_id,
                    address :"Farmer’s Fresh Meat at Cullen",
                    preference : this.props.cartAddedItems.preference.preference_id,
                    delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                };
                this.props.defStore(data);
            }

            if(this.props.cartAddedItems.preference.restaurant_id ? this.props.cartAddedItems.preference.restaurant_id : 0 === 2 )
            {
                let data = {
                    store : this.props.cartAddedItems.preference.restaurant_id,
                    address :"Farmer’s Fresh Meat at Mesa",
                    preference : this.props.cartAddedItems.preference.preference_id,
                    delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                };
                this.props.defStore(data);
            }
        }
        const counter =  this.props.cartAddedItems.cart_items ? this.props.cartAddedItems.cart_items.length : 0 ;
        const { cookies } = this.props;
        if (parseInt(counter) > 0) {
            cookies.set('toggle', 'toggled');
        } else {
            cookies.set('toggle', '');
            this.setState({toggle: '' ,container_toggle: "container1"});
        }

        const that = this;
        window.addEventListener('scroll',function () {
            const myEle = document.getElementById("container1");
            if(myEle) {
                that.cartscrollFunction();
            }
        });
        this.cartscrollFunction()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.cartAddedItems?.cart_items != null){

            if(prevProps.cartAddedItems?.cart_items !== this.props.cartAddedItems?.cart_items)
            {
                // console.log(this.props.cartAddedItems?.cart_items)
                this.setState({quantity : null, quantity_amount:null ,quantity_product : null, quantity_variable: null, quantity_customization:null});
                this.setState({ cartItems: this.props.cartAddedItems?.cart_items || [] });
                this.setState({counter: this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items?.length : 0});
                this.setState({ price: this.props.cartAddedItems?.order_total ? this.props.cartAddedItems?.order_total?.toFixed(2) : 0 });
                this.setState({ delivery_overall_weight: this.props.cartAddedItems?.delivery_overall_weight ? this.props.cartAddedItems.delivery_overall_weight : 0 });
                this.setState({ tax : this.props.cartAddedItems?.total_tax ? this.props.cartAddedItems?.total_tax?.toFixed(2) : 0 });
                this.setState({
                    grocery_max_order_limit: this.props?.cartAddedItems?.grocery_max_order_limit || 0,
                    grocery_order_limit_message: this.props?.cartAddedItems?.grocery_order_limit_message || null
                });

                const counter =  this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items?.length : 0 ;
                const { cookies } = this.props;
                if (parseInt(counter) > 0) {
                    cookies.set('toggle','toggled');
                } else {
                    cookies.set('toggle','');
                    this.setState({toggle: '',container_toggle: "container1"});
                }
            }

            if(prevProps.cartAddedItems.preference !== this.props.cartAddedItems.preference){

                if(this.props.cartAddedItems.preference.restaurant_id === 1 ){
                    var data = {
                        store : this.props.cartAddedItems.preference.restaurant_id,
                        address :"Farmer’s Fresh Meat at Cullen",
                        preference : this.props.cartAddedItems.preference.preference_id,
                        delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                    };
                    this.props.defStore(data);
                }

                if(this.props.cartAddedItems.preference.restaurant_id === 2 )
                {
                    var data = {
                        store : this.props.cartAddedItems.preference.restaurant_id,
                        address :"Farmer’s Fresh Meat at Mesa",
                        preference : this.props.cartAddedItems.preference.preference_id,
                        delivery : { address : this.props.cartAddedItems.preference.preference_address, zip_code : null }
                    };
                    this.props.defStore(data);
                }
            }
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store){
            this.setState({default_store: this.props.cookies.get('selected_store') });
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference){
            this.setState({default_preference: this.props.cookies.get('selected_preference') });
        }

        if (this.props.cookies.get('free_delivery_applicable') !== undefined && this.props.cookies.get('free_delivery_applicable') !== this.state.free_delivery_applicable){
            this.setState({free_delivery_applicable: this.props.cookies.get('free_delivery_applicable') });
        }

        if (this.state.token !== this.props.cookies.get('token')) {
            this.setState({token: this.props.cookies.get('token')});
        }
    }

    cartscrollFunction() {
        const freeDeliveryApplicable = Number(this.state.free_delivery_applicable) === 1;
        const defaultSize = freeDeliveryApplicable ? 167 : 117;
        if(document.documentElement.scrollTop > 0 && document.documentElement.scrollTop < defaultSize){
            const calc=parseInt(defaultSize - document.documentElement.scrollTop || '');
            document.getElementById("container1").style.height = "calc(100vh - "+calc+"px)";
        } else if(document.documentElement.scrollTop >= defaultSize){
            document.getElementById("container1").style.height = "100vh";
        } else{
            document.getElementById("container1").style.height = freeDeliveryApplicable ? "calc(100vh - 168px)" : "calc(100vh - 118px)";
        }
    }

    redirectHandler()
    {
        const guestCheckout = this.props?.guest_checkout;
        if(!this.state.token && !guestCheckout){
            this.props.history.push("/signUp?ref=cart");
            return false;
        }
        this.setState({container_toggle: "container1" },()=>{
            setTimeout(
                function() {
                    if (this.state.checkout === false) {
                        this.props.enqueueSnackbar("Few items are unavailable for checkout", {
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                            variant: 'error',
                        });
                        return false ;
                    }
                    this.props.history.push({
                        pathname: '/cart',
                    })
                }
                .bind(this),400);
        });
    }

    redirectProductDetailHandler(slug)
    {
        this.setState({container_toggle: "container1" },()=>{
            setTimeout(
                function() {
                    this.props.history.push({
                        pathname: '/shop/'+slug,
                    })
                }
                    .bind(this),400);
        });
    }

    addToCart(val) {

        var time = 0 ;
        if(val.quantity === 0){
            this.setState({remove_slide : val.item_id,remove_confirm : "slide-items confirm"});
            return false ;
        }else{
            //cancel multiple request
            clearTimeout(timerId);
            time = 400 ;
        }

        //state update for multiple quantity request
        this.setState({quantity : val.quantity , quantity_amount:val.amount * val.quantity ,quantity_product : val.id, quantity_variable:val.variable_id, quantity_customization:val.customization},()=>{

            //api calling with 500ms delay
            timerId = setTimeout(
                function() {
                    const product = {
                        'menu_id':3,
                        'restaurant':this.state.default_store,
                        "item_id":val.id,
                        "variable_id":val.variable_id,
                        "quantity":this.state.quantity ? this.state.quantity : 1,
                        customization: val.customization === 'No preference' ? '' : (val.customization || ''),
                        cart_item_id : val?.cart_item_id || ''
                        // "removed":0,
                        // "change":0
                    };

                    this.setState({updating : true});
                    api.addToCart(
                        product,this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                        this.props.cookies.get('token') ? this.props.cookies.get('token') : null,
                        this.state.default_preference
                    ).then(success =>{
                        if (success.data.status == "400") {
                            this.props.enqueueSnackbar(success.data.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                variant: 'error',
                            });
                            setTimeout(() => {
                                this.setState({updating : false});
                            },1500);
                        } else if (success.data.status == "405") {
                            this.setState({showDeliveryOfflineConflictModal : true, updating : false})
                        } else {
                            this.props.updateCartItems( { cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                                token : this.props.cookies.get('token') ? this.props.cookies.get('token') : null  });
                            setTimeout(() => {
                                this.setState({updating : false});
                            },1500);
                        }

                    }).catch(error => {
                        if (error?.response?.data?.response?.is_grocery_online === 0){
                            this.setState({offline : true});
                        }else {
                            this.props.enqueueSnackbar(error?.response?.data?.message, {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "error",
                            });
                        }
                        this.setState({updating : false});
                        this.props.updateCartItems( { cart_id : this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                            token : this.props.cookies.get('token') ? this.props.cookies.get('token') : null  });
                    });
                }
                    .bind(this),
                time
            );
        });
    }

    selectHandler(event, customize='',cart_item_id = '') {
        var item = JSON.parse(event.target.value);
        this.addToCart({
            'variable_id': item.id,
            'item_price': item.item_price,
            'sell_price': item.sell_price,
            'id': item.item_id,
            'quantity': item.item_count ? item.item_count : 1,
            customization: customize || '',
            cart_item_id : cart_item_id || ''
        })
    }

    handleExpand()
    {
        if(this.state.container_toggle === "container1"){
            this.setState({container_toggle: "container1 open" });
        }else{
            this.setState({container_toggle: "container1" });
        }
        EventEmitter.dispatch('closeDropDown');
    };

    handleRemove = (item_id) => {
        api.removeFromCart(item_id).then(success => {
            this.setState({quantity : null, quantity_amount:null ,quantity_product : null, quantity_variable:null, quantity_customization:null});
            this.props.updateCartItems({
                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null
            });
        });
    };

    render() {

        const priceWithCharges = parseFloat(this.state.price);
        const maxOrderLimit = parseFloat(this.state.grocery_max_order_limit);
        const cartLimitExceeded = (maxOrderLimit > 0) && (priceWithCharges > maxOrderLimit) || false;

        const {updating} = this.state || {};
        return (
            <React.Fragment>
                <div id="sidebar-wrapper" className="" ref={this.box} >
                    <div id="container1" className={ this.props.cookies.get('toggle') !== "toggled" ? "container1" : this.state.container_toggle } >
                        <div className="background">
                            <div
                                className="closeburger"
                                onClick={() => {
                                    this.handleExpand()
                                }}>
                                Cart
                            </div>
                        </div>

                        <div className="sidebar-front">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 px-2">
                                { this.state.counter ? this.state.cartItems.map((val) => {
                                        return (
                                            <div
                                                className="food-images"
                                                key={val.cart_item_id}
                                            >
                                                <img
                                                    src={val.product && val.product.images.length > 0 ? `https://cdn.farmersfreshmeat.com/211x211/${val.product.images[0].path}` : images.food }
                                                    alt="Not Found"
                                                />
                                            </div>
                                        )
                                    })
                                    : null
                                }
                            </div>
                        </div>


                        <div className="main-cart">

                            <div className="cart-padding">
                                {
                                    this.state.counter ? (
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className="estimate-order">
                                                    <h6>
                                                        <span>
                                                            {
                                                                currencyFormat(Number(this.state.price))
                                                            }
                                                        </span>
                                                    </h6>
                                                    <p>Estimated order value
                                                        {/*{parseInt(this.state.tax) > 0 &&*/}
                                                        {/*    <>*/}
                                                        {/*        <svg data-tip data-for="showTip" className="price-info" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM12 8v5" stroke="#FF8A65" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M11.995 16h.009" stroke="#FF8A65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>*/}
                                                        {/*        <i className="fa fa-info bg-lih"></i>*/}
                                                        {/*    </>*/}
                                                        {/*}*/}
                                                    </p>

                                                    <ReactTooltip id="showTip" type="light" effect="solid">
                                                        <small className="price-info-text">Estimated value is inclusive <br/>
                                                        of all state taxes applicable</small>
                                                    </ReactTooltip>
                                                </div>
                                                <div className="proceed-button"
                                                    // onClick={() => handleClose()}
                                                >
                                                    <a href="javascript:void(0)" onClick={this.redirectHandler}>
                                                        <button className="btn btn-proceed">
                                                            Begin checkout
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>



                                            {this.state.default_preference === "2" && (
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                    <small style={{ fontSize: "11px" }} className="text-muted">
                                                        Estimated delivery weight:{" "}
                                                        <span className={parseFloat(this.state.delivery_overall_weight) > parseFloat(process.env.RAZZLE_DELIVERY_RESTRICTED_WEIGHT) ? "text-danger" : "text-success"}>
                                                                    {this.state.delivery_overall_weight} lbs
                                                            {parseFloat(this.state.delivery_overall_weight) > parseFloat(process.env.RAZZLE_DELIVERY_RESTRICTED_WEIGHT) && (
                                                                <a className="tooltip-container ml-1"
                                                                   data-tip="React-tooltip"
                                                                   data-for="promo">
                                                                    <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                                         viewBox="0 0 24 24" fill="none">
                                                                        <path
                                                                            d="M12 9v5M12 21.41H5.94c-3.47 0-4.92-2.48-3.24-5.51l3.12-5.62L8.76 5c1.78-3.21 4.7-3.21 6.48 0l2.94 5.29 3.12 5.62c1.68 3.03.22 5.51-3.24 5.51H12v-.01Z"
                                                                            stroke="#696969" strokeWidth="1.5" strokeLinecap="round"
                                                                            strokeLinejoin="round"></path>
                                                                        <path d="M11.995 17h.009" stroke="#696969" strokeWidth="2"
                                                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                    <p className="tooltip-text bg-white text-black shadow-sm text-left left">
                                                                        <p className="bold w-100 border-0 mb-0 fs-12">
                                                                            <p className="mb-0 font-weight-semibold border-0 text-danger">
                                                                                (Exceeds the delivery order weight limit of 85 lbs.)
                                                                            </p>
                                                                        </p>
                                                                    </p>
                                                                </a>
                                                            )}
                                                        </span>
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <div className="py-5 my-5 d-flex flex-column align-items-cente text-center justify-content-center">
                                                <img src={images.cartIcon} width="26px" className="mx-auto d-block"/>
                                                <h2 className="mt-3 mb-1">No items in your cart.</h2>
                                            </div>
                                        </>
                                    )
                                }

                                {cartLimitExceeded &&
                                    <div role="info" className="sc-1wz9i2x-0 cardErrorClr" style={{fontSize : '12px',marginBottom : '0px'}}>
                                        <span className="text-danger">{this.state.grocery_order_limit_message}</span>
                                    </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="cart-padding side-cart-list">

                                        {this.state.counter
                                            ? this.state.cartItems.map((val) => {
                                                if (this.state.checkout === true) {
                                                    parseInt(val.in_stock) === 0 ? this.setState({checkout: false}) : "";
                                                }
                                                return (
                                                    <>
                                                        <div
                                                            className={this.state.remove_slide === val.cart_item_id ? this.state.remove_confirm : "slide-items"}>
                                                            <div className="area">
                                                                <p>Are you sure you want to remove?</p>
                                                                <button
                                                                    className="btn btn-outline-dark w-auto px-3 py-1 mr-2"
                                                                    onClick={() => this.setState({remove_confirm: "slide-items"})}>Cancel
                                                                </button>
                                                                <button className="btn btn-danger w-auto px-3 py-1"
                                                                        onClick={() => this.handleRemove(val.cart_item_id)}>Yes
                                                                </button>
                                                            </div>
                                                            <div className="food-details p-0" key={val.cart_item_id}>
                                                                <div
                                                                    className="close-btn d-flex align-items-center mr-2">
                                                                    <button type="btn" className="mt-0"
                                                                            onClick={() => this.setState({remove_slide: val.cart_item_id}, () => {
                                                                                this.setState({remove_confirm: "slide-items confirm"})
                                                                            })}>
                                                                        <svg viewBox="0 0 24 24" width="24" height="24"
                                                                             stroke="currentColor" stroke-width="2"
                                                                             fill="none" stroke-linecap="round"
                                                                             stroke-linejoin="round"
                                                                             className="css-i6dzq1">
                                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div className="imgCart">
                                                                    <a href="javascript:void(0)"
                                                                       onClick={() => this.redirectProductDetailHandler(val.product && val.product.slug ? val.product.slug : null)}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                val.product && val.product.images.length > 0 ? `https://cdn.farmersfreshmeat.com/211x211/${val.product.images[0].path}` : images.food
                                                                            }
                                                                            className="img-fluid"
                                                                            alt="food"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="cart-inner-details">
                                                                    <div
                                                                        className="d-inline-flex flex-column item-info">
                                                                        <h6 className=''>
                                                                            <a href="javascript:void(0)"
                                                                               className='mb-1'
                                                                               onClick={() => this.redirectProductDetailHandler(val.product && val.product.slug ? val.product.slug : null)}
                                                                            >{val.product && val.product.item_name ? val.product.item_name : null}
                                                                            </a>
                                                                            <br/>

                                                                            <CartDropDown
                                                                                variables={val.product.variables || []}
                                                                                preferences={val.product.customizations || []}
                                                                                handleVariables={this.selectHandler}
                                                                                btnLabel={`${(val.product && val.product.item_type === 2 && (val.variable?.unit?.name ?? "") === "pc") ? `Pieces: ${parseInt(val.variable?.weight)}` :
                                                                                    (val.product && val.product.item_type === 2 && (val.variable?.unit?.name ?? "") !== "pc") ?
                                                                                        /\d/.test(val.variable?.weight) ?
                                                                                            `Weight: ${val.variable?.weight + " " + (val.variable?.unit?.name ?? "")}`
                                                                                            :
                                                                                            `${val.variable?.weight}`
                                                                                        :
                                                                                        `${val.product && val.product.item_type === 1 && "Tray size: " + val.variable?.tray_size}`
                                                                                } ${(!!val?.customization && val?.customization !== 'No preference') ? `(${CapitalizeFirstLetter(val?.customization || '')})` : ''}`}
                                                                                cartValue={val}
                                                                                isAddedToCart={true}
                                                                                isCatering={val.product.item_type === 1}
                                                                                isDisabled={val.product.variables?.length === 1 && !val.product.customizations?.length}
                                                                                wrapperClass={'cart-item-dropdown'}
                                                                                isCartPage={true}
                                                                                productName={val.product?.item_name}
                                                                                positionToCenter={true}
                                                                                itemId={val.product?.item_id}
                                                                                positionToRight={true}
                                                                            />
                                                                        </h6>
                                                                    </div>
                                                                    <p className={`price `}>
                                                                        <span className="d-block">
                                                                            {formatNumber(this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity_customization === val.customization && this.state.quantity_amount ? this.state.quantity_amount.toFixed(2) : (val.amount *  (val.item_count ? val.item_count : 1)).toFixed(2))}
                                                                        </span>

                                                                        <small className="d-block text-muted">${val.amount} each</small>

                                                                        <div className="food-details justify-content-end">
                                                                            <div className="cart-table qty-button">

                                                                               {(( val.product && val.product.variables.length < 2) ||
                                                                                   (val.product && val.product.item_type === 2 && val.product.variables.length > 1) ||
                                                                                   (val.product && val.product.item_type === 1 && val.product.variables.length > 1)
                                                                               ) ?
                                                                                <div className="product-quantity-cart m-0">
                                                                                    <div className="num-block skin-5">
                                                                                        <div className={`num-in ${updating ? 'actions-disabled' : ''}`}>
                                                                                          <span className="minus dis decrement"
                                                                                              onClick={() => this.addToCart({
                                                                                                  'id': val.item_id,
                                                                                                  'quantity':( this.state.quantity_product === val.item_id && this.state.quantity_variable === val.variable_id && this.state.quantity ? this.state.quantity : val.item_count) - 1,
                                                                                                  'variable_id': val.variable_id,
                                                                                                  'amount': val.amount,
                                                                                                  'item_id':val.cart_item_id,
                                                                                                  customization: val.customization,
                                                                                                  cart_item_id : val.cart_item_id
                                                                                              })}
                                                                                          >
                                                                                              {(this.state.quantity_product === val.item_id && this.state.quantity_customization === val.customization && this.state.quantity_variable === val.variable_id && this.state.quantity ? this.state.quantity : val.item_count) === 1 ?
                                                                                                  <i className="fa fa-trash"></i> :  <i className="fa fa-minus"></i> }
                                                                                          </span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="in-num"
                                                                                                value={
                                                                                                    this.state.quantity_product === val.item_id && this.state.quantity_customization === val.customization &&  this.state.quantity_variable === val.variable_id && this.state.quantity ? this.state.quantity : val.item_count
                                                                                                }
                                                                                                name="qty"
                                                                                            />
                                                                                            <span
                                                                                                className="plus increment"
                                                                                                onClick={() => this.addToCart({
                                                                                                    'id': val.item_id,
                                                                                                    'quantity':(this.state.quantity_product === val.item_id && this.state.quantity_customization === val.customization && this.state.quantity_variable === val.variable_id && this.state.quantity ? this.state.quantity : val.item_count) + 1,
                                                                                                    'variable_id': val.variable_id,
                                                                                                    'amount': val.amount,
                                                                                                    'item_id':val.cart_item_id,
                                                                                                    customization: val.customization,
                                                                                                    cart_item_id : val.cart_item_id
                                                                                                })}
                                                                                            >
                                                                                              <i className="fa fa-plus"></i>
                                                                                          </span>
                                                                                        </div>
                                                                                            { val.item_count === 0
                                                                                                ? this.handleRemove(
                                                                                                    val.cart_item_id
                                                                                                )
                                                                                                : null }
                                                                                    </div>
                                                                                </div>
                                                                                    : "" }
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    </>
                                                )
                                            })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                      close={() => {
                                          this.setState({showDeliveryOfflineConflictModal: false})
                                      }}
                />

                <Modal onHide={() => {
                    this.setState({
                        quantity_product : null ,
                        product_quantity : 0,
                        quantity_variable : 0,
                        quantity_customization :0,
                        offline : false
                    })
                }} show={this.state.offline} className="storeModal mt-5">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 text-center p-0">
                                    <span className="">
                                          <img width="200px"
                                               src={images.offline}
                                          />
                                    </span>
                                <br/>
                                <h1 className="card-text w-100 font-weight-bold mt-3">{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"} </h1>
                            </div>
                            <hr/>
                            <div className="text-center">
                                <button onClick={() => {
                                    this.setState({offline : false},()=>{
                                        this.props.cookies.set('selected_store', ( parseInt(this.state.default_store) === 2 ? 1 : 2 ));
                                    })
                                }} className="btn btn-s proceed-button mb-3 mb-md-0"
                                        style={{
                                            transition: 'all .3s ease-in-out'
                                        }}>
                                    {parseInt(this.state.default_store) === 2 ? "Continue, with Farmer’s Fresh Meat at Cullen" : "Continue, with Farmer’s Fresh Meat at Mesa"}
                                </button>
                                <button onClick={() => this.setState({quantity_product : null , product_quantity : 0, quantity_variable:null ,quantity_customization:null ,offline: false})}
                                        className="btn btn-s cart-button">Cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: 'UPDATE_CART_ITEMS', data : val })),
        defStore: (val) => dispatch(selectStore({type: 'SELECTED_STORE', data: val}))
    }
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps,mapDispatchToProps)(CartSideBar))));



