import React from 'react';
import {Modal} from "react-bootstrap";

const DeliveryOfflineModal = ({
    show, close
                              }) => {

    return (
        <React.Fragment>
            <Modal onHide={close} show={show} className="storeModal mt-5">
                <Modal.Body>
                    <div className="modal-body">
                        <div className="col-12 p-0">
                            <h2 className="card-heading w-100 mt-1 mb-3">
                                We sincerely apologize for any inconvenience caused. Unfortunately,
                                we are currently unable to provide delivery services for our products.
                                However, we kindly recommend considering the option of picking up your desired items instead.
                            </h2>
                        </div>
                        <hr/>
                        <div>
                            <button onClick={close}
                                    className="btn btn-s proceed-button mb-3 mb-md-0"
                                    style={{
                                        transition: 'all .3s ease-in-out'
                                    }}>Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default DeliveryOfflineModal;