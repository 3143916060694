import React from 'react';
import {validateEmail} from "../../helper";
import API from "../../api";
import {withSnackbar} from "notistack";
import {Modal} from "react-bootstrap";
import {images} from "../../constant";

const api = new API();

class Subscribe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            show: false,
            error: false,
            errors : {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
        delete this.state.errors?.email ;
    }

    handleSubmit(event) {

        event.preventDefault();

        let errors = {};

        if (this.state.email === '' || !validateEmail(this.state.email)) {
            errors["email"] = "Enter valid email address";
            this.setState({errors: errors});
            return false;
        }

        api.subscription({email: this.state.email}).then(success => {
            this.setState({show: true, email: ''});
        }).catch(error => {
            this.setState({error: true, email: ''});
        });
    }

    render() {
        return (
            <React.Fragment>

                <div
                    className="subscribe-info"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                <div className="col-12 subscribe-details">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <h4>SUBSCRIBE AND SAVE</h4>
                                            <p>Join today for special offers and deals!</p>
                                        </div>
                                        <form className="col-md-7 action">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    value={this.state.email}
                                                    className={
                                                        "form-control valid-control " +
                                                        (this.state.errors.email ? "invalid" : "")
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <button className="btn btn-m btn-order" onClick={this.handleSubmit}>
                                                Subscribe now
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <hr className="bg-white my-4"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    dialogClassName="modal-dialog-centered"
                    onHide={() => {
                        this.setState({
                            show: false
                        })
                    }}
                    show={this.state.show}
                    // show={true}
                    className="storeModal">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 text-center p-0">
                                    <span className="">
                                          <img width="100px"
                                               src={images.successStar}
                                          />
                                    </span>
                                <h3 className="card-head w-100 mt-3 mb-2">Thank you for subscription!</h3>
                                <h6 className="card-text w-100 mt-2 mb-3">You have successfully subscribed to Farmer's Fresh Meat. Enjoy offers and updates in your email!</h6>
                            </div>
                            <div className="text-center">
                                <button onClick={() => {
                                    this.setState({show: false})
                                }}
                                        className="btn btn-s cart-button mb-3 mb-md-0"
                                        style={{
                                            transition: 'all .3s ease-in-out'
                                        }}>Done
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal
                    dialogClassName="modal-dialog-centered"
                    onHide={() => {
                        this.setState({
                            error: false
                        })
                    }}
                    show={this.state.error}
                    // show={true}
                    className="storeModal">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 text-center p-0">
                                    <span className="">
                                          <img width="100px"
                                               src={images.successStar}
                                          />
                                    </span>
                                <h3 className="card-head w-100 mt-3 mb-2">Already Subscribed!</h3>
                                <h6 className="card-text w-100 mt-2 mb-3">You are already in our subscriber's list. Enjoy offers and updates in your email!</h6>
                            </div>
                            <div className="text-center">
                                <button onClick={() => {
                                    this.setState({error: false})
                                }}
                                        className="btn btn-s cart-button mb-3 mb-md-0"
                                        style={{
                                            transition: 'all .3s ease-in-out'
                                        }}>Done
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withSnackbar(Subscribe)
