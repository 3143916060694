import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {images} from "../../../constant";
import Subscribe from "../../Common/subscribe";
import {EventEmitter} from "../../../helper";
import Article from "../../Common/article";
import API from "../../../api";
import ShopHoursClass from "../../Common/Timing";
const api = new API();

class  Footer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            showDropdown: false
        },
            this.refreshCategory = this.refreshCategory.bind(this);
    }

    componentDidMount() {
        const filters = localStorage.getItem("categories");
        const categories = JSON.parse(filters);
        if (categories == undefined){
            api.getCategories({keyword: "all", category: null}).then((success)=>{
                this.setState({categories : success.data.response?.categories },()=>{
                    localStorage.setItem('categories', JSON.stringify(success.data.response?.categories))
                })
            });
        }
    }

    refreshCategory(slug,name) {
        this.setState({categories_slug: slug, categories_name: name}, () => {
            EventEmitter.dispatch('sidebarToggleClose');
            document.getElementById("body-mob").classList.remove('fixed-position');
            this.props.history.push({
                pathname:"/category/"+slug,
            });
        });
    }

    setFilterValues(slug,name) {
        localStorage.setItem('filter', JSON.stringify({
                range: [0, 1000],
                categories_slug: slug,
                categories_name: name,
                filter: {
                    is_prime: false,
                    is_bulk: false,
                    made_locally: false,
                    is_on_sell: false
                },
                highToLow: false,
                lowToHigh: false,
            }
        ));
        EventEmitter.dispatch('changeFilterFromFooter');
        this.props.history.push({
            pathname: '/',
            state: { categories_slug : slug ,categories_name : name }
        });
    }

    handleDropDownClick() {
        this.setState({
            showDropdown : true
        });
        setTimeout(() => {
            this.setState({
                showDropdown : false
            });
        }, 500)
    }

    render() {

        var filters = undefined ;
        if (typeof window !== 'undefined') {
            filters = localStorage.getItem("categories");
        }
        const categories = filters != undefined ? JSON.parse(filters) : this.state.categories;

        const {showDropdown} = this.state || {};
        return (
            <>
                <React.Fragment>
                    <div className="footer" data-aos="zoom-in" data-aos-easing="linear">
                        {this.props.location.pathname.toString() === "/" &&
                            <Article/>
                        }
                        <div className="container">
                            <Subscribe/>
                            <div className="row justify-content-between">
                                <div className="item-col col-12 col-md-3 col-lg-4">
                                    <div className="footer-content alignment">
                                        <h2>Farmer's Fresh Meat</h2>
                                        <p>Welcome to Farmer’s Fresh Meat! We are one of the very few true full-service meat markets in the country.
                                            Our fresh selection and our Texas-sized variety encompasses all tastes from chicken and beef to goat and lamb. Our experienced butchers will masterfully carve your culinary pleasure to your liking – the old fashioned way.
                                            Try us and you’ll be convinced!</p>
                                        <hr className="bg-white"/>
                                        <h3 className="text-white font-weight-medium">Get the Farmer’s Fresh Kitchen App</h3>
                                        <ul className="d-flex mb-0">
                                            <li className="mr-2">
                                                <a href="https://apps.apple.com/app/id1486732896">
                                                    <img
                                                        src={images.appStore}
                                                        alt="Not Found"
                                                        width="90px"
                                                        className="img-fluid"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.farmers.kitchen">
                                                    <img
                                                        src={images.playStore}
                                                        alt="Not Found"
                                                        width="90px"
                                                        className="img-fluid"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        <hr className="bg-white d-block d-md-none"/>
                                    </div>
                                </div>
                                <div className="item-col col-6 col-md-2 col-lg-2">
                                    <div className="footer-content">
                                        <h2>Company</h2>
                                        <ul>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/about">About us</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/locations-contact">Find a store</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/frequent-asked-question">FAQs</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/promotion">Promotions</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/blogs">Articles & recipes</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/about/employment">Careers</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/locations-contact">Contact us</NavLink></li>
                                            <li onClick={()=>{EventEmitter.dispatch('changeKeywordResetFilter')}}><NavLink exact to="/terms-and-condition">Terms & conditions</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item-col col-6 col-md-3 col-lg-2">
                                    <div className="footer-content alignment">
                                        <h2>Categories</h2>
                                        <ul className="mb-sm-0 mb-2">
                                            {categories.length > 0 && categories.map((category,index) => {
                                                if(index !== 9) {
                                                    return (
                                                        <>
                                                            <li key={category.id}>
                                                                <a href="javascript:void(0);"
                                                                   className={category.slug === this.state.categories_slug ? "active" : ""}
                                                                   onClick={() => {
                                                                       this.refreshCategory(category.slug, category.category_name)
                                                                   }}>
                                                                    {category.category_name}
                                                                </a>
                                                            </li>
                                                        </>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="item-col col-12 col-md-3 col-lg-4">
                                    <div className="footer-content">
                                        <h2>Contact Us</h2>
                                        <div className="contact-details">
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 col-md-10 col-lg-10 col-xl-10 px-0">
                                                    <div className="hours-details">
                                                        <h6 onClick={() => {
                                                            this.handleDropDownClick();
                                                        }} className='pointer text-timing footer-bg'>Hours of Operation</h6>
                                                        <ShopHoursClass isFooter={true}
                                                                        showDropdown={showDropdown}
                                                                        shopOpenId='footerShopOpen'
                                                                        shopCloseId='footerShopClose'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 col-md-10 col-lg-10 col-xl-10 pl-0">
                                                    <div className="hours-details">
                                                        <h6>8630 Cullen Blvd<br/>
                                                            Houston, TX 77051
                                                            <hr className="my-2"></hr>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 col-md-10 col-lg-10 col-xl-10 pl-0">
                                                    <div className="hours-details">
                                                        <h6>9541 Mesa Dr.<br/>
                                                            Houston, TX 77078
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                                                    <i className="fa fa-phone"></i>
                                                </div>
                                                <div className="col-10 col-md-10 col-lg-10 col-xl-10 pl-0">
                                                    <div className="hours-details">
                                                        <h6>(713) 715-5051</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2 col-xl-1">
                                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 col-md-10 col-lg-10 col-xl-10 pl-0">
                                                    <div className="hours-details">
                                                        <h6>Contact@farmersfreshmeat.com</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="social mb-0 d-flex align-items-center">
                                                <li>
                                                    <h6>Follow us on</h6>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.facebook.com/farmersfreshmeat">
                                                        <svg width="20" height="20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/farmersfreshmeat/?igshid=1bjecjwuj8mla">
                                                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" ><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 copyright-details">
                                    <div>
                                        <p className="copyright-content">Copyright © 2024. Farmer's Fresh Meat & Grocery. All rights reserved.</p>

                                    </div>
                                </div>
                                {/*<div className="col-12 col-md-7 col-lg-6 col-xl-6">*/}
                                {/*    <div className="footer-right">*/}
                                {/*        <ul className="list-group list-group-horizontal">*/}
                                {/*            <li>*/}
                                {/*                <a href="#">SITEMAP</a>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <a href="#">TERMS &amp; CONDITIONS</a>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <a href="#">PRIVACY POLICY</a>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </>
        );
    }
}

export default withRouter(Footer);



