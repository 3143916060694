import React from 'react';
import Header from "./header";
import Footer from "./footer";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import store from "../../store";
import {Provider} from "react-redux";
import CartSideBar from "./sidebar/CartSideBar";
import {withRouter} from "react-router-dom";
import API from "../../api";
const api = new API();

class Layout extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            toggle: this.props.cookies.get('toggle'),
            is_show: true,
        };
    }

    static async getInitialProps({req, res, match, history, location, ...ctx}) {
        const restaurant = await api.getSingleRestaurant(1);
        return {
            guest_checkout : restaurant.data.response?.guest_checkout,
        }
    }

    componentDidMount() {
         api.getCategories({keyword: "all", category: null}).then((success)=>{
             localStorage.setItem('categories', JSON.stringify(success.data.response?.categories))
        });
        this.setState({toggle: this.props.cookies.get('toggle') });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.toggle !== this.props.cookies.get('toggle')) {
            this.setState({toggle: this.props.cookies.get('toggle')});
        }
    }

    render() {
        return (
            <>
                <Provider store={store}>

                    <Header guest_checkout={this.props?.guest_checkout} />

                    <div id="wrapper" className={this.state.toggle}>
                        {
                            this.state.is_show === true ? <CartSideBar guest_checkout={this.props?.guest_checkout} /> : null
                        }
                        <div className={(this.props.location.pathname.toString() === "/login" || this.props.location.pathname.toString() === "/signup" || this.props.location.pathname.toString() === "/signUp" ? "content-padding site-layout pt-0" : "content-padding site-layout")} id="page-content-wrapper">
                            { this.props.children }
                        </div>


                        {
                        this.props.location.pathname.toString() === "/login" ||
                        this.props.location.pathname.toString() === "/signUp" ||
                        this.props.location.pathname.toString() === "/signup" ||
                        this.props.location.pathname.toString() === "/cart" ||
                        this.props.location.pathname.toString() === "/checkout" ||
                        this.props.location.pathname.toString() === "/catering-products" ||
                        this.props.location.pathname.toString() === "/catering-checkout" ||
                        this.props.location.pathname.toString() === "/similar-products"
                            ?
                            "" :
                            <>

                                <Footer />
                            </>
                        }
                    </div>
                </Provider>
            </>
        );
    }
}

export default withRouter(withCookies(Layout))
